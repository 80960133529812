import React from 'react'
import styles from './CreatePortfolio.module.scss'
import api from 'api'
import Button from 'ui/Button/Button'
import { useSelector } from 'store/store'

const CreatePortfolio = () => {
  const clientId = useSelector((store) => store.client.id)

  const [createPortfolio, result] = api.usePostDemandMutation()

  const onSendRequest = () => {
    createPortfolio({ type: 'create', client: clientId! })
  }

  return (
    <div className={styles.page}>
      <Button onClick={onSendRequest} className={styles.sendBtn} disabled={result.isLoading || result.isSuccess}>
        Send a request to create a portfolio
      </Button>
      <p className={styles.message}>
        If you already have a portfolio in our company,
        <br />
        the adviser will add it with the preservation of all products
      </p>
    </div>
  )
}

export default CreatePortfolio
