import React from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { CHART_AXIS } from 'types/chartTypes'
import { useState } from 'react'

type Props = {
  title: string
  data: [string | number, number | null][]
  x_label: string
  y_label: string
  y_type: CHART_AXIS
  x_type: CHART_AXIS
}

const ScatterChart: React.FC<Props> = (props) => {
  const [ordinal, setOrdinal] = useState(false)
  let data = props.data
  if (props.x_type === CHART_AXIS.date) {
    //@ts-ignore
    data = data.map(([ts, y]) => [(ts as number) * 1000, y]).sort((a, b) => a[0] - b[0])
  }
  // console.log('data', data)
  const ys = props.data.map(([x, y]) => y)
  const xDate = props.x_type === CHART_AXIS.date
  const yString = props.y_type === CHART_AXIS.string
  const options: Highcharts.Options = {
    legend: { enabled: false },
    chart: { type: 'scatter' },
    title: { text: props.title },
    xAxis: {
      type: xDate ? 'datetime' : undefined,
      ordinal: xDate ? ordinal : undefined,
      title: { text: props.x_label },
      startOnTick: true,
      endOnTick: true,
      // dateTimeLabelFormats: {
      //   month: '%m/%d/%y',
      // },
      showLastLabel: true,
    },
    yAxis: {
      title: { text: props.y_label },
      categories: yString ? (ys as unknown[] as string[]) : undefined,
    },
    tooltip: { valueDecimals: 1 },
    plotOptions: {
      line: {
        tooltip: {
          xDateFormat: '%m/%d/%Y',
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        marker: {
          radius: 0,
        },
      },
      scatter: {
        marker: {
          radius: 2,
        },
      },
    },
    series: [
      {
        name: 'Series',
        type: xDate ? 'line' : 'scatter',
        data: yString ? data.map((e) => [e[0], ys.indexOf(e[1])]) : data,
      },
    ],
  }

  return (
    <HighchartsReact highcharts={Highcharts} options={options} constructorType={'chart'} style={{ width: '100%' }} />
  )
}

export default ScatterChart
