import SVG from 'components/SVG'
import React, { useEffect, useState } from 'react'
import styles from './AssetsBlockLine.module.scss'
import { deleteAsset, getNewChart, portfolioConstructorActions } from 'store/portfolioConstructorReducer'
import { useDispatch, useSelector } from 'store/store'
import { getAssetColor, number2currency } from 'utils'
import SliderPortfolio from '../SliderPortfolio'
import PopConfirm from 'components/PopConfirm/PopConfirm'
import Button from 'ui/Button/Button'
import classNames from 'classnames'
import PortfolioConfirmationForm from '../PortfolioConfirmationForm/PortfolioConfirmationForm'
import { PortfolioWS } from 'websocket/WebSocket'

type PropsType = {
  asset: AssetPortfolio
  percent: string
  isEdit?: boolean
}

const AssetsBlockLine: React.FC<PropsType> = ({ asset, percent, isEdit }) => {
  const dispatch = useDispatch()
  const assets = useSelector((store) => store.portfolioConstructor.assets)
  const anotherAssets = useSelector((store) => store.portfolioConstructor.anotherAssets)
  const deposit = useSelector((state) => state.portfolioConstructor.deposit) ?? 0
  const availableCash = useSelector((state) => state.portfolioConstructor.availableCash!)
  const assetsChangeMode = useSelector((state) => state.portfolioConstructor.assetsChangeMode!)
  const equity = useSelector((state) => state.portfolioConstructor.equity!)
  const statusConnected = useSelector((state) => state.portfolioConstructor.statusConnected!)
  const [isDeletePopConfirm, setIsDeletePopConfirm] = useState(false)
  const [anotherAsset, setAnotherAsset] = useState<undefined | AnotherAssetPortfolio>()

  const onChange = (newValue: number) => {
    if (asset.seller_product.price_name === 'Min. Investment') {
      dispatch(
        portfolioConstructorActions.setAsset({
          ...asset,
          quantity: Number(newValue),
          proposed_quantity:
            statusConnected === 'connected'
              ? asset.proposed_quantity
                ? asset?.proposed_quantity
                : asset.quantity
              : undefined,
        }),
      )
      PortfolioWS.requestChangeAssetsValue([
        {
          ...asset,
          proposed_quantity: Number(newValue),
        },
      ])
    } else {
      dispatch(
        portfolioConstructorActions.setAsset({
          ...asset,
          proposed_quantity:
            statusConnected === 'connected'
              ? asset.proposed_quantity
                ? asset?.proposed_quantity
                : asset.quantity
              : undefined,
          quantity: Number(newValue / asset.seller_product.price),
        }),
      )
      PortfolioWS.requestChangeAssetsValue([
        {
          ...asset,
          proposed_quantity: Number(newValue / asset.seller_product.price),
        },
      ])
    }
    getNewChart('my_chart')
  }

  const getMaxForSlider = () => {
    if (assetsChangeMode === 'price') {
      if (!asset.seller_product.max_investment)
        return equity + (deposit ? deposit : 0) + (availableCash ? availableCash : 0)
      else {
        // return equity + (deposit ? deposit : 0)
        return equity + (deposit ? deposit : 0) + (availableCash ? availableCash : 0)
      }
    } else {
      if (asset.seller_product.price_name === 'Min. Investment') {
        return equity + (deposit ? deposit : 0) + (availableCash ? availableCash : 0)
      }
      // else {
      // console.log('555555', portfolio.equity + (deposit ? deposit : 0))
      return (equity + (deposit ? deposit : 0) + (availableCash ? availableCash : 0)) / asset.seller_product.price
      // }
    }
  }

  const getMax = () => {
    if (!asset.seller_product.max_investment)
      return equity + (deposit ? deposit : 0) + (availableCash ? availableCash : 0)
    else {
      return equity + (deposit ? deposit : 0) + (availableCash ? availableCash : 0)
      // if (equity + (deposit ? deposit : 0) > asset.seller_product.max_investment) {
      //   // console.log('if')
      //   return asset.seller_product.max_investment
      // } else {
      //   return equity + (deposit ? deposit : 0) + (availableCash ? availableCash : 0)
      // }
    }
  }

  return (
    <tr
      className={classNames(
        (asset?.is_deleted === false || asset?.is_created === false || asset.is_deleted || asset?.is_created) &&
          styles.opacityTd,
      )}>
      <td
        style={{
          background: getAssetColor(asset.seller_product.id),
          borderTop: anotherAsset?.is_deleted ? 'none' : '',
        }}></td>
      <td style={{ paddingLeft: 12 }}>{asset?.seller_product?.name}</td>
      {isEdit && (
        <td style={{ textAlign: 'center' }}>
          {/* {asset.seller_product.price_name === 'Min. Investment' ? ( */}
          <SliderPortfolio
            asset={asset}
            price={asset.seller_product.price !== 1 ? Number(asset.seller_product.price.toFixed(2)) : undefined}
            max={getMax()}
            maxForSlider={getMaxForSlider()}
            min={asset.seller_product.price_name === 'Min. Investment' ? asset.seller_product.price : 0}
            //@ts-ignore
            step={(asset?.step ?? 1) * asset.seller_product.price}
            defaultValue={
              asset.seller_product.price_name === 'Min. Investment'
                ? asset?.quantity
                : asset?.quantity * asset.seller_product.price
            }
            onChange={onChange}
            anotherAsset={anotherAsset}
          />
          {/* ) : (
             <InputsAsset anotherAsset={anotherAsset} asset={asset} onChange={onChange} />
           )} */}
        </td>
      )}
      <td style={{ textAlign: 'end' }}>
        {/* {number2currency(
          asset.seller_product.price_name === 'Min. Investment'
            ? asset?.quantity
            : asset?.quantity * asset.seller_product.price,
        ) + percent} */}
        {assetsChangeMode === 'price' ? (
          asset.seller_product.price_name === 'Min. Investment' ? (
            number2currency(asset?.quantity, 2)
          ) : (
            <p style={{ whiteSpace: 'nowrap', minWidth: 76 }}>
              {number2currency(asset?.quantity * asset.seller_product.price, 2)}
            </p>
          )
        ) : asset.seller_product.price_name === 'Min. Investment' ? (
          number2currency(asset?.quantity, 2)
        ) : (
          <p style={{ whiteSpace: 'nowrap', minWidth: 84 }}>No.of {asset?.quantity.toFixed(0)}</p>
        )}
      </td>
      {isEdit && (
        <td style={{ textAlign: 'end', width: 16, position: 'relative' }}>
          <button
            style={{ border: 0, background: 0, cursor: 'pointer' }}
            onClick={() => setIsDeletePopConfirm(!isDeletePopConfirm)}>
            <SVG.Trash style={{ fill: 'var(--color_danger)' }} />
          </button>
          {isDeletePopConfirm && (
            <PopConfirm className={styles.popConfirmContainer} onClose={() => setIsDeletePopConfirm((state) => !state)}>
              <p style={{ maxWidth: 210, marginBottom: 20, textAlign: 'left' }}>
                You are sure what you want to delete <b>{asset.seller_product.name}</b>?
              </p>
              <div className={styles.popConfirmBtnContainer}>
                <Button className={styles.popConfirmBtn} onClick={() => setIsDeletePopConfirm(false)}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setIsDeletePopConfirm(false)
                    // dispatch(portfolioConstructorActions.delAsset(asset.seller_product.id))
                    deleteAsset(asset)
                    getNewChart('my_chart')
                  }}
                  style={{ backgroundColor: 'var(--color_danger)' }}
                  className={styles.popConfirmBtn}>
                  Delete
                </Button>
              </div>
            </PopConfirm>
          )}
        </td>
      )}
      {asset?.is_deleted === false && (
        <div className={styles.infoContainer}>
          <p style={{ color: 'var(--color_danger)' }}>You agree to remove this product?</p>
          <PortfolioConfirmationForm
            onConfirm={() => {
              dispatch(portfolioConstructorActions.delAsset(asset.seller_product.id))
              PortfolioWS.deleteProducts([{ ...asset }])
            }}
            onCancel={() => {
              dispatch(portfolioConstructorActions.setAsset({ ...asset, is_deleted: undefined }))
              PortfolioWS.changeAssets([{ ...asset, is_deleted: undefined }])
            }}
            isVisible={true}
          />
        </div>
      )}
      {asset?.is_deleted && (
        <div className={styles.infoContainer}>
          <p style={{ color: 'var(--color_danger)' }}>Waiting for another user confirmation</p>
        </div>
      )}
      {asset?.is_created === false && (
        <div className={styles.infoContainer}>
          <p style={{ color: 'var(--color_confirmation)' }}>Do you agree to add this product?</p>
          <PortfolioConfirmationForm
            onConfirm={() => {
              dispatch(portfolioConstructorActions.setAsset({ ...asset, is_created: undefined }))
              PortfolioWS.addProducts([{ ...asset, is_created: undefined }])
            }}
            onCancel={() => {
              dispatch(portfolioConstructorActions.delAsset(asset.seller_product.id))
              PortfolioWS.deleteProducts([{ ...asset }])
            }}
            isVisible={true}
          />
        </div>
      )}
      {asset?.is_created === true && (
        <div className={styles.infoContainer}>
          <p style={{ color: 'var(--color_confirmation)' }}>Waiting for another user confirmation</p>
        </div>
      )}
    </tr>
  )
}

export default AssetsBlockLine
