import React, { useEffect } from 'react'
import styles from './SharedPortfolio.module.scss'
import AssetsBlock from 'pages/Investing/Components/Assets/AssetsBlock'
import MainBlock from 'pages/Investing/Components/MainBlock'
import api from 'api'
import { searchParams } from 'utils'
import { useDispatch } from 'react-redux'
import { portfolioConstructorActions } from 'store/portfolioConstructorReducer'
import Loading from 'pages/Loading/Loading'

const SharedPortfolio = () => {
  const dispatch = useDispatch()
  const params = searchParams()
  const { portfolio: token } = params
  const { data: portfolio, ...respPortfolio } = api.useGetSharedPortfolioQuery({ token: token.toString()! })

  useEffect(() => {
    if (portfolio) {
      dispatch(
        portfolioConstructorActions.setMyChart({
          chart: portfolio.chart,
          down_bound: portfolio.down_bound,
          real_chart: portfolio.real_chart,
          real_chart_time: portfolio.real_chart_time,
          up_bound: portfolio.up_bound,
          times: portfolio.times,
        }),
      )
    }
  }, [portfolio])

  if (respPortfolio.isLoading) return <Loading />

  return (
    <div className={styles.page}>
      <h1>This portfolio is read-only</h1>
      <MainBlock equity={portfolio?.equity!} isShared />
      <AssetsBlock assets={portfolio?.assets} equity={portfolio?.equity!} />
      {portfolio?.is_snapshot && (
        <p style={{ color: 'var(--color_placeholder)' }}>
          This portfolio is not updated. If the owner changes something in his portfolio, then nothing will change in
          this portfolio
        </p>
      )}
    </div>
  )
}

export default SharedPortfolio
