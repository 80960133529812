import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import styles from './Header.module.scss'
import { useDispatch, useSelector } from 'store/store'
import { profileActions } from 'store/profileReducer'
import classNames from 'classnames'
import { LOGO } from 'hooks/useFirmTheme'
import { useClickOutside } from 'hooks/useClickOutside'
import translation from 'assets/locales/en/translation.json'
import api from 'api'
import SVG from '../SVG'
import LazyLoading from '../LazyLoading/LazyLoading'
import defaultAvatar from 'assets/images/avatar.png'

const ProfileBtn = () => {
  const dispatch = useDispatch()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const user = useSelector((state) => state.profile)
  const ref = useClickOutside(() => setIsMenuOpen(false))

  const onLogout = () => {
    setIsMenuOpen(false)
    dispatch(profileActions.logout())
    dispatch(api.util.resetApiState())
  }

  return (
    <div style={{ height: '100%', display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
      <div
        ref={ref}
        className={styles.profileBtnContainer}
        onMouseEnter={() => setIsMenuOpen(true)}
        onMouseLeave={() => setIsMenuOpen(false)}>
        <div className={styles.profileContainer} onClick={() => setIsMenuOpen((o) => !o)}>
          <p>{user.first_name}</p>
          {/* <button className={styles.profileBtn}>
            <LazyLoading className={styles.avatar} src={user?.avatar_url} noDataImg={defaultAvatar} />
          </button> */}
          <SVG.Arrow className={classNames(styles.arrow, { [styles.activeArrow]: isMenuOpen })} />
        </div>
        {isMenuOpen && (
          <div className={styles.profileBtnMenu}>
            <Link to={'/profile'}>
              <div className={styles.avatarInMenuContainer}>
                <LazyLoading src={user?.avatar_url} noDataImg={defaultAvatar} />
              </div>
              <div
                style={{
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'right',
                }}>
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {user.first_name + ' ' + user.last_name}
                </span>
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: 14, opacity: 0.8 }}>
                  {user.email}
                </span>
              </div>
            </Link>
            <Link to={`/products`} state={{ subscribed: 1 }}>
              {/* <SVG.Bell style={{ fill: 'var(--color_text)' }} /> */}
              {translation.Header.subscriptions}
            </Link>
            <Link to={`/products`} state={{ liked: 1 }}>
              {/* <SVG.Bell style={{ fill: 'var(--color_text)' }} /> */}
              {translation.Header.favorite}
            </Link>
            <Link to={'/login'} onClick={onLogout} className={styles.logout}>
              {/* <SVG.Logout style={{ height: '18px', stroke: 'var(--color_text)' }} /> */}
              {translation.Header.logout}
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

const HamburgerMenu = () => {
  const dispatch = useDispatch()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const user = useSelector((state) => state.profile)
  const ref = useClickOutside(() => setIsMenuOpen(false))
  const clientId = useSelector((store) => store.client.id)

  const onLogout = () => {
    dispatch(profileActions.logout())
    dispatch(api.util.resetApiState())
  }

  return (
    <div style={{ height: '100%', display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
      <Link to={'/subscriptions'} className={styles.shoppingCartBtn}>
        <SVG.Bell style={{ fill: 'var(--color_text)' }} />
      </Link>
      <div ref={ref} className={styles.profileBtnContainer}>
        <button className={styles.hamburgerLines} onClick={() => setIsMenuOpen((o) => !o)}>
          <span className={classNames(styles.line, styles.line1, isMenuOpen && styles.openLine1)}></span>
          <span className={classNames(styles.line, styles.line2, isMenuOpen && styles.openLine2)}></span>
          <span className={classNames(styles.line, styles.line3, isMenuOpen && styles.openLine3)}></span>
        </button>
        {/* <LazyLoading className={styles.avatar} src={user?.avatar_url} noDataImg={defaultAvatar} /> */}
        {isMenuOpen && (
          <div className={styles.profileBtnMenu}>
            <Link to={'/profile'}>
              <div className={styles.avatarInMenuContainer}>
                <LazyLoading src={user?.avatar_url} noDataImg={defaultAvatar} />
              </div>
              <div
                style={{
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'right',
                }}>
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{user.username}</span>
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: 14, opacity: 0.8 }}>
                  {user.email}
                </span>
              </div>
            </Link>
            <Link to={'/subscriptions'}>{translation.Header.subscriptions}</Link>

            <Link to={'/products'}>{translation.Header.products}</Link>
            {/* <Link to={'/about'}>{translation.Header.about}</Link> */}
            {clientId && __STAGE2__ && <Link to={'/investing'}>{translation.Header.investing}</Link>}
            <Link to={'/about'}>{translation.Header.about}</Link>
            {/* {__STAGE2__ && (
              <Link to={'/vlogs'} >
                <SVG.Vlogs style={{ fill: 'var(--color_text)' }} />
                {translation.Header.vlogs}
              </Link>
            )} */}
            <Link to={'/login'} className={styles.logout} onClick={onLogout}>
              {translation.Header.logout}
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

const Header = () => {
  const navigate = useNavigate()
  const mySellerFirm = useSelector((state) => state.sellerFirm)
  const isAuth = useSelector((state) => state.profile.isAuth)
  const emailConfirmed = useSelector((state) => state.profile.email_confirmed)
  const pathname = useLocation().pathname
  const clientId = useSelector((store) => store.client.id)

  useEffect(() => undefined, [pathname])

  if (!isAuth || !emailConfirmed) return <header className={styles.header} style={{ border: 'none' }} />
  return (
    <header className={styles.header}>
      <nav className={styles.nav}>
        <div className={styles.leftHeader}>
          <div className={styles.sellerFirmAvatarContainer}>
            <div
              className={styles.sellerFirmAvatar}
              onClick={() => {
                navigate('selection_seller_firm')
              }}>
              <img src={mySellerFirm.avatar_url ?? LOGO} className={styles.logo} alt={''} />
            </div>
          </div>
          <Link to={'/'} className={classNames(styles.link, { [styles.activeLink]: pathname === '/' })}>
            {translation.Header.home}
          </Link>
        </div>
        <div className={styles.rightHeader}>
          <div className={styles.links}>
            <Link
              to={'/products'}
              className={classNames(styles.link, { [styles.activeLink]: pathname.includes('product') })}>
              {translation.Header.products}
            </Link>
            {clientId && __STAGE2__ && (
              <Link
                to={'/investing'}
                className={classNames(styles.link, {
                  [styles.activeLink]: pathname.includes('investing') || pathname.includes('portfolio_constructor'),
                })}>
                {translation.Header.investing}
              </Link>
            )}
            <Link
              to={'/about'}
              className={classNames(styles.link, {
                [styles.activeLink]: pathname.includes('about'),
              })}>
              {translation.Header.about}
            </Link>
            {/* <Link
              to={'/about'}
              className={classNames(styles.link, { [styles.activeLink]: pathname.includes('about') })}>
              {translation.Header.about}
            </Link> */}
          </div>
          <div className={styles.profileBtnBox}>
            <ProfileBtn />
          </div>
          <div className={styles.hamburgerMenuBox}>
            <HamburgerMenu />
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
