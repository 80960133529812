import React from 'react'
import styles from './Page404.module.scss'
import { Link } from 'react-router-dom'
import Page404Img from 'assets/images/Page404.png'
import translation from 'assets/locales/en/translation.json'

const Page404 = () => {
  return (
    <div className={styles.page}>
      <img src={Page404Img} className={styles.Image} alt={translation.Page404.img_alt} />
      <button className={styles.goHomeBtn}>
        <Link to={'/'}>{translation.Page404.btn_home}</Link>
      </button>
    </div>
  )
}

export default Page404
