import { PortfolioWS } from 'websocket/WebSocket'
import { store } from 'store/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from 'api'
import { deleteFieldsAsset, getSumQuantity } from 'utils'

type StateType = {
  curPortfolio?: Portfolio
  equity?: number
  assets?: AssetPortfolio[]
  categories?: InvestingCategory[]
  remotePortfolioConfiguration?: RemotePortfolioConfigurationType
  myChart?: PortfolioChart
  anotherChart?: PortfolioChart
  anotherAssets?: AnotherAssetPortfolio[]
  statusConnected?: 'connected' | 'not connected' | 'disconnected'
  availableCash?: number
  deposit?: number
  anotherDeposit?: number
  depositStatus?: boolean
  assetsChangeMode: 'price' | 'quantity'

  editPortfolio?: Portfolio // Требуется при смене портфеля по WS
  editPortfolioStatus?: boolean
}

const initialState: StateType = {
  assetsChangeMode: 'price',
}

const portfolioConstructorSlice = createSlice({
  name: 'portfolioConstructor',
  initialState,
  reducers: {
    setCurPortfolio: (state, action: PayloadAction<Portfolio>) => {
      // const equity = action.payload.assets.reduce((e: number, a) => {
      //   if (a.seller_product.price_name === 'Min. Investment') return e + a.quantity
      //   else return e + a.quantity * a.seller_product.price
      // }, 0)
      state.curPortfolio = action.payload
      state.equity = action.payload.equity
    },
    setAssets: (state, action: PayloadAction<AssetPortfolio[]>) => {
      state.assets = action.payload
    },
    setProposedQuantity: (state, action: PayloadAction<AssetPortfolio[]>) => {
      state.assets = state?.assets?.map((a) => {
        const newAsset = action.payload.find((f) => f.seller_product.id === a.seller_product.id)
        if (newAsset?.seller_product?.id) {
          return { ...newAsset, quantity: a.quantity }
        } else {
          return { ...a }
        }
      })
    },
    setPortionAssets: (state, action: PayloadAction<AssetPortfolio[]>) => {
      state.assets = state?.assets?.map((a) => {
        const newAsset = action.payload.find((f) => f.seller_product.id === a.seller_product.id)
        if (newAsset?.seller_product?.id) {
          return { ...newAsset }
        } else {
          return { ...a }
        }
      })
    },
    setAsset: (state, action: PayloadAction<AssetPortfolio>) => {
      if (state.assets)
        state.assets = state?.assets.map((a, i) => {
          if (a.seller_product.id === action.payload.seller_product.id) return { ...action.payload }
          else return a
        })
      // if (state.assets) state.assets = [...state.assets, action.payload]
    },
    delAsset: (state, action: PayloadAction<number>) => {
      state.assets = state?.assets?.filter((f) => f.seller_product.id !== action.payload)
    },
    delAssets: (state, action: PayloadAction<number[]>) => {
      state.assets = state?.assets?.filter((f) => !action.payload.includes(f.seller_product.id))
    },
    addAssets: (state, action: PayloadAction<AssetPortfolio[]>) => {
      state.assets = state?.assets?.concat(action.payload)
    },
    addAsset: (state, action: PayloadAction<AssetPortfolio>) => {
      //@ts-ignore
      state.assets = [...state?.assets, { ...action.payload }]
    },
    setCategories: (state, action: PayloadAction<InvestingCategory[]>) => {
      state.categories = action.payload
    },
    setEquity: (state, action: PayloadAction<number>) => {
      state.equity = action.payload
    },
    setMyChart: (state, action: PayloadAction<PortfolioChart>) => {
      state.myChart = action.payload
    },
    setAnotherChart: (state, action: PayloadAction<PortfolioChart>) => {
      state.anotherChart = action.payload
    },
    setAnotherAssets: (state, action: PayloadAction<AnotherAssetPortfolio[]>) => {
      state.anotherAssets = action.payload
    },
    setAnotherAsset: (state, action: PayloadAction<AnotherAssetPortfolio>) => {
      state.anotherAssets = state?.anotherAssets?.map((a) => {
        if (a.seller_product.id === action.payload.seller_product.id) return { ...action.payload }
        else return a
      })
    },
    changeAnotherAsset: (state, action: PayloadAction<{ id: number | undefined; status: boolean }>) => {
      if (action.payload) {
        //@ts-ignore
        state.anotherAssets = state?.anotherAssets.map((a) => {
          if (a.seller_product.id === action.payload.id) {
            if (action.payload.status === false) {
              //Если человек нажал отказ, то нужно записать это  в мои ассеты, чтобы при отправке другому пользователю он смог отменить изменения
            }
            return { ...a, is_change: true }
          } else return { ...a }
        })
      }
      if (state.assets && action.payload.status === false)
        state.assets = state?.assets.map((a, i) => {
          if (a.seller_product.id === action.payload.id) return { ...a, status: false }
          else return a
        })
    },
    setStatusConnected: (state, action: PayloadAction<'connected' | 'not connected' | 'disconnected'>) => {
      state.statusConnected = action.payload
    },
    setAvailableCash: (state, action: PayloadAction<number>) => {
      state.availableCash = action.payload
    },
    changeDeposit: (state, action: PayloadAction<number>) => {
      state.deposit = (state.deposit ? state.deposit : 0) + action.payload
    },
    setDeposit: (state, action: PayloadAction<number>) => {
      // console.log('setAddedCash', action.payload)
      state.deposit = action.payload
      state.depositStatus = undefined
    },
    setAnotherDeposit: (state, action: PayloadAction<number>) => {
      state.anotherDeposit = action.payload
    },

    setDepositStatus: (state, action: PayloadAction<boolean | undefined>) => {
      state.depositStatus = action.payload
    },
    setAssetsChangeMode: (state, action: PayloadAction<'price' | 'quantity'>) => {
      state.assetsChangeMode = action.payload
    },
    clearPortfolioReducer: () => initialState,
  },
})

export const addNewAssets = (newAssets: AssetPortfolio[]) => {
  const dispatch = store.dispatch
  if (store.getState().portfolioConstructor.statusConnected === 'connected') {
    dispatch(
      portfolioConstructorActions.addAssets(
        newAssets.map((a) => {
          return { ...a, is_created: true }
        }),
      ),
    )
    PortfolioWS.requestAddProducts(newAssets)
  } else {
    dispatch(portfolioConstructorActions.addAssets(newAssets))
  }
}

export const setAssetQuantity = () => {
  if (store.getState().portfolioConstructor.statusConnected === 'connected') {
  } else {
  }
}

export const deleteAsset = (asset: AssetPortfolio) => {
  if (store.getState().portfolioConstructor.statusConnected === 'connected') {
    store.dispatch(portfolioConstructorActions.setAsset({ ...asset, is_deleted: true }))
    PortfolioWS.requestDeleteProducts([{ ...asset, is_deleted: false }])
  } else {
    store.dispatch(portfolioConstructorActions.delAsset(asset.seller_product.id))
  }
}

export const getNewChart = async (type?: 'my_chart') => {
  const dispatch = store.dispatch

  const assets = store.getState().portfolioConstructor.assets
  const portfolio_id = store.getState().portfolioConstructor.curPortfolio?.id
  const seller_firm_id = store.getState().sellerFirm?.id
  //@ts-ignore
  const result = store.dispatch(
    //@ts-ignore
    api.endpoints.getNewChart.initiate({
      seller_firm: seller_firm_id,
      equity: getSumQuantity(assets!),
      assets: assets?.map((a) => {
        return { id: a.seller_product.id, quantity: a.quantity, price: a.seller_product.price }
      }),
      portfolio_id,
    }),
  )
  //@ts-ignore
  result.then((res: { data: PortfolioChart }) => {
    if (type === 'my_chart') {
      dispatch(portfolioConstructorActions.setMyChart(res.data))
    } else dispatch(portfolioConstructorActions.setAnotherChart(res.data))
  })
}

export const changeDeposit = (deposit: number) => {
  if (store.getState().portfolioConstructor.statusConnected === 'connected') {
    store.dispatch(portfolioConstructorActions.setDepositStatus(true))
    PortfolioWS.requestChangeDeposit(deposit)
  } else {
    store.dispatch(portfolioConstructorActions.changeDeposit(deposit))
  }
}

export const connectedNewClient = () => {
  const dispatch = store.dispatch

  const assetsForNewClient = [] as AssetPortfolio[]
  const myNewAssets = [] as AssetPortfolio[]
  const portfolioAssets = store.getState().portfolioConstructor.curPortfolio?.assets
  const assets = store.getState().portfolioConstructor?.assets

  assets?.map((a) => {
    const portfolioAsset = portfolioAssets?.find((f) => f.seller_product.id === a.seller_product.id)
    if (portfolioAsset) {
      //Ассет возможно изменил quantity
      myNewAssets.push({ ...portfolioAsset, quantity: a.quantity, proposed_quantity: portfolioAsset.quantity })
      assetsForNewClient.push({ ...portfolioAsset, proposed_quantity: a.quantity })
    } else {
      //Пользователь который первый сидел в комнате добавил новый asset
      myNewAssets.push({ ...a, proposed_quantity: a.quantity, is_created: true, quantity: 0 })
      assetsForNewClient.push({ ...a, proposed_quantity: a.quantity, is_created: false, quantity: 0 })
    }
  })
  portfolioAssets?.map((a) => {
    const portfolioAsset = assets?.find((f) => f.seller_product.id === a.seller_product.id)
    if (!portfolioAsset) {
      //Пользователь который первый сидел в комнате удалил asset
      myNewAssets.push({ ...a, proposed_quantity: a.quantity, is_deleted: true })
      assetsForNewClient.push({ ...a, proposed_quantity: a.quantity, is_deleted: false })
    }
  })

  dispatch(portfolioConstructorActions.setAssets(myNewAssets))
  // dispatch(portfolioConstructorActions.setDeposit(0))

  PortfolioWS.setAssets(assetsForNewClient)
  // PortfolioWS.changeDeposit(0)
}

export const disconnectedClient = () => {
  const dispatch = store.dispatch
  const myNewAssets = [] as AssetPortfolio[]
  const assets = store.getState().portfolioConstructor?.assets

  assets?.map((a) => {
    myNewAssets.push(deleteFieldsAsset(a))
  })

  dispatch(portfolioConstructorActions.setAssets(myNewAssets))
  dispatch(portfolioConstructorActions.setDepositStatus(undefined))
}

export const portfolioConstructorActions = portfolioConstructorSlice.actions
export const portfolioConstructorReducer = portfolioConstructorSlice.reducer
