import React from 'react'
import styles from './ProductCard.module.scss'
import { number2currency } from 'utils'
import { useNavigate } from 'react-router'
import Gallery from '../Gallery/Gallery'
import api from 'api'
import Button from 'ui/Button/Button'
import Like from 'components/Like/Like'
import LazyLoading from '../LazyLoading/LazyLoading'

type PropsType = {
  data: Product
  liked?: boolean
}

const ProductCard: React.FC<PropsType> = ({ data, liked }) => {
  const navigate = useNavigate()
  const [like, likeResp] = api.usePostLikeMutation()
  const [deleteLike] = api.useDeleteLikeMutation()

  // const galleryItems: React.ReactNode[] = data.images.map((i) => <GalleryImage key={i.id} src={i.url} width={500} />)
  const galleryItems: React.ReactNode[] = data.images.map((i) => (
    <LazyLoading key={i.id} src={i.url} width={500} className={styles.galleryItem} />
  ))

  const onClickLike = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (liked) deleteLike(data.issuer_firm_product_id ?? data.id)
    else like(data.issuer_firm_product_id ?? data.id)
  }

  return (
    <div className={styles.container}>
      <div
        // style={{ cursor: 'pointer' }}
        className={styles.galleryContainer}
        onClick={() => {
          navigate(`/product/${data.issuer_firm_product_id ?? data.id}`)
        }}>
        <Gallery controls className={styles.gallery}>
          {galleryItems}
        </Gallery>
      </div>
      <div className={styles.info}>
        <h3 className={styles.name} title={data.name}>
          {data.name}
        </h3>
        <div className={styles.shortDescriptionContainer}>
          <p className={styles.shortDescription}>{data.short_description}</p>
        </div>
        <p className={styles.price}>
          <span className={styles.priceName}>{data.price !== null && data.price_name}: </span>
          {number2currency(data.price)}
        </p>
      </div>
      <div className={styles.btnsContainer}>
        <Button
          onClick={() => {
            navigate(`/product/${data.issuer_firm_product_id ?? data.id}`)
          }}>
          Learn more
        </Button>
        <Like liked={liked} onClick={onClickLike} disabled={likeResp.isLoading} />
        {/* <Share text={window.origin + `/product/${data.issuer_firm_product_id ?? data.id}`} /> */}
      </div>
    </div>
  )
}

export default ProductCard
