import { components, ItemType } from '../index'
import styles from './ui.module.scss'

const Row: React.FC<ItemType> = ({ children, style }) => {
  return (
    <div className={styles.row} style={style}>
      {children?.map(({ blockType, ...params }) => {
        const Block: React.FC<ItemType> = components[blockType]
        return <Block key={blockType + params.id} {...params} />
      })}
    </div>
  )
}
export default Row
