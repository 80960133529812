import SVG from 'components/SVG'
import React from 'react'

type PropsType = {
  // title: string
  children: React.ReactNode
  style?: React.CSSProperties
}
const Notifications = {
  Warning: ({ ...props }: PropsType) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', ...props.style }}>
        <SVG.Warning style={{ width: 16, fill: '#f7c600' }} />
        {props.children}
      </div>
    )
  },
  Info: ({ ...props }: PropsType) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', ...props.style }}>
        <SVG.Info style={{ width: 20, height: 20, fill: 'var(--color_link)' }} />
        {props.children}
      </div>
    )
  },
}

export default Notifications
