import api from 'api'
import Landing from 'components/Landing/Landing'
import Loading from 'pages/Loading/Loading'
import { useSelector } from 'store/store'
import styles from './about.module.scss'

const About = () => {
  const sellerFirm = useSelector((store) => store.sellerFirm)

  const { data: landingData, isLoading } = api.useGetLandingQuery(
    { seller_firm: sellerFirm.id },
    { skip: !sellerFirm.id },
  )

  const landing = landingData?.landing

  if (isLoading) return <Loading />
  else if (landing) return <Landing data={landing} />
  else if (!sellerFirm) {
    return (
      <div>
        <h1 style={{ color: 'rgb(255, 77, 79)' }}>Firm don&apos;t selected </h1>
      </div>
    )
  } else {
    return (
      <div className={styles.main}>
        <div className={styles.head}>
          <h1>{sellerFirm.name}</h1>
          {sellerFirm.avatar_url && <img src={sellerFirm.avatar_url} />}
        </div>
        {sellerFirm.description && (
          <div className={styles.description}>
            <p style={{ color: '#8C8C8C', fontWeight: 'bold' }}>Description</p>
            <p>{sellerFirm.description}</p>
          </div>
        )}
      </div>
    )
  }
}

export default About
