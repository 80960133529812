import React, { useEffect, useState } from 'react'
import styles from './Modal.module.scss'
import { useClickOutside } from '../../hooks/useClickOutside'
import classNames from 'classnames'
import SVG from 'components/SVG'
import Button from 'ui/Button/Button'

type Props = {
  visible?: boolean
  onClose?: () => void
  classNameWrapper?: string
  classNameContainer?: string
  onOk?: () => void
  okBtnDisabled?: boolean
  cancelText?: string
  okTitle?: string
  title?: string
}

const Modal: React.FC<Props> = ({
  children,
  onClose,
  classNameWrapper,
  classNameContainer,
  onOk,
  okTitle,
  cancelText,
  title,
  okBtnDisabled,
  visible,
}) => {
  const ref = useClickOutside(onClose)
  return (
    <div className={classNames(styles.wrapper, classNameWrapper, { [styles.visible]: visible })}>
      <div className={classNames(styles.container, classNameContainer)} ref={ref}>
        <div className={styles.header}>
          <h2>{title}</h2>
          <SVG.Cross
            onClick={onClose}
            style={{
              width: 16,
              height: 16,
              stroke: 'var(--color_text)',
              strokeWidth: 3,
              cursor: 'pointer',
              marginLeft: 10,
            }}
          />
        </div>
        <div className={styles.content}>{children}</div>
        <div className={styles.footer}>
          <Button type={'secondary'} onClick={onClose} style={{ marginRight: 10 }}>
            {cancelText ?? 'Cancel'}
          </Button>
          <Button onClick={onOk} disabled={okBtnDisabled}>
            {okTitle ?? 'Ok'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Modal
