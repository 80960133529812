import { CSSProperties } from 'react'

import Row from './ui/Row'
import Text from './ui/Text'
import Image from './ui/Image'
import { Landing } from '../Landing'

export enum componentsName {
  row = 'Row',
  text = 'Text',
  img = 'Image',
}
export type componentsNameStrings = keyof typeof componentsName

export type ItemType = {
  id: string
  style?: CSSProperties
  value?: any
  url?: string
  children?: Landing[]
}

export const components: { [key in keyof typeof componentsName]: React.FC<ItemType> } = {
  row: Row,
  img: Image,
  text: Text,
}
