import React, { useState } from 'react'
import styles from './SelectParams.module.scss'
import classNames from 'classnames'
import Select from 'components/Select/Select'

type PropsType = {
  onChange: (value: any) => void
  data: SelectType
}
const SelectParams: React.FC<PropsType> = ({ data, ...props }) => {
  const [value, setValue] = useState<string>()
  const [showOptions, setShowOptions] = useState(false)

  const onChange = (value: string) => {
    setValue(value)
    props.onChange(value)
  }

  return (
    <div className={styles.container} onClick={() => setShowOptions((v) => !v)}>
      <p className={styles.title}>{data.name}:</p>
      <div className={styles.select}>
        {/* <p className={classNames([styles.currentValue, { [styles.placeholder]: value === undefined }])}>
          {value ?? 'Select...'}
        </p> */}
        <Select
          options={data.options.map((o) => {
            return { label: o, value: 0 }
          })}
          //@ts-ignore
          value={value ? { label: value, value: value } : undefined}
          onChange={(e) => {
            onChange(e.label)
          }}
        />
      </div>
    </div>
  )
}

export default SelectParams
