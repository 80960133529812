import React, { useEffect } from 'react'
import styles from './Subscriptions.module.scss'
import { useSelector } from '../../store/store'
import { Link } from 'react-router-dom'
import translation from 'assets/locales/en/translation.json'
import Button from '../../ui/Button/Button'
import api from '../../api'
import Loading from '../Loading/Loading'
import SubscriptionRow from './components/SubscriptionRow/SubscriptionRow'

type Props = {
  subscribes: Subscribe[]
  sellerFirms: Firm[]
}

const Subscriptions = ({ subscribes, sellerFirms }: Props) => {
  return (
    <div className={styles.container}>
      {subscribes === undefined || subscribes.length === 0 ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h2 className={styles.noSubscriptionsTitle}>{translation.Subscriptions.no_subscriptions_title}</h2>
          <Link to={'/products'}>
            <Button className={styles.goHomeBtn}>{translation.Subscriptions.link}</Button>
          </Link>
        </div>
      ) : (
        <div className={styles.productsContainer}>
          {subscribes.map((s) => (
            <SubscriptionRow key={s.id} data={s} firm={sellerFirms.find((f) => f.id === s.product.seller_firm)!} />
          ))}
        </div>
      )}
    </div>
  )
}

const SubscriptionsContainer = () => {
  const userId = useSelector((state) => state.profile.id)
  const { data: subscribes, isLoading: subscribesLoading } = api.useGetSubscribesQuery({ user: userId! })
  const [getSellerFirms, sellersFirms] = api.useLazyGetSellerFirmsQuery()

  useEffect(() => {
    if (subscribes) {
      getSellerFirms(subscribes.map((s) => s.product.seller_firm))
    }
  }, [subscribes])

  if (subscribesLoading || sellersFirms.isUninitialized || sellersFirms.isLoading) return <Loading />
  else return <Subscriptions subscribes={subscribes!} sellerFirms={sellersFirms.data!} />
}

export default SubscriptionsContainer
