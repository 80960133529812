import React from 'react'
import styles from './SubscriptionRow.module.scss'
import { number2currency } from 'utils'
import { useNavigate } from 'react-router'
import LazyLoading from 'components/LazyLoading/LazyLoading'
import Gallery from 'components/Gallery/Gallery'
import api from 'api'
import Button from 'ui/Button/Button'

type PropsType = {
  data: Subscribe
  firm: Firm
}

const SubscriptionRow: React.FC<PropsType> = ({ data, firm }) => {
  const navigate = useNavigate()
  const product = data.product
  const picture = product.images[0]
  const [unsubscribe] = api.useDeleteSubscribeMutation()

  const onUnsub = (e: React.MouseEvent) => {
    e.stopPropagation()
    unsubscribe(data.id)
  }

  return (
    <div
      className={styles.container}
      onClick={() => {
        navigate('/product/' + (product.issuer_firm_product_id ?? data.id))
      }}>
      <LazyLoading
        src={picture?.url}
        width={240}
        style={{ background: '#' + picture?.background_color }}
        className={styles.picture}
      />
      <div className={styles.info}>
        <div className={styles.firstCol}>
          <p className={styles.name}>{product.name}</p>
          <LazyLoading src={firm.avatar_url} width={100} className={styles.firmAvatar} />
          <p className={styles.firmName}>{firm.name}</p>
        </div>
        <div className={styles.params}>
          {data.params?.map((p, i) => (
            <p key={p.name + i}>
              {p.name}: {p.value}
            </p>
          ))}
        </div>
        <div className={styles.lastCol}>
          <div className={styles.price}>
            {product.price > 0 && (
              <>
                <p style={{ marginRight: 4, whiteSpace: 'nowrap' }}>{product.price_name}:</p>
                <p>
                  <b>{number2currency(product.price)}</b>
                </p>
              </>
            )}
          </div>
          <Button className={styles.unsubBtn} onClick={onUnsub}>
            Unsubscribe
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionRow
