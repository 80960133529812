import api from 'api'
import VideoPlayer from 'components/VideoPlayer/VideoPlayer'
import React from 'react'
import styles from './Vlogs.module.scss'
const Vlogs = () => {
  const { data: vlogs, ...respVlogs } = api.useGetVlogsQuery({})
  return (
    <div className={styles.container}>
      <h1 style={{ marginBottom: 20 }}>Vlogs</h1>
      <div className={styles.content}>
        {vlogs?.length === 0 && <h1>No vlogs available</h1>}
        {vlogs?.map((v) => (
          <Vlog key={v.id} vlog={v} />
        ))}
      </div>
    </div>
  )
}

export default Vlogs

type VlogType = {
  vlog: Vlog
}

const Vlog: React.FC<VlogType> = ({ vlog }) => {
  return (
    <div className={styles.vlogContainer}>
      <p className={styles.vlogTitle}>{vlog.title}</p>
      <VideoPlayer
        url={vlog.video[0].url ?? ''}
        videoId={vlog.id}
        poster={vlog.video[0].url_preview}
        controls
        isSendAnalytic={false}
        classNameVideo={styles.video}
        classNameContainerVideo={styles.containerVideo}
      />
    </div>
  )
}
