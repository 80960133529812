import classNames from 'classnames'
import { useClickOutside } from 'hooks/useClickOutside'
import React from 'react'
import styles from './PopConfirm.module.scss'

type PropsType = {
  className?: string
  onClose: Function
}
const PopConfirm: React.FC<PropsType> = ({ className, children, onClose }) => {
  const ref = useClickOutside(() => onClose())
  return (
    <div ref={ref} className={classNames(styles.container, className)}>
      {children}
    </div>
  )
}

export default PopConfirm
