import React from 'react'
import styles from './Like.module.scss'
import SVG from 'components/SVG'
import classNames from 'classnames'

type PropsType = {
  liked?: boolean
  onClick: (e: React.MouseEvent) => void
  disabled?: boolean
}

const Like = ({ liked, onClick, disabled }: PropsType) => {
  return (
    <div
      className={classNames(styles.likeContainer, { [styles.likedContainer]: liked })}
      onClick={(e) => {
        !disabled && onClick(e)
      }}>
      <SVG.Like />
    </div>
  )
}

export default Like
