import React, { useEffect, useState } from 'react'
import styles from '../Portfolio.module.scss'
import classNames from 'classnames'
import { number2currency } from 'utils'
import SVG from 'components/SVG'
import { useClickOutside } from 'hooks/useClickOutside'
import Modal from 'components/Modal/Modal'
import Input from 'components/Input/Input'
import Button from 'ui/Button/Button'
import api from 'api'
import { groupCollapsed } from 'console'

type GoalRowType = {
  goal: Goal
  balance: number
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  setModalType: React.Dispatch<React.SetStateAction<'Edit' | 'Add' | undefined>>
  setModalOption: React.Dispatch<
    React.SetStateAction<
      | {
          name: string
          price: number
          id: number
        }
      | undefined
    >
  >
}

const GoalRow: React.FC<GoalRowType> = ({ goal, balance, setIsOpenModal, setModalOption, setModalType }) => {
  const progress = Math.round((balance / goal.price) * 100)
  const [isShowPopup, setIsShowPopup] = useState(false)
  const ref = useClickOutside(() => setIsShowPopup(false))
  const [deleteGoal, deleteGoalResult] = api.useDeleteGoalMutation()

  return (
    <div className={styles.goalRow}>
      <p>{goal.name}</p>
      <div style={{ display: 'flex' }}>
        <p style={{ marginRight: 10 }}>{number2currency(goal.price)}</p>
        <p style={{ color: progress < 100 ? 'var(--color_danger)' : 'var(--color_confirmation)', marginRight: 5 }}>
          {progress}%
        </p>
        <div style={{ position: 'relative' }}>
          <button className={styles.ghostBtn} onClick={() => setIsShowPopup(true)}>
            <SVG.ThreeDots style={{ width: 20, height: 20 }} />
          </button>
          {isShowPopup && (
            <div ref={ref} className={styles.goalThreeDotsPopup}>
              <button
                onClick={() => {
                  setIsShowPopup(false)
                  setIsOpenModal(true)
                  setModalType('Edit')
                  setModalOption({ name: goal.name, price: goal.price, id: goal.id })
                }}>
                Edit
              </button>
              <button
                onClick={() => {
                  deleteGoal(goal.id)
                  setIsShowPopup(false)
                }}>
                Delete
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

type Props = {
  balance: number
}

const GoalsBlock = ({ balance }: Props) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [modalType, setModalType] = useState<'Add' | 'Edit' | undefined>()
  const [modalOption, setModalOption] = useState<undefined | { name: string; price: number; id: number }>()
  const goals = api.useGetGoalsQuery()

  return (
    <>
      <div className={classNames([styles.block, styles.goalsBlock])}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
          <p style={{ fontWeight: 'bold' }}>Goals</p>
          <button
            className={styles.ghostBtn}
            onClick={() => {
              setIsOpenModal(true)
              setModalType('Add')
              setModalOption(undefined)
            }}>
            <SVG.Plus style={{ width: 16, height: 16, stroke: 'var(--color_link)' }} />
          </button>
        </div>

        {goals?.data?.map((g, i) => (
          <GoalRow
            setIsOpenModal={setIsOpenModal}
            setModalOption={setModalOption}
            setModalType={setModalType}
            key={g.name + i}
            goal={g}
            balance={balance}
          />
        ))}
      </div>
      <GoalsModal
        modalOption={modalOption}
        isOpenModal={isOpenModal}
        type={modalType}
        setIsOpenModal={setIsOpenModal}
      />
    </>
  )
}

export default GoalsBlock

type GoalsModalType = {
  type: 'Add' | 'Edit' | undefined
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  isOpenModal: boolean
  modalOption:
    | {
        name: string
        price: number
        id: number
      }
    | undefined
}
const GoalsModal: React.FC<GoalsModalType> = ({ setIsOpenModal, type, modalOption, isOpenModal }) => {
  const ref = useClickOutside(() => setIsOpenModal(false))
  const [addGoal, addGoalResult] = api.useAddGoalMutation()
  const [editGoal, editGoalResult] = api.useEditGoalMutation()

  const [name, setName] = useState<string | undefined>(modalOption?.name)
  const [nameError, setNameError] = useState<string | undefined>()
  const [price, setPrice] = useState<number | undefined>(modalOption?.price)
  const [priceError, setPriceError] = useState<string | undefined>()

  useEffect(() => {
    if (addGoalResult.isSuccess || editGoalResult.isSuccess) setIsOpenModal(false)
  }, [addGoalResult, editGoalResult])

  // console.log('modalOption!!!', modalOption)

  const onSubmit = () => {
    if (!name) return setNameError('Field "Name" cannot be empty')
    else {
      setNameError(undefined)
    }
    if (!price) return setPriceError('Field "Price" cannot be empty')
    else {
      setPriceError(undefined)
    }
    if (type === 'Add') addGoal({ name, price })
    if (type === 'Edit') editGoal({ name, price, id: modalOption?.id! })
  }
  return (
    <Modal
      onClose={() => setIsOpenModal(false)}
      onOk={onSubmit}
      okBtnDisabled={!name || !price}
      visible={isOpenModal}
      title={type === 'Add' ? 'Add new goal' : type === 'Edit' ? 'Edit goal' : ''}>
      <div ref={ref} className={styles.goalsModalContainer}>
        <p style={{ fontWeight: 'bold', marginBottom: 16 }}></p>
        <div className={styles.goalsInputContainer}>
          <p style={{ marginBottom: 4 }}>Name</p>
          <Input
            value={name}
            onChange={(e) => {
              setName(e)
            }}
          />
          {nameError && (
            <p className={styles.error} style={{ marginTop: 4 }}>
              {nameError}
            </p>
          )}
        </div>
        <div className={styles.goalsInputContainer}>
          <p style={{ marginBottom: 4 }}>Price</p>
          <Input
            type={'number'}
            value={price?.toString()}
            onChange={(e) => {
              //@ts-ignore
              setPrice(e)
            }}
          />
          {priceError && (
            <p className={styles.error} style={{ marginTop: 4 }}>
              {priceError}
            </p>
          )}
        </div>

        {/* <Button style={{ marginLeft: 'auto' }} onClick={onSubmit}>
          Send
        </Button> */}
      </div>
    </Modal>
  )
}
