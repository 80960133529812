import Modal from 'components/Modal/Modal'
import Params from 'pages/Product/Params/Params'
import React, { useRef, useState } from 'react'
import { Button } from 'ui'
import styles from './SubscribeParams.module.scss'
import translation from 'assets/locales/en/translation.json'
import classNames from 'classnames'
import useScrollLock from 'hooks/useScrollLock'
import api from 'api'
import { useSelector } from 'store/store'

type PropsType = {
  product?: Product
  subscribe?: ShortSubscribe
  refetchSubscribes?: () => void
  // productParams?: (ParamSlider | ParamSelect | ParamInput)[] | null
  // capacity: number | null
  // isSubscribe?: boolean
}
const SubscribeParams: React.FC<PropsType> = ({ product, subscribe, refetchSubscribes }) => {
  const [visible, setVisible] = useState(false)
  const [parametersFilled, setParametersFilled] = useState(false)
  const mySellerFirmId = useSelector((store) => store.sellerFirm.id)
  const { data: sellerProduct } = api.useGetSellerProductByIssuerProductQuery(
    { product: product?.id!, seller_firm: mySellerFirmId! },
    { skip: !product?.id || !mySellerFirmId },
  )
  const [subscribeRequest, subscribeResponse] = api.useSetSubscribeMutation()
  const [deleteSubscribeRequest, { isLoading: delSubscribeLoading }] = api.useDeleteSubscribeMutation()
  const hasParams = product?.params && product.params.length > 0

  //@ts-ignore
  const isSubscribed = subscribe?.id && subscribe.is_subscribed
  const isDisabled =
    (hasParams && !parametersFilled && !isSubscribed) || subscribeResponse.isLoading || delSubscribeLoading
  const params = useRef<(SelectType | InputType | SliderType)[]>(
    product?.params?.map((p) => ({ ...p, value: undefined })) ?? [],
  )
  const onSubscribe = async () => {
    //@ts-ignore
    subscribeRequest({ product: sellerProduct.id, params: params.current }).then(() => {
      refetchSubscribes?.()
      onClose()
    })
  }

  useScrollLock({ value: visible })

  const onClose = () => {
    setVisible(false)
  }
  const onOk = () => {
    if (parametersFilled) {
      onSubscribe()
    }

    // setVisible(false)
  }
  const onClick = () => {
    if (isSubscribed) {
      deleteSubscribeRequest(subscribe.id).then(() => {
        refetchSubscribes?.()
        onClose()
      })
    } else if (hasParams) {
      setVisible(true)
    } else {
      onSubscribe()
    }
  }

  return (
    <>
      <div style={{ marginLeft: 10, display: 'flex', gap: 10, alignItems: 'center' }}>
        {isSubscribed && <p>{subscribe?.status ? 'Status: ' + subscribe?.status : ''}</p>}
        <Button
          disabled={isDisabled}
          onClick={onClick}
          isLoading={isSubscribed ? delSubscribeLoading : subscribeResponse.isLoading}
          className={classNames([styles.subscribeBtn, { [styles.unsubscribeBtn]: isSubscribed }])}>
          {isSubscribed ? translation.Product.unsubscribe : translation.Product.subscribe}
        </Button>
      </div>
      <Modal title="Select configuration" visible={visible} onClose={onClose} onOk={onOk}>
        <Params params={params} onParametersFilledChange={setParametersFilled} capacity={product?.capacity_available} />
      </Modal>
    </>
  )
}

export default SubscribeParams
