import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type InitialStateType = {
  id?: number
  accredited_investor?: boolean
  qualified_purchaser?: boolean
  billing_state?: string
  email?: string
  first_name?: string
  last_name?: string
  mobile_phone?: string
  zip_code?: string

  birthday?: string
  gender?: string
  address?: string
  city?: string
  country?: string
}
type InitialStateTypeKeys = (keyof InitialStateType)[]
const initialState: InitialStateType = {
  id: undefined,
  accredited_investor: undefined,
  qualified_purchaser: undefined,
  billing_state: undefined,
  email: undefined,
  first_name: undefined,
  last_name: undefined,
  mobile_phone: undefined,
  zip_code: undefined,
  birthday: undefined,
  gender: undefined,
  address: undefined,
  city: undefined,
  country: undefined,
}
const stateKeys = Object.keys(initialState) as InitialStateTypeKeys

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClientId: (state, action: PayloadAction<number | undefined>) => {
      state.id = action.payload
    },
    setClient: (state, action: PayloadAction<Partial<ImportedClient> | undefined>) => {
      if (!action.payload) state = initialState
      else {
        Object.entries(action.payload).forEach(([key, value]) => {
          //@ts-ignore
          if (stateKeys.includes(key)) state[key] = value
        })
      }
    },
  },
})

export const clientActions = clientSlice.actions
export const clientReducer = clientSlice.reducer
