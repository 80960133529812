import React, { MouseEventHandler, useEffect, useState } from 'react'
import classNames from 'classnames'
import Checkbox from 'components/Checkbox/Checkbox'
import SVG from 'components/SVG'
import { useClickOutside } from 'hooks/useClickOutside'
import styles from './ThreeSelect.module.scss'

export type Option = { id: number; label: string; children?: Option[] }

const getIsSelected = (selected: number[], option: Option): boolean => {
  if (option.children === undefined) return selected.find((id) => id === option.id) !== undefined
  else {
    if (option.children.length > 0) {
      return option.children.reduce((p: boolean, c) => {
        if (!p) return false
        if (c.children === undefined) return selected.find((id) => id === c.id) !== undefined
        return getIsSelected(selected, c)
      }, true)
    } else return false
  }
}

const getSelectedOptions = (selected: number[], options: Option[], arr?: Option[]): Option[] => {
  const resArr: Option[] = arr ?? []
  options.forEach((opt) => {
    if (opt.children === undefined) {
      if (selected.find((id) => id === opt.id) !== undefined) resArr.push(opt)
    } else {
      getSelectedOptions(selected, opt.children, resArr)
    }
  })

  return resArr
}

const getIsIndeterminate = (option: Option, selected: number[]): boolean => {
  if (option.children === undefined) return selected.find((id) => id === option.id) !== undefined
  return option.children.reduce((p: boolean, c) => {
    if (p) return p
    else return getIsIndeterminate(c, selected)
  }, false)
}

const updSelected = (id: number, options: Option[], selected: number[], resArr?: number[], isSelected?: boolean) => {
  const tempRes: number[] = resArr ?? []
  options.forEach((opt) => {
    if (opt.children === undefined) {
      const selectedInd = selected.indexOf(opt.id)
      const prevIsSelected = selectedInd !== -1
      if (opt.id === id) {
        const newIsSelected = isSelected !== undefined ? isSelected : !prevIsSelected
        if (prevIsSelected !== newIsSelected && newIsSelected) tempRes.push(opt.id)
      } else {
        const newIsSelected = isSelected !== undefined ? isSelected : prevIsSelected
        if (newIsSelected) tempRes.push(opt.id)
      }
    } else {
      if (opt.id === id) {
        const prevIsSelected = getIsSelected(selected, opt)
        tempRes.concat(updSelected(id, opt.children, selected, tempRes, !prevIsSelected))
      } else tempRes.concat(updSelected(id, opt.children, selected, tempRes))
    }
  })

  return tempRes
}

type OptionProps = {
  option: Option
  onClick: (id: number) => void
  selected: number[]
}

const Option = ({ option, onClick, selected }: OptionProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const isSelected =
    option.children !== undefined
      ? getIsSelected(selected, option)
      : selected.find((id) => id === option.id) !== undefined

  return (
    <div className={styles.optionContainer}>
      <div className={classNames(styles.option, { [styles.showOptions]: isOpen })} onClick={() => setIsOpen((o) => !o)}>
        <Checkbox
          indeterminate={getIsIndeterminate(option, selected)}
          checked={isSelected}
          style={{ marginRight: 8 }}
          onClick={() => onClick(option.id)}
        />
        <p key={option.id}>{option.label}</p>
        {option.children !== undefined && <SVG.Arrow style={{ fill: 'var(--color_text)' }} />}
      </div>
      {option.children !== undefined && isOpen && (
        <div className={styles.optionChildren}>
          {option.children?.map((o) => (
            <Option key={o.id} option={o} onClick={onClick} selected={selected} />
          ))}
        </div>
      )}
    </div>
  )
}

type PropsType = {
  onChange: (selected: number[]) => void
  options: Option[]
  selected?: number[]
  placeholder?: string
  btnClass?: string
  containerClass?: string
  error?: string
  optionsPosRight?: boolean
  onClickContainer?: (value: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  disabled?: boolean
}

const ThreeSelect = ({ options, selected: propsSelected, ...props }: PropsType) => {
  const [selected, setSelected] = useState<number[]>(propsSelected ?? [])
  const [showOptions, setShowOptions] = useState(false)
  const ref = useClickOutside(() => setShowOptions(false))
  const hasOptions = options.length > 0

  const onClickOption = (id: number) => {
    const newSelected = updSelected(id, options, selected)
    setSelected(newSelected)
    props.onChange(newSelected)
  }

  useEffect(() => {
    ref.current.onclick = props?.onClickContainer
  }, [])

  useEffect(() => {
    setSelected(propsSelected ?? [])
  }, [propsSelected])

  useEffect(() => {
    if (props.disabled) {
      setShowOptions(false)
    }
  }, [props.disabled])

  return (
    <div className={classNames([props.containerClass, styles.select])} ref={ref}>
      {props.disabled && <div className={styles.disabledBlock}></div>}
      <div
        className={classNames([
          props.btnClass,
          styles.selected,
          {
            [styles.placeholder]: selected === undefined,
            [styles.valueError]: props.error !== undefined,
            [styles.showOptions]: showOptions,
          },
        ])}
        onClick={() => setShowOptions((v) => hasOptions && !v)}>
        {selected.length > 0 ? (
          getSelectedOptions(selected, options).map((v) => (
            <span key={v.id} className={styles.selectedItem} onClick={(e) => e.stopPropagation()}>
              {v.label}
              <SVG.Cross style={{ fill: 'var(--color_text)' }} onClick={() => onClickOption(v.id)} />
            </span>
          ))
        ) : (
          <p className={styles.placeholder}>{props.placeholder}</p>
        )}
        <SVG.Arrow style={{ fill: 'var(--color_text)' }} />
      </div>
      {props.error && <span className={styles.error}>{props.error}</span>}
      {hasOptions && (
        <div
          className={classNames(styles.options, {
            [styles.optionsOpen]: showOptions,
            [styles.right]: props.optionsPosRight,
          })}>
          {options.map((o) => (
            <Option key={o.id} option={o} onClick={onClickOption} selected={selected} />
          ))}
        </div>
      )}
    </div>
  )
}

export default ThreeSelect
