import classNames from 'classnames'
import React, { useState } from 'react'
import styles from './LazyLoading.module.scss'
import { thumb } from 'utils'
import noImage from 'assets/images/image_not_available.png'

type PropsType = Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'> & {
  src?: string | null
  noDataImg?: NodeRequire
  width?: number
}

const LazyLoading: React.FC<PropsType> = ({ noDataImg, src = undefined, ...props }) => {
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(false)

  const noData = noDataImg ?? noImage

  if (error || !src) {
    return <img {...props} src={noData} className={props.className} />
  } else {
    return (
      <>
        <img
          {...props}
          src={thumb(src, 100)}
          className={classNames({ [styles.displayNone]: loaded }, props.className)}
          onError={() => setError(true)}
        />
        <img
          {...props}
          src={props.width ? thumb(src, props.width) : src}
          className={classNames({ [styles.displayNone]: !loaded }, props.className)}
          onLoad={() => setLoaded(true)}
        />
      </>
    )
  }
}

export default LazyLoading
