import classnames from 'classnames'
import React, { useRef, useState } from 'react'
import styles from './VideoPlayer.module.scss'
import { watchVideo } from '../../analytics'

type ProspType = React.VideoHTMLAttributes<HTMLVideoElement> & {
  url: string
  videoId: number
  poster?: string
  isPlayInHover?: boolean
  pip?: 'true' | 'false'
  isSendAnalytic?: boolean
  classNameVideo?: string
  classNameContainerVideo?: string
}

const VideoPlayer: React.FC<ProspType> = ({
  url,
  videoId,
  poster,
  isPlayInHover,
  isSendAnalytic = true,
  classNameVideo,
  classNameContainerVideo,
  ...props
}) => {
  const [loading, setLoading] = useState(true)
  const [isVideoPlayBack, setIsVideoPlayBack] = useState(true)
  const videoRef = useRef<HTMLVideoElement>(null)
  const timer = useRef<number>()

  const onPlay = () => {
    timer.current = Date.now()
  }

  const onStop = () => {
    // "Date.now() - timer.current > 1000" - protection against unnecessary triggers
    if (timer.current !== undefined && Date.now() - timer.current > 1000 && isSendAnalytic) {
      const duration = Date.now() - timer.current
      timer.current = undefined
      watchVideo({ video: videoId, duration })
    }
  }

  return (
    <div
      className={classnames(styles.containerVideo, loading && styles.containerVideoLoading, classNameContainerVideo)}
      onMouseEnter={() => {
        if (videoRef.current && isPlayInHover) videoRef.current.play()
      }}
      onMouseLeave={() => {
        if (videoRef.current && isPlayInHover) videoRef.current.pause()
      }}>
      <div className={classnames(styles.loading, !loading && styles.loadingNone)}>
        <h1>Loading...</h1>
      </div>
      <video
        {...props}
        poster={poster}
        preload={'metadata'}
        ref={videoRef}
        onPlay={onPlay}
        onPause={onStop}
        onEnded={() => {
          onStop()
          setIsVideoPlayBack(false)
        }}
        //@ts-ignore
        onLoadedData={() => {
          setLoading(false)
        }}
        onClick={() => {
          if (videoRef.current) {
            setIsVideoPlayBack(!isVideoPlayBack)
            if (isVideoPlayBack) videoRef.current.pause()
            else videoRef.current.play()
          }
        }}
        className={classnames(loading ? styles.loadingVideo : styles.video, classNameVideo)}>
        <source style={{ height: 300 }} src={url} type="video/mp4" />
      </video>
    </div>
  )
}

export default VideoPlayer

VideoPlayer.defaultProps = {
  pip: 'false',
  loop: true,
  muted: true,
  playsInline: true,
  controls: false,
  // preload: 'auto',
}
