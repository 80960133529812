import classNames from 'classnames'
import { DefaultUiTypes } from 'ui'
import styles from './Button.module.scss'
import ReactLoading from 'react-loading'

enum ButtonTypes {
  'primary',
  'secondary',
  'tertiary',
  'danger',
}
type ButtonTypesStrings = keyof typeof ButtonTypes

const classNamesByType: { [key in ButtonTypesStrings]: string } = {
  primary: styles.primary,
  secondary: styles.secondary,
  tertiary: styles.tertiary,
  danger: styles.danger,
}

type PropsType = DefaultUiTypes & {
  onClick?: (e: React.MouseEvent) => void
  type?: ButtonTypesStrings
  htmlType?: 'submit' | 'button' | 'reset'
  disabled?: boolean
  isLoading?: boolean
}

const Button: React.FC<PropsType> = ({ type = 'primary', className, children, htmlType, disabled, ...rest }) => {
  return (
    <button
      disabled={disabled}
      {...rest}
      onClick={(e) => {
        if (!rest.isLoading) {
          rest?.onClick?.(e)
        }
      }}
      type={htmlType}
      className={classNames([
        styles.button,
        classNamesByType[type],
        className,
        { [styles.disabled]: disabled },
        { [styles.loading]: rest.isLoading },
      ])}>
      <>
        {children}

        {rest?.isLoading && (
          <ReactLoading
            className={styles.loadingSpin}
            type="spin"
            color={type === 'primary' ? 'var(----color_primary_text)' : 'var(--color_primary)'}
          />
        )}
      </>
    </button>
  )
}

export default Button
