import api from 'api'
import Select from 'components/Select/Select'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { portfolioConstructorActions } from 'store/portfolioConstructorReducer'
import { useDispatch, useSelector } from 'store/store'
import styles from './SelectPortfolio.module.scss'
import { useSearchParams } from 'react-router-dom'
import { searchParams } from '../../utils'
import { PortfolioWS } from 'websocket/WebSocket'

type SelectOption = { label: string; value: number }

const SelectPortfolio = () => {
  const [_, setParams] = useSearchParams()
  const params = searchParams()
  const portfolioId = params.portfolio as number | undefined
  const dispatch = useDispatch()
  const clientId = useSelector((store) => store.client.id)
  const curPortfolio = useSelector((store) => store.portfolioConstructor.curPortfolio)
  const sellerFirmId = useSelector((store) => store.sellerFirm.id)

  const [portfolio, setPortfolio] = useState<SelectOption | undefined>()

  const { data: portfolios, isSuccess: portOk } = api.useGetPortfoliosQuery({ client: clientId! })
  const [reqPortfolio, resPortfolio] = api.useLazyGetPortfolioQuery()

  const onChangePortfolio = (p: SelectOption) => {
    if (p.value !== portfolio?.value) {
      setParams({ ...params, portfolio: p.value.toString() })
      setPortfolio(p)
    }
  }

  useEffect(() => {
    if (portOk) {
      const p = portfolios.find((p) => p.id === portfolioId) ?? portfolios[0]
      if (portfolioId === undefined || portfolioId !== p.id) setParams({ ...params, portfolio: p.id.toString() })
      setPortfolio({ label: p.name, value: p.id })
    }
  }, [portOk])

  useEffect(() => {
    if (sellerFirmId && portfolio?.value && portfolio?.value !== curPortfolio?.id) {
      reqPortfolio({ portfolioId: portfolio?.value!, seller_firm: sellerFirmId })
    }
  }, [portfolio])

  useEffect(() => {
    if (resPortfolio.isError) {
      toast.error('Something went wrong')
      setPortfolio({ label: portfolios?.[0]?.name!, value: portfolios?.[0]?.id! })
    }
    if (resPortfolio.isSuccess && resPortfolio.data !== undefined) {
      dispatch(portfolioConstructorActions.setCurPortfolio(resPortfolio.data))
      dispatch(portfolioConstructorActions.setAssets(resPortfolio.data.assets))
      dispatch(portfolioConstructorActions.setAvailableCash(resPortfolio.data.available_cash))
      dispatch(
        portfolioConstructorActions.setMyChart({
          chart: resPortfolio.data.chart,
          down_bound: resPortfolio.data.down_bound,
          real_chart: resPortfolio.data.real_chart,
          times: resPortfolio.data.times,
          up_bound: resPortfolio.data.up_bound,
          real_chart_time: resPortfolio?.data?.real_chart_time,
        }),
      )
    }
  }, [resPortfolio])

  useEffect(() => {
    const unsubscribe = PortfolioWS.subscribe({
      type: 'change_portfolio',
      cb: (newPortfolio) => {
        onChangePortfolio(newPortfolio)
      },
    })
    const usConnectedNewClient = PortfolioWS.subscribe({
      type: 'connected_new_client',
      cb: () => {
        if (portfolio) PortfolioWS.changePortfolio(portfolio)
      },
    })

    return () => {
      unsubscribe()
      usConnectedNewClient()
    }
  }, [])

  return (
    <div>
      <Select
        value={portfolio}
        containerClass={styles.select}
        onChange={(e) => {
          onChangePortfolio(e)
          PortfolioWS.changePortfolio(e)
        }}
        options={(portfolios ?? []).map((p) => {
          return { label: p.name, value: p.id }
        })}
      />
    </div>
  )
}

export default SelectPortfolio
