import classNames from 'classnames'
import React, { ChangeEvent } from 'react'
import styles from './Switch.module.scss'

type PropsType = { id: number; onClick: (val: boolean) => void; selected: boolean; className?: string }
const Switch: React.FC<PropsType> = ({ id, onClick, selected, className }) => {
  const inputProp = {
    type: 'checkbox',
    id: 'toggle' + id,
    onChange: (e: ChangeEvent<HTMLInputElement>) => onClick(e.target.checked),
    checked: selected,
  }
  return (
    <label
      className={classNames(
        styles.switch,
        {
          [styles.autoHistoryCheeckBoxDisabled]: false,
        },
        className,
      )}>
      <input {...inputProp} className={styles.autoHistoryCheeckBox} />
      <span className={styles.slider} />
    </label>
  )
}

export default Switch
