import classNames from 'classnames'
import SVG from 'components/SVG'
import { useClickOutside } from 'hooks/useClickOutside'
import React, { useEffect, useState } from 'react'
import styles from './Select.module.scss'

type Value<T> = { value: T; label: string }

type PropsType<T> = {
  onChange: (value: Value<T>) => void
  options: Value<T>[]
  value?: Value<T>
  btnClass?: string
  containerClass?: string
  error?: string
}

const Select = <T extends string | number>({ options, value, ...props }: PropsType<T>) => {
  const [showOptions, setShowOptions] = useState(false)
  const ref = useClickOutside(() => setShowOptions(false))
  const hasOptions = options.length > 0

  const onChange = (value: Value<T>) => {
    setShowOptions(false)
    props.onChange(value)
  }

  return (
    <div className={classNames([props.containerClass, styles.select])} ref={ref}>
      <button
        type={'button'}
        className={classNames([
          props.btnClass,
          styles.currentValue,
          {
            [styles.placeholder]: value === undefined,
            [styles.valueError]: props.error !== undefined,
            [styles.showOptions]: showOptions,
          },
        ])}
        onClick={() => setShowOptions((v) => hasOptions && !v)}>
        {value?.label ?? 'Select...'}
      </button>
      <div className={classNames(styles.arrow, { [styles.arrowOpen]: showOptions })}>
        <SVG.Arrow style={{ fill: 'var(--color_placeholder)', strokeWidth: 1 }} />
      </div>
      {props.error && <span className={styles.error}>{props.error}</span>}
      {hasOptions && (
        <div className={classNames(styles.options, { [styles.optionsOpen]: showOptions })}>
          {options.map((e) => (
            <button key={e.value} type={'button'} className={styles.option} onClick={() => onChange(e)}>
              {e.label}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default Select
