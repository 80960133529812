import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type InitialStateType = Partial<Firm & { seller_firm_theme: FirmTheme | null }>

const initialState: InitialStateType = {}

const sellerFirmSlice = createSlice({
  name: 'sellerFirm',
  initialState,
  reducers: {
    setSellerFirm: (state, action: PayloadAction<Firm & { seller_firm_theme: FirmTheme | null }>) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        // @ts-ignore
        state[key] = value
      })
    },
    clearSellerFirm: () => initialState,
  },
})

export const sellerFirmActions = sellerFirmSlice.actions
export const sellerFirmReducer = sellerFirmSlice.reducer
