import api from 'api'
import Loading from 'pages/Loading/Loading'
import styles from './ProductsSlider.module.scss'
import { useSelector } from 'store/store'
import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { Button } from 'ui'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'

const ProductsSlider = () => {
  const navigate = useNavigate()
  const sellerFirm = useSelector((store) => store.sellerFirm)

  const [value, setValue] = useState(0)
  const [products, setProducts] = useState<Product[]>()

  const { data, isLoading } = api.useGetProductsQuery({
    seller_firm: sellerFirm.id,
    visible_marketplace: 1,
  })

  const ref = useRef<Slider>(null)

  const changeSlide = (index: number) => {
    if (ref.current) {
      ref.current.slickGoTo(index)
    }
  }

  useEffect(() => {
    if (data !== undefined) {
      if (data.length > 3) setProducts([data[0], data[1], data[2]])
      else setProducts(data)
    }
  }, [isLoading])

  if (isLoading) {
    return (
      <div>
        <Loading />
      </div>
    )
  } else if (!products) {
    return <h2 style={{ textAlign: 'center' }}>No products</h2>
  } else {
    return (
      <div className={styles.container}>
        <div className={styles.head}>
          {products.map((p, i) => {
            return (
              <div
                onClick={() => {
                  changeSlide(i)
                }}
                key={p.id + 'head'}
                className={classNames([styles.option, { [styles.activeOption]: value === i }])}>
                <p>{p.name}</p>
                <span />
              </div>
            )
          })}
        </div>
        <Slider
          infinite
          slidesToShow={1}
          slidesToScroll={1}
          speed={500}
          afterChange={(e) => {
            console.log('afterChange')

            setValue(e)
          }}
          ref={ref}>
          {products.map((p, i) => (
            <div
              className={classNames([
                { [styles.leftSlide]: i === 0 ? value === products.length - 1 : i - 1 === value },
                { [styles.rightSlide]: i === products.length - 1 ? value === 0 : i + 1 === value },
              ])}
              key={p.id + 'slide'}>
              <div className={styles.imageBlock}>
                <img src={p.images[0].url} />
              </div>
              <div className={styles.descBlock}>
                <h1>{p.name}</h1>
                <p>{p.short_description}</p>
                <Button onClick={() => navigate(`/product/${p.id}`)}>Learn more</Button>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    )
  }
}

export default ProductsSlider
