import { useEffect } from 'react'

const useScrollLock = ({ value }: { value: boolean }) => {
  useEffect(() => {
    if (value) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }
    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [value])
}

export default useScrollLock
