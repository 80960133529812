import React from 'react'
import classNames from 'classnames'
import styles from './Checkbox.module.scss'
import SVG from 'components/SVG'

type PropsType = {
  checked: boolean
  indeterminate?: boolean
  onClick?: () => void
  className?: string
  style?: React.CSSProperties
}

const Checkbox = ({ checked, indeterminate, onClick, ...props }: PropsType) => {
  return (
    <label
      style={props.style}
      className={classNames([props.className, styles.checkbox, { [styles.checked]: checked }])}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        onClick?.()
      }}>
      {indeterminate && !checked && <div className={styles.indeterminate} />}
      {checked && <SVG.CheckMark style={{ stroke: 'var(--color_primary_text)' }} />}
      <input type={'checkbox'} className={styles.input} />
    </label>
  )
}

export default Checkbox
