import React, { useEffect, useRef, useState } from 'react'
import styles from '../../Portfolio.module.scss'

import classNames from 'classnames'
import { getAssetColor, number2currency } from 'utils'
import { useDispatch, useSelector } from 'store/store'
import AssetsBlockLine from './AssetsBlockLine'
import Button from 'ui/Button/Button'
import AssetsChart from './AssetsChart'
import ThreeSelect, { Option } from 'components/ThreeSelect/ThreeSelect'
import PopConfirm from 'components/PopConfirm/PopConfirm'
import { changeDeposit, portfolioConstructorActions } from 'store/portfolioConstructorReducer'
import SVG from 'components/SVG'
import Input from 'components/Input/Input'
import PortfolioConfirmationForm from '../PortfolioConfirmationForm/PortfolioConfirmationForm'
import SendChangeRequestBtn from './EditAssetsComponents/SendChangeRequestBtn'
import Switch from 'components/Switch/Switch'
import AddProduct from '../AddProductModal/AddProduct'
import { PortfolioWS } from 'websocket/WebSocket'

type AssetsType = {
  equity: number
}

const EditAssetsBlock: React.FC<AssetsType> = ({ equity }) => {
  const dispatch = useDispatch()
  const availableRef = useRef<null | HTMLInputElement>(null)

  const locEquity = useSelector((store) => store.portfolioConstructor.equity)
  const curPortfolio = useSelector((store) => store.portfolioConstructor.curPortfolio!)
  const categories = useSelector((store) => store.portfolioConstructor.categories!)
  const assets = useSelector((store) => store.portfolioConstructor.assets)
  const anotherAssets = useSelector((store) => store.portfolioConstructor.anotherAssets)
  const statusConnected = useSelector((store) => store.portfolioConstructor.statusConnected)
  const availableCash = useSelector((store) => store.portfolioConstructor.availableCash)
  const deposit = useSelector((store) => store.portfolioConstructor.deposit) ?? 0
  const depositStatus = useSelector((store) => store.portfolioConstructor.depositStatus)
  const anotherDeposit = useSelector((store) => store.portfolioConstructor.anotherDeposit)
  const assetsChangeMode = useSelector((store) => store.portfolioConstructor.assetsChangeMode)

  const [productsError, setProductsError] = useState<string | undefined>()
  const [selectedProducts, setSelectedProducts] = useState(curPortfolio.assets.map((a) => a.seller_product.id))
  const [isChangeSelectedProduct, setIsChangeSelectedProduct] = useState(false)
  const [isAddProductPopConfirm, setIsAddProductPopConfirm] = useState(false)
  const [isChangeAvailableCash, setIsChangeAvailableCash] = useState(false)

  const threeOptions: Option[] = categories?.map((c) => ({
    id: c.id,
    label: c.name,
    children: [...c.products]
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((p) => ({
        id: p.id,
        label: p.name,
      })),
  }))

  const getIsPercent = (quantity: number, priceName: string, price: number) => {
    if (priceName === 'Min. Investment') return ` (${Number(((quantity / locEquity!) * 100).toFixed(2))}%)`
    else return ` (${Number((((quantity * price) / locEquity!) * 100).toFixed(2))}%)`
  }

  useEffect(() => {
    setSelectedProducts(
      //@ts-ignore
      assets?.map((a) => {
        if (!a.is_deleted) return a.seller_product.id
      }),
    )
  }, [assets])

  useEffect(() => {
    availableRef?.current?.focus()
  }, [isChangeAvailableCash])

  const addDeposit = () => {
    changeDeposit(Number(availableRef?.current?.value))
    setIsChangeAvailableCash(false)
  }

  return (
    <div className={classNames([styles.block, styles.assetsBlock])}>
      <div
        style={{ opacity: depositStatus === true || depositStatus === false ? 0.3 : 1 }}
        className={styles.assetBlockContent}>
        <div className={styles.stickyContent}>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr auto 1fr' }}>
            {/* <div style={{ display: 'flex', flexDirection: 'column' }}> */}
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <h3 style={{ marginRight: 6 }}>Portfolio value now:</h3>
                <p style={{ marginRight: 6, fontWeight: 'bold' }}>{number2currency(curPortfolio?.equity)}</p>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 40 }}>
                <h3 style={{ marginRight: 6 }}>Available cash now:</h3>
                <p style={{ marginRight: 6, fontWeight: 'bold' }}>
                  {number2currency(curPortfolio?.available_cash ?? 0)}
                </p>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: 40 }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                  <h3 style={{ marginRight: 6 }}>Deposit :</h3>
                  <p style={{ marginRight: 6, fontWeight: 'bold', color: 'var(--color_confirmation)' }}>
                    {number2currency(deposit ? deposit : 0)}
                  </p>
                  <SVG.Plus
                    onClick={() => {
                      setIsChangeAvailableCash((state) => !state)
                    }}
                    style={{
                      width: 16,
                      height: 16,
                      stroke: 'var(--color_confirmation)',
                      cursor: 'pointer',
                      marginRight: 10,
                      marginLeft: 10,
                    }}
                  />
                </div>

                {/* {!isChangeAvailableCash && deposit !== undefined && deposit !== 0 && (
            <p
              style={{
                marginLeft: 10,
              }}>
              Entered:{' '}
              <span style={{ color: 'var(--color_confirmation)', fontWeight: 'bold' }}>{number2currency(deposit)}</span>
            </p>
          )} */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {isChangeAvailableCash && (
                    <Input
                      refForInput={availableRef}
                      type="number"
                      containerClass={styles.availableCashInput}
                      onKeyUp={(e) => {
                        //@ts-ignore
                        if (e.key === 'Enter' && Number(availableRef?.current?.value)) {
                          addDeposit()
                        }
                      }}
                      // value={equity.toString()}
                    />
                  )}
                  {isChangeAvailableCash && (
                    <>
                      <SVG.CheckMark
                        onClick={() => {
                          if (isChangeAvailableCash) {
                            if (Number(availableRef?.current?.value)) {
                              addDeposit()
                            }
                          }
                        }}
                        style={{
                          width: 16,
                          height: 16,
                          stroke: 'var(--color_confirmation)',
                          cursor: 'pointer',
                          marginRight: 10,
                          marginLeft: 10,
                          strokeWidth: 4,
                        }}
                      />

                      <SVG.Cross
                        onClick={() => {
                          setIsChangeAvailableCash(false)
                        }}
                        style={{ width: 14, height: 14, stroke: 'var(--color_danger)', cursor: 'pointer' }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <h3 style={{ marginRight: 6 }}>Portfolio value after transaction:</h3>
                <p style={{ marginRight: 6, fontWeight: 'bold' }}>{number2currency(equity)}</p>
                {/* {surcharge !== 0 && (
          <p style={{ fontWeight: 'bold', color: surcharge > 0 ? 'var(--color_danger)' : 'var(--color_confirmation)' }}>
            {` (${number2currency(surcharge * -1)})`}
          </p>
        )} */}
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <h3 style={{ marginRight: 6 }}>Available cash after transaction:</h3>
                <p
                  style={{
                    fontWeight: 'bold',
                    color:
                      (availableCash ?? 0) + (deposit ?? 0) < 0 ? 'var(--color_danger)' : 'var(--color_confirmation)',
                  }}>
                  {number2currency((availableCash ?? 0) + (deposit ?? 0))}
                </p>
              </div>
            </div>
          </div>
        </div>
        {assets && assets?.length > 0 && (
          <div style={{ minWidth: 300, display: 'flex', width: 300, alignSelf: 'center', minHeight: 200 }}>
            <AssetsChart
              data={assets?.map((a) => {
                return {
                  name: a.seller_product.name,
                  y:
                    a.seller_product.price_name === 'Min. Investment'
                      ? a.quantity
                      : a.quantity * a.seller_product.price,
                  color: getAssetColor(a.seller_product.id),
                }
              })}
            />
          </div>
        )}

        <div>
          {/* {colors.map((c) => (
            <div key={c} style={{ width: 100, height: 100, background: c }}>
              {c}
            </div>
          ))} */}
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 20,
            position: 'relative',
            marginTop: assets?.length === 0 ? 24 : 0,
          }}>
          <AddProduct />
          <div className={styles.switchContainer}>
            <p>Price</p>
            <Switch
              className={styles.switch}
              id={0}
              onClick={(e) => {
                if (e) {
                  dispatch(portfolioConstructorActions.setAssetsChangeMode('quantity'))
                } else {
                  dispatch(portfolioConstructorActions.setAssetsChangeMode('price'))
                }
              }}
              selected={assetsChangeMode === 'quantity'}
            />
            <p>Quantity</p>
          </div>
        </div>

        {assets && assets.length > 0 ? (
          <table className={styles.fundsTable}>
            <colgroup>
              <col style={{ width: '10px', paddingRight: '10px' }} />
              <col />
              <col style={{ width: '100%' }} />
              <col style={{ width: '120px' }} />
            </colgroup>
            <tbody>
              {assets?.map((a) => {
                return (
                  <AssetsBlockLine
                    key={a?.seller_product.id}
                    asset={a}
                    percent={getIsPercent(a?.quantity, a?.seller_product?.price_name, a?.seller_product?.price)}
                    isEdit={true}
                  />
                )
              })}
              {/* {anotherAssets
                ?.filter((f) => f.is_created)
                ?.map((a) => (
                  <CreatedLine
                    key={a?.seller_product.id}
                    asset={a}
                    percent={getIsPercent(a?.quantity, a?.seller_product?.price_name, a?.seller_product?.price)}
                    isEdit={true}
                  />
                ))} */}
            </tbody>
          </table>
        ) : (
          <div style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto', marginTop: 24 }}>
            <h1>No assets</h1>
          </div>
        )}
        <SendChangeRequestBtn />
      </div>
      {depositStatus === false && (
        <div className={styles.changeAddedCashContainer}>
          <div className={styles.changeAddedCashContent}>
            <h1>Available cash was changed</h1>
            <div>
              <h3>
                Old available cash were equal: <span>{number2currency(deposit ? deposit : 0)}</span>
              </h3>
              <h3 style={{ marginBottom: 40 }}>
                New available cash is equal:
                <span>{number2currency((anotherDeposit ? anotherDeposit : 0) + deposit)}</span>
              </h3>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h3 style={{ marginBottom: 0, marginRight: 10 }}>Do you agree to change available cash?</h3>
                <PortfolioConfirmationForm
                  onConfirm={() => {
                    dispatch(portfolioConstructorActions.changeDeposit(anotherDeposit!))
                    dispatch(portfolioConstructorActions.setDepositStatus(undefined))
                    PortfolioWS.changeDeposit((deposit ? deposit : 0) + anotherDeposit!)
                  }}
                  onCancel={() => {
                    dispatch(portfolioConstructorActions.setDeposit(deposit ? deposit : 0))
                    PortfolioWS.changeDeposit(deposit ? deposit : 0)
                  }}
                  isVisible={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {depositStatus && (
        <div className={styles.changeAddedCashContainer}>
          <div className={styles.changeAddedCashContent}>
            <h1>Wait for confirmation of changes available cash</h1>
          </div>
        </div>
      )}
    </div>
  )
}

export default React.memo(EditAssetsBlock)
