import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './SelectionSellerFirm.module.scss'
import api from 'api'
import { portfolioConstructorActions } from 'store/portfolioConstructorReducer'
import { sellerFirmActions } from 'store/sellerFirmReducer'
import { useDispatch, useSelector } from 'store/store'
import { setTheme } from 'utils'
import classNames from 'classnames'
import no_img from 'assets/images/image_not_available.png'

const SelectionSellerFirm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentFirm = useSelector((state) => state.sellerFirm)
  const { data: sellerFirms } = api.useGetSellerFirmsByUserQuery()
  const [getSellerFirm, resultSellerFirm] = api.useLazyGetSellerFirmQuery()

  const onClick = (selectedFirm: Firm) => {
    if (selectedFirm.id === currentFirm.id) navigate('/')
    else {
      getSellerFirm({ seller_firm: selectedFirm.id, params: { with_theme: 1 } })
      dispatch(portfolioConstructorActions.clearPortfolioReducer())
    }
  }

  useEffect(() => {
    if (resultSellerFirm.data) {
      dispatch(sellerFirmActions.setSellerFirm(resultSellerFirm.data))
      navigate('/')
      if (resultSellerFirm.data.seller_firm_theme) {
        setTheme(resultSellerFirm.data.seller_firm_theme)
      }
    }
  }, [resultSellerFirm.data])

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Select seller firm</h1>
      <div className={styles.firms}>
        {(sellerFirms ?? []).map((firm) => (
          <div key={firm.id}>
            <button
              onClick={() => onClick(firm)}
              className={classNames([styles.firm, { [styles.activeFirm]: firm.id === currentFirm?.id }])}>
              <span className={styles.firmName}>{firm.name}</span>
              <img src={firm.avatar_url ?? no_img} className={styles.avatar} />
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SelectionSellerFirm
