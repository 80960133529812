import React, { useEffect, useRef, useState } from 'react'
import QRCode from 'react-qr-code'
import Modal from 'components/Modal/Modal'
import styles from './SharePortfolioModal.module.scss'
import SVG from 'components/SVG'
import Button from 'ui/Button/Button'
import { useClickOutside } from 'hooks/useClickOutside'
import api from 'api'
import { useSelector } from 'store/store'
import LoadingComponent from 'components/LoadingComponent/LoadingComponent'

type PropsType = {
  onClose: () => void
  visible: boolean
}

const SharePortfolioModal: React.FC<PropsType> = ({ onClose, visible }) => {
  const [activeSnapshotButton, setActiveSnapshotButton] = useState<1 | 0>(1)
  const [offerCopy, setOfferCopy] = useState(true)
  const [link, setLink] = useState<string | undefined>()

  const linkRef = useRef<HTMLInputElement>(null)

  const curPortfolio = useSelector((store) => store.portfolioConstructor.curPortfolio)
  const sellerFirmId = useSelector((store) => store.sellerFirm.id)

  const [getSharedPortfolioToken, respSharedPortfolioToken] = api.usePostSharedPortfolioMutation()

  const onClick = () => {
    if (!linkRef.current) return
    linkRef.current.select()
    linkRef.current.setSelectionRange(0, 99999)
    document.execCommand('copy')
    setOfferCopy(false)
    setTimeout(() => {
      setOfferCopy(true)
    }, 2000)
  }

  useEffect(() => {
    if (respSharedPortfolioToken.data?.token)
      setLink(window.location.origin + '/shared_portfolio?portfolio=' + respSharedPortfolioToken.data.token)
  }, [respSharedPortfolioToken.data])

  const getSharedToken = (is_snapshot: 1 | 0) => {
    if (curPortfolio?.id && sellerFirmId) {
      setActiveSnapshotButton(is_snapshot)
      getSharedPortfolioToken({ portfolio: curPortfolio?.id, is_snapshot, seller_firm: sellerFirmId })
    }
  }
  useEffect(() => {
    getSharedToken(1)
  }, [visible])
  //window.location.origin
  return (
    <Modal title="Share your portfolio" visible={visible} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.content}>
          {respSharedPortfolioToken.isLoading ? (
            <LoadingComponent />
          ) : (
            link && (
              <>
                <div className={styles.shareBtnContainer}>
                  <Button onClick={() => getSharedToken(1)} type={activeSnapshotButton === 0 ? 'secondary' : 'primary'}>
                    Snapshot
                  </Button>
                  <Button onClick={() => getSharedToken(0)} type={activeSnapshotButton === 1 ? 'secondary' : 'primary'}>
                    Live
                  </Button>
                </div>
                <QRCode value={link} size={256} />
                <div className={styles.linkContainer}>
                  <input ref={linkRef} className={styles.linkInput} readOnly value={link} />
                  <Button onClick={onClick} className={styles.copyBtn}>
                    {offerCopy ? 'Copy' : 'Copied!'}
                  </Button>
                </div>
              </>
            )
          )}
        </div>
      </div>
    </Modal>
  )
}

export default SharePortfolioModal
