import classNames from 'classnames'
import SVG from 'components/SVG'
import React from 'react'
import styles from './PortfolioConfirmationForm.module.scss'

type PropsType = {
  onConfirm: () => void
  onCancel: () => void
  isVisible?: boolean
  className?: string
}

const PortfolioConfirmationForm: React.FC<PropsType> = ({ onConfirm, onCancel, isVisible, className }) => {
  if (isVisible) {
    return (
      <div className={classNames(styles.container, className)}>
        <button className={styles.agreement} onClick={() => onConfirm()}>
          <SVG.CheckMark style={{ height: '10px', strokeWidth: 4 }} />
        </button>
        <button className={styles.refusal} onClick={() => onCancel()}>
          <SVG.Cross style={{ height: 10, strokeWidth: 4 }} />
        </button>
      </div>
    )
  } else return <div style={{ minWidth: 20, height: 20 }}></div>
}

export default PortfolioConfirmationForm
