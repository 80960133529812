import React, { useState } from 'react'
import styles from './Input.module.scss'
import classNames from 'classnames'

type PropsType = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'className' | 'value'> & {
  value?: string
  onChange?: (v: string) => void
  inputClass?: string
  containerClass?: string
  prefix?: string
  error?: string
  anotherValue?: string
  onClickAnother?: () => void
  refForInput?: React.RefObject<any>
}

const Input = ({ inputClass, onChange, containerClass, onClickAnother, refForInput, ...props }: PropsType) => {
  const [focus, setFocus] = useState(false)
  return (
    <div
      className={classNames(styles.container, containerClass, {
        [styles.focus]: focus,
        [styles.containerError]: props.error != undefined,
      })}>
      {props.prefix && <span className={styles.prefix}>{props.prefix}</span>}
      <input
        {...props}
        ref={refForInput}
        onChange={(e) => onChange?.(e.target.value)}
        className={classNames(styles.input, inputClass)}
        onFocus={() => setFocus(true)}
        onBlur={(e) => {
          setFocus(false)
          props?.onBlur?.(e)
        }}
        onKeyPress={(event) => {
          if (props.type === 'number' && !/[0-9]/.test(event.key)) {
            event.preventDefault()
          }
        }}
      />
      {props.anotherValue && (
        <span onClick={onClickAnother} className={styles.anotherValue}>
          {props.anotherValue}
        </span>
      )}
      {props.error && <span className={styles.error}>{props.error}</span>}
    </div>
  )
}

export default Input
