import React, { useEffect, useRef } from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import styles from './InvestingChart.module.scss'
import classNames from 'classnames'
import { number2currency } from '../../../utils'
import moment from 'moment'
import { HighChartsRef } from 'utils/hc-utils'

require('highcharts/modules/boost')(Highcharts)
require('highcharts/highcharts-more')(Highcharts) // for arearange

type Props = {
  simulate: () => void
  payments: any
  charts: { chartData?: [number, number][]; ranges?: [number, number, number][]; realChart: [number, number][] }
  data?: { name: string; data: [number, number][] }[]
  name?: string
  mapNavigation?: Highcharts.MapNavigationOptions
  height?: number
  yTitle?: string
  loading?: boolean
  className?: string
}

const InvestingChart: React.FC<Props> = ({ data, name, ...props }) => {
  const ref = useRef<HighChartsRef>()
  const color_primary = getComputedStyle(document.getElementById('root')!).getPropertyValue('--color_primary')
  const options: Highcharts.Options = {
    time: {
      useUTC: false,
    },
    mapNavigation: {
      ...props.mapNavigation,
    },
    chart: {
      backgroundColor: 'var(--color_background)',
      animation: {
        duration: 0,
      },
      zoomType: 'x',
      type: 'line',
      reflow: true,
      height: 300,
      resetZoomButton: {
        position: {
          align: 'left',
          verticalAlign: 'top',
          y: -4,
          x: -2,
        },
        // relativeTo: 'spacingBox',
      },
    },
    boost: {
      useGPUTranslations: true,
      enabled: true,
      allowForce: true,
      seriesThreshold: 1,
    },
    title: { text: '' },
    scrollbar: { enabled: false },
    rangeSelector: { enabled: false },
    navigator: { enabled: false },
    xAxis: {
      type: 'datetime',
      ordinal: false,

      // ...props.xAxis,
    },
    yAxis: {
      labels: {
        align: 'left',
      },
      title: {
        //@ts-ignore
        enabled: false,
        text: props.yTitle ?? 'y axis',
      },
    },
    tooltip: {
      valueDecimals: 1,
      split: false,
      shared: true,
      // @ts-ignore
      formatter: function (a) {
        // @ts-ignore
        return this.points.reduce(function (s, point) {
          // @ts-ignore
          if (point.series.initialType === 'arearange') {
            // @ts-ignore
            const l = number2currency(point.point.low)
            // @ts-ignore
            const h = number2currency(point.point.high)
            return s + '<br/><br/>Min: ' + l + '<br/>Max: ' + h
          } else if (point.series.userOptions.id === 'lineser')
            return s + '<br/><br/>Predict Balance: ' + number2currency(point.y ?? 0)
          else if (point.series.userOptions.id === 'real_chart')
            return s + '<br/><br/>Real Balance: ' + number2currency(point.y ?? 0)
        }, '<b>' + moment(this.x).format('MM/DD/YYYY') + '</b>')
      },
    },
    plotOptions: {
      line: {
        tooltip: {
          xDateFormat: '%y',
        },
      },
      spline: {
        states: {
          hover: {
            animation: {
              duration: 0,
            },
            enabled: true,
            lineWidth: 1,
          },
        },
      },
    },
    series: [
      {
        zoneAxis: 'x',
        id: 'lineser',
        type: 'line',
        name: 'Chart',
        data: props.charts?.chartData,
        lineWidth: 1,
        states: { hover: { lineWidth: 1, opacity: 1 } },
        color: 'var(--color_primary)',
      },
      {
        zoneAxis: 'x',
        id: 'real_chart',
        type: 'line',
        name: 'Chart',
        data: props.charts?.realChart,

        lineWidth: 1,
        states: { hover: { lineWidth: 1, opacity: 1 } },
        color: 'var(--color_primary)',
      },
      {
        id: 'std',
        type: 'arearange',
        name: 'std',
        data: props?.charts?.ranges,
        lineWidth: 0,
        fillOpacity: 0.3,
        color: color_primary,
        linecap: 'butt',
        linkedTo: 'lineser',
        marker: {
          enabled: false,
        },
      },
    ],
  }

  useEffect(() => {
    if (ref.current) {
      if (props.loading === true) {
        ref.current.chart.showLoading()
      }
      if (props.loading === false) {
        ref.current.chart.hideLoading()
      }
    }
  }, [props.loading, ref.current])

  return (
    <div className={classNames(styles.container, props.className)}>
      <HighchartsReact
        //@ts-ignore
        ref={ref}
        highcharts={Highcharts}
        options={options}
        constructorType={'stockChart'}
        style={{ width: '100%' }}
      />
      {/* <Button type={'primary'} className={styles.simulateBtn} onClick={props.simulate} loading={props.loading}>
        Simulate
      </Button> */}
    </div>
  )
}

export default React.memo(InvestingChart)
