import React, { useEffect } from 'react'
import styles from '../../Portfolio.module.scss'
import classNames from 'classnames'
import { getAssetColor } from 'utils'
import AssetsBlockLine from './AssetsBlockLine'
import api from 'api'
import { useNavigate } from 'react-router'
import AssetsChart from './AssetsChart'

type Props = {
  assets?: AssetPortfolio[]
  isEdit?: boolean
  equity: number
}

const AssetsBlock = ({ assets, isEdit, equity }: Props) => {
  const navigate = useNavigate()

  const [postDemand, demandResp] = api.usePostDemandMutation()

  const getIsPercent = (quantity: number, priceName: string, price: number) => {
    if (priceName === 'Min. Investment') return ` (${Number(((quantity / equity) * 100).toFixed(2))}%)`
    else return ` (${Number(((quantity / equity) * 100).toFixed(2))}%)`
  }

  useEffect(() => {
    if (demandResp.isSuccess) navigate('/investing')
  }, [demandResp])

  return (
    <div className={classNames([styles.block, styles.assetsBlock])}>
      {assets && assets.length > 0 ? (
        <div style={{ minWidth: 300, display: 'flex', width: 300, alignSelf: 'center' }}>
          <AssetsChart
            data={assets?.map((a) => {
              return {
                name: a.seller_product.name,
                y: a.seller_product.price_name === 'Min. Investment' ? a.quantity : a.quantity * a.seller_product.price,
                color: getAssetColor(a.seller_product.id),
              }
            })}
          />
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', margin: '32px auto 8px auto' }}>
          <h1 style={{ marginBottom: 32 }}>No products</h1>
          {/* <Button onClick={() => navigate('/portfolio_constructor')}>Add products</Button> */}
        </div>
      )}
      <table className={styles.fundsTable}>
        <colgroup>
          <col style={{ width: '10px', paddingRight: '10px' }} />
          <col />
          {isEdit && <col style={{ width: '100%' }} />}
          <col style={{ width: '120px' }} />
        </colgroup>
        <tbody>
          {assets?.map((a) => (
            <AssetsBlockLine
              key={a?.seller_product.id}
              asset={a}
              percent={getIsPercent(a.quantity, a.seller_product.price_name, a.seller_product.price)}
              isEdit={isEdit}
            />
          ))}
        </tbody>
      </table>
      {isEdit && (
        <h1>Тут была кнопка отправки демона!!</h1>
        // <Button
        //   style={{ width: 210, marginLeft: 'auto', marginTop: 20 }}
        //   onClick={() =>
        //     postDemand({
        //       type: 'change',
        //       client: client_id!,
        //       assets: (assets ?? []).map((a) => {
        //         return { id: a.seller_product.id, quantity: a.quantity }
        //       }),
        //     })
        //   }>
        //   Submit a change request
        // </Button>
      )}
    </div>
  )
}

export default React.memo(AssetsBlock)
