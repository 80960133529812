import { searchParams, setTheme as setThemeUtil } from '../utils'
import { useDispatch, useSelector } from '../store/store'
import api from '../api'
import { useEffect } from 'react'
import { sellerFirmActions } from '../store/sellerFirmReducer'

export let LOGO = process.env.REACT_APP_ASSETS_URL + '/logo.svg'

const useFirmTheme = () => {
  const dispatch = useDispatch()
  const { seller_firm: sellerFirmId } = searchParams() as { seller_firm?: number }
  const sellerFirm = useSelector((store) => store.sellerFirm)
  const [getSellerFirm, resultSellerFirm] = api.useLazyGetSellerFirmQuery()

  const setTheme = () => {
    if (sellerFirm.seller_firm_theme) setThemeUtil(sellerFirm.seller_firm_theme)
    if (sellerFirm.avatar_url) LOGO = sellerFirm.avatar_url
  }

  useEffect(() => {
    if (resultSellerFirm.data) {
      dispatch(sellerFirmActions.setSellerFirm(resultSellerFirm.data))
      if (location.search.includes('seller_firm')) {
        location.search = location.search
          .replace(/seller_firm=\d+/, '')
          .replace('?&', '?')
          .replace('&&', '&')
          .replace(/&$/, '')
      }
    }
  }, [resultSellerFirm.data])

  useEffect(() => {
    if (sellerFirm.id) {
      getSellerFirm({ seller_firm: sellerFirm.id, params: { with_theme: 1 } }).then(() => {
        setTheme()
      })
    } else setTheme()
  }, [sellerFirm])

  useEffect(() => {
    if (sellerFirmId && sellerFirmId !== sellerFirm.id) {
      getSellerFirm({ seller_firm: sellerFirmId, params: { with_theme: 1 } })
    } else if (sellerFirm.id && (sellerFirm.seller_firm_theme === null || sellerFirm.seller_firm_theme === undefined)) {
      getSellerFirm({ seller_firm: sellerFirm.id, params: { with_theme: 1 } })
    } else setTheme()
  }, [])
}

export default useFirmTheme
