import classNames from 'classnames'
import SVG from 'components/SVG'
import { useClickOutside } from 'hooks/useClickOutside'
import { useEffect, useState } from 'react'
import styles from './MultiSelect.module.scss'

type Option = {
  value: string | number
  label: string
}

type PropsTypeOptions = {
  options: Option[]
  value?: (string | number)[]
  visible: boolean
  onClick: (value: string | number, isSubscribe: boolean) => void
}
const Options: React.FC<PropsTypeOptions> = ({ options, value, visible, onClick }) => {
  return (
    <div className={classNames([styles.optionsContainer, { [styles.visibleOptions]: visible }])}>
      {options.map((option, i) => {
        const isSelected = value?.includes(option.value)
        return (
          <div
            key={option.label + i}
            className={classNames([styles.option, { [styles.selectedOption]: isSelected }])}
            onClick={() => {
              onClick(option.value, !!isSelected)
            }}>
            <p>{option.label}</p>
            {isSelected && <SVG.CheckMark className={styles.check} />}
          </div>
        )
      })}
    </div>
  )
}
type PropsTypeSelectedValues = {
  options: Option[]
  value: (string | number)[]
  onClick: (value: string | number, isSubscribe: boolean) => void
}
const SelectedValues: React.FC<PropsTypeSelectedValues> = ({ options, value, onClick }) => {
  return (
    <div className={styles.valuesContainer}>
      {value.map((v) => {
        const option = options.find((o) => o.value === v)
        return (
          <div
            key={'value' + v}
            className={styles.valueContainer}
            onClick={(e) => {
              e.stopPropagation()
              onClick(v, true)
            }}>
            <p>{option?.label}</p>
            <SVG.Cross />
          </div>
        )
      })}
    </div>
  )
}

type PropsType = {
  options: Option[]
  value?: (string | number)[]
  onChange?: (values?: (string | number)[], options?: Option[]) => void
  placeholder?: string
}
const MultiSelect: React.FC<PropsType> = ({ options, onChange, value, placeholder }) => {
  const [v, sv] = useState<(string | number)[]>(value ?? [])

  useEffect(() => {
    const ops = v.map((e) => options.find((o) => o.value == e)) as Option[]
    onChange?.(v, ops)
  }, [v])
  const [isOpen, setOpen] = useState(false)
  const ref = useClickOutside(() => setOpen(false))
  const onClick = (value: string | number, isSubscribe: boolean) => {
    if (!isSubscribe) sv((v) => [...v, value])
    else {
      sv(v.filter((e) => e !== value))
    }
  }
  return (
    <div ref={ref} style={{ position: 'relative' }}>
      <div onClick={() => setOpen((e) => !e)} className={classNames([styles.select, { [styles.selectOpen]: isOpen }])}>
        {v.length === 0 && placeholder && <p className={styles.placeholder}>{placeholder}</p>}
        {v.length > 0 && <SelectedValues options={options} value={v} onClick={onClick} />}
        <SVG.Arrow className={classNames([styles.arrow])} />
      </div>
      <Options options={options} value={v} visible={isOpen} onClick={onClick} />
    </div>
  )
}

export default MultiSelect
