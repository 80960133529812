import api from 'api'
import Button from 'ui/Button/Button'
import Slider from 'components/Slider/Slider'
import ThreeSelect, { Option } from 'components/ThreeSelect/ThreeSelect'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getNewChart, portfolioConstructorActions } from 'store/portfolioConstructorReducer'
import { useSelector } from 'store/store'
import styles from '../PortfolioConstructor.module.scss'
import { PortfolioWS } from 'websocket/WebSocket'

const isHasOption = (id: number, options: Option[]) => {
  let isHas = false

  options.forEach((o) => {
    if (o.children === undefined) {
      if (o.id === id) isHas = true
    } else if (!isHas) isHas = isHasOption(id, o.children)
  })

  return isHas
}

const addNotRecognized = (options: Option[], assets?: AssetPortfolio[]) => {
  if (assets === undefined) return
  const notRecognized: AssetPortfolio[] = []
  assets.forEach((a) => {
    if (!isHasOption(a?.seller_product?.id, options)) notRecognized.push(a)
  })
  if (notRecognized.length > 0) {
    options.push({
      id: -1,
      label: 'Not recognized',
      children: notRecognized.map((a) => ({
        id: a?.seller_product?.id,
        label: a?.seller_product?.name,
      })),
    })
  }
}

const ParamsBlock = () => {
  const dispatch = useDispatch()
  const portfolio = useSelector((state) => state.portfolioConstructor.curPortfolio!)
  const categories = useSelector((state) => state.portfolioConstructor.categories!)
  const equity = useSelector((state) => state.portfolioConstructor.equity)
  const deposit = useSelector((state) => state.portfolioConstructor.deposit) ?? 0
  const assets = useSelector((state) => state.portfolioConstructor.assets)
  const sellerFirmId = useSelector((store) => store.sellerFirm.id)

  const [form, setForm] = useState<InvestingParams>({
    selectedProducts: [] as number[],
    riskLevel: 5,
    dailyDrawdown: 15,
    sharpe: 2.5,
  })

  useEffect(() => {
    setForm({ ...form, selectedProducts: portfolio.assets.map((a) => a.seller_product.id) })
  }, [])

  useEffect(() => {
    const unsubscribe = PortfolioWS.subscribe({
      type: 'investing_params',
      cb: (newParams) => {
        setForm(newParams)
      },
    })
    const usConnectedNewClient = PortfolioWS.subscribe({
      type: 'connected_new_client',
      cb: () => {
        PortfolioWS.setParams({
          ...form,
        })
      },
    })

    return () => {
      unsubscribe()
      usConnectedNewClient()
    }
  }, [])

  const [productsError, setProductsError] = useState<string | undefined>()

  const [requestConfiguration, resultConfiguration] = api.usePortfolioConfigurationMutation()

  const threeOptions: Option[] = categories.map((c) => ({
    id: c.id,
    label: c.name,
    children: c.products.map((p) => ({
      id: p.id,
      label: p.name,
    })),
  }))

  addNotRecognized(threeOptions, assets)

  const validate = () => {
    let hasError = false

    if (form.selectedProducts.length < 1) {
      setProductsError('Please specify the products')
      hasError = true
    }

    return !hasError
  }

  const onChangeSelectedProducts = (v: number[]) => {
    setForm({
      ...form,
      selectedProducts: v,
    })
    setProductsError(undefined)
    PortfolioWS.setParams({
      ...form,
      selectedProducts: v,
    })
  }

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    requestConfiguration({
      seller_firm: sellerFirmId!,
      daily_drawdown: form.dailyDrawdown!,
      equity: portfolio.equity!,
      risk_level: form.riskLevel!,
      sharpe: form.sharpe!,
      assets: form.selectedProducts.map((s) => {
        return { id: s, is_fixed: true, weight: 0 }
      }),
      portfolio_id: portfolio.id,
    })
    const isValid = validate()
  }

  useEffect(() => {
    if (resultConfiguration.data) {
      const assets = resultConfiguration.data.assets.map((a) => {
        return {
          quantity:
            a.price_name === 'Min. Investment'
              ? (portfolio.equity! + deposit) * a.weight
              : ((portfolio.equity! + deposit) * a.weight) / a.price,
          seller_product: {
            id: a.id,
            max_investment: a.max_investment,
            min_investment: a.min_investment,
            name: a.name,
            step: a.step,
            price_name: a.price_name,
            price: a.price,
          },
        }
      })
      //@ts-ignore
      dispatch(portfolioConstructorActions.setAssets(assets))
      //@ts-ignore
      PortfolioWS.setAssets(assets)
      PortfolioWS.setChart(resultConfiguration.data)
      getNewChart()
      dispatch(
        portfolioConstructorActions.setMyChart({
          chart: resultConfiguration.data.chart,
          down_bound: resultConfiguration.data.down_bound,
          real_chart: resultConfiguration.data.real_chart,
          times: resultConfiguration.data.times,
          up_bound: resultConfiguration.data.up_bound,
          real_chart_time: resultConfiguration.data.real_chart_time,
        }),
      )
    }
  }, [resultConfiguration])

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <h3 style={{ marginBottom: 24 }}>Auto-balancing</h3>
      <div className={styles.formField}>
        <label>Categories:</label>
        <ThreeSelect
          onChange={onChangeSelectedProducts}
          selected={form.selectedProducts}
          options={threeOptions}
          error={productsError}
          optionsPosRight
          btnClass={styles.threeSelect}
        />
      </div>
      <div className={styles.formField}>
        <label>Risk level: {form.riskLevel}</label>
        <Slider
          value={form.riskLevel}
          onChange={(v) => {
            setForm({
              ...form,
              riskLevel: v as number,
            })
            PortfolioWS.setParams({
              ...form,
              riskLevel: v as number,
            })
          }}
          min={1}
          max={10}
          step={1}
          marks={{ 1: '1', 10: '10' }}
          tooltip={false}
        />
      </div>
      <div className={styles.formField}>
        <label>Drawdown: {form.dailyDrawdown}%</label>
        <Slider
          value={form.dailyDrawdown}
          onChange={(v) => {
            setForm({
              ...form,
              dailyDrawdown: v as number,
            })
            PortfolioWS.setParams({
              ...form,
              dailyDrawdown: v as number,
            })
          }}
          min={1}
          max={25}
          step={1}
          marks={{ 1: '1%', 25: '25%' }}
          tooltip={false}
        />
      </div>
      <div className={styles.formField}>
        <label>Sharpe: {form.sharpe}</label>
        <Slider
          value={form.sharpe}
          onChange={(v) => {
            setForm({
              ...form,
              sharpe: v as number,
            })
            PortfolioWS.setParams({
              ...form,
              sharpe: v as number,
            })
          }}
          min={0.5}
          max={4}
          step={0.25}
          marks={{ 0.5: '0.5', 4: '4' }}
          tooltip={false}
        />
      </div>
      <Button
        style={{ marginTop: 'auto' }}
        type={'secondary'}
        htmlType={'submit'}
        disabled={resultConfiguration.isLoading}>
        Apply
      </Button>
    </form>
  )
}

export default ParamsBlock
