import Input from 'components/Input/Input'
import React, { useState } from 'react'
import styles from './InputParams.module.scss'

type PropsType = {
  onChange: (value: string | undefined) => void
  data: InputType
}

const InputParams: React.FC<PropsType> = ({ data, ...props }) => {
  const [value, setValue] = useState<string | undefined>(data.value)

  const onChange = (e: string) => {
    const value = e !== '' ? e : undefined
    setValue(value)
    props.onChange(value)
  }

  return (
    <div className={styles.inputContainer}>
      <p className={styles.title}>{data.name}:</p>
      <Input value={value} onChange={onChange} />
    </div>
  )
}

export default InputParams
