import { useEffect, useState } from 'react'
import api from 'api'
import ProductCard from 'components/ProductCard/ProductCard'
import styles from './Products.module.scss'
import Loading from '../Loading/Loading'
import { useSelector } from 'store/store'
import ProductsFilter, { FilterValuesType } from 'components/ProductsFilter/ProductsFilter'
import { useLocation } from 'react-router-dom'

const Products = () => {
  const location = useLocation()
  const locationState = location.state as FilterValuesType
  const { data: categories } = api.useGetCategoriesQuery()
  const sellerFirm = useSelector((store) => store.sellerFirm)
  const [filterParams, setFilterParams] = useState<FilterValuesType>({ ...locationState })
  const { data: products, isFetching: isProductsLoading } = api.useGetProductsQuery({
    seller_firm: sellerFirm.id,
    visible_marketplace: 1,
    ...filterParams,
  })

  const [getLikes, likes] = api.useLazyGetLikesQuery()

  useEffect(() => {
    if (products) getLikes({ products: products.map((p) => p.issuer_firm_product_id ?? p.id) })
  }, [products])

  return (
    <div className={styles.main}>
      <ProductsFilter
        categories={categories}
        products={products}
        initialValues={locationState}
        onChange={setFilterParams}
      />
      {isProductsLoading ? (
        <Loading />
      ) : (
        <>
          {products === undefined || products.length < 1 ? (
            <div className={styles.noDataMessage}>
              <h2 style={{ textAlign: 'center' }}>No products</h2>
            </div>
          ) : (
            <div className={styles.productsContainer}>
              {products?.map((e) => {
                const liked = !!likes.data?.find((l) => l === (e.issuer_firm_product_id ?? e.id))
                return <ProductCard key={e.id} data={e} liked={liked} />
              })}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default Products
