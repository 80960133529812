import styles from './Home.module.scss'
import { Button } from 'ui'
import { useNavigate } from 'react-router-dom'
import ProductsSlider from 'components/ProductsSlider/ProductsSlider'
import { useSelector } from 'store/store'
import api from 'api'
import Landing from 'components/Landing/Landing'

const Home = () => {
  const navigate = useNavigate()
  const sellerFirm = useSelector((store) => store.sellerFirm)
  const { data: landingData } = api.useGetMainProductLandingQuery(
    { seller_firm: sellerFirm.id! },
    { skip: !sellerFirm.id },
  )

  return (
    <div className={styles.container}>
      {landingData && landingData.landing && (
        <div style={{ marginBottom: 128 }}>
          <Landing data={landingData.landing.landing} />
          <Button
            style={{ width: 'max-content', margin: '0 auto' }}
            onClick={() => {
              navigate(`/product/${landingData.issuer_product_id}`)
            }}>
            Learn more
          </Button>
        </div>
      )}

      <ProductsSlider />
    </div>
  )
}

export default Home
