import { BASE_URL } from 'api'
import { ItemType } from '../index'
import styles from './ui.module.scss'

const Image: React.FC<ItemType> = ({ url, style }) => {
  return (
    <div className={styles.img}>
      <img src={BASE_URL + url} style={style} />
    </div>
  )
}
export default Image
