import { io, Socket } from 'socket.io-client'
import { connectedNewClient, disconnectedClient, portfolioConstructorActions } from 'store/portfolioConstructorReducer'
import { store } from 'store/store'

const SOCKET_URL = 'https://sancback.splinex.com'
// const SOCKET_URL = '192.168.5.174:5001'

export class PortfolioWS {
  private static socketReady = false
  private static myId: number
  private static clientId: number | undefined
  private static socket: Socket | null = null
  private static subscribes: SubscribeWS[] = []

  static initSocket = ({ myId, clientId, cb }: InitArgs) => {
    if (PortfolioWS.socket !== null) return

    PortfolioWS.myId = myId
    PortfolioWS.clientId = clientId
    PortfolioWS.socket = io(SOCKET_URL)
    PortfolioWS.socket.on('connect', () => {
      PortfolioWS.socketReady = true
      console.log('WS SOCKET CONNECTED')
      console.log('WS Hello', {
        client_id: PortfolioWS.clientId,
        my_id: PortfolioWS.myId,
      })
      PortfolioWS.socket?.emit('hello', {
        client_id: PortfolioWS.clientId,
        my_id: PortfolioWS.myId,
      })

      PortfolioWS.socket?.on('hello', (msg: { room: string }) => {
        // console.log('hello', msg)
        cb()
      })

      PortfolioWS.socket?.on(
        'connected',
        (msg: { status: 'connected' | 'not connected' | 'disconnected'; user: string }) => {
          store.dispatch(portfolioConstructorActions.setStatusConnected(msg.status))
          if (msg.status === 'disconnected') disconnectedClient()
          cb()
        },
      )
      PortfolioWS.socket?.on('connected_new_client', (msg: { status: boolean }) => {
        PortfolioWS.subscribes.forEach((s) => {
          if (s.type === 'connected_new_client') s.cb(msg.status)
        })
        connectedNewClient()
      })

      PortfolioWS.socket?.on('request_add_products', (msg: { assets: AssetPortfolio[] }) => {
        store.dispatch(
          portfolioConstructorActions.addAssets(
            msg.assets.map((a) => {
              return { ...a, is_created: false }
            }),
          ),
        )
      })

      PortfolioWS.socket?.on('add_products', (msg: { assets: AssetPortfolio[] }) => {
        store.dispatch(portfolioConstructorActions.setPortionAssets(msg.assets))
      })
      PortfolioWS.socket?.on('request_delete_products', (msg: { assets: AssetPortfolio[] }) => {
        store.dispatch(portfolioConstructorActions.setPortionAssets(msg.assets))
      })
      PortfolioWS.socket?.on('delete_products', (msg: { assets: AssetPortfolio[] }) => {
        store.dispatch(
          portfolioConstructorActions.delAssets(
            msg.assets.map((a) => {
              return a.seller_product.id
            }),
          ),
        )
      })
      PortfolioWS.socket?.on('set_assets', (msg: { assets: AssetPortfolio[] }) => {
        store.dispatch(portfolioConstructorActions.setAssets(msg.assets))
      })
      PortfolioWS.socket?.on('change_assets', (msg: { assets: AssetPortfolio[] }) => {
        store.dispatch(portfolioConstructorActions.setPortionAssets(msg.assets))
      })
      PortfolioWS.socket?.on('request_change_assets_value', (msg: { assets: AssetPortfolio[] }) => {
        store.dispatch(portfolioConstructorActions.setProposedQuantity(msg.assets))
      })
      PortfolioWS.socket?.on('change_assets_value', (msg: { assets: AssetPortfolio[] }) => {
        store.dispatch(portfolioConstructorActions.setPortionAssets(msg.assets))
      })

      PortfolioWS.socket?.on('request_change_deposit', (msg: { value: number }) => {
        store.dispatch(portfolioConstructorActions.setDepositStatus(false))
        store.dispatch(portfolioConstructorActions.setAnotherDeposit(msg.value))
      })

      PortfolioWS.socket?.on('change_deposit', (msg: { value: number }) => {
        store.dispatch(portfolioConstructorActions.setDeposit(msg.value))
      })

      PortfolioWS.socket?.on('set_chart', (msg: { chart: PortfolioChart }) => {
        store.dispatch(portfolioConstructorActions.setMyChart(msg.chart))
      })

      PortfolioWS.socket?.on('set_params', (msg: { params: InvestingParams }) => {
        PortfolioWS.subscribes.forEach((s) => {
          if (s.type === 'investing_params') s.cb(msg.params)
        })
      })
      PortfolioWS.socket?.on('change_portfolio', (msg: { portfolio: { label: string; value: number } }) => {
        PortfolioWS.subscribes.forEach((s) => {
          if (s.type === 'change_portfolio') s.cb(msg.portfolio)
        })
      })
    })
  }

  static subscribe(args: SubscribeArgs) {
    let id = 1
    if (PortfolioWS.subscribes.length > 0) {
      id = PortfolioWS.subscribes.map((s) => s.id).sort((a, b) => (a < b ? 1 : -1))[0]
    }
    PortfolioWS.subscribes.push({ id, ...args })
    return () => PortfolioWS.unsubscribe(id)
  }

  static unsubscribe(id: number) {
    PortfolioWS.subscribes = PortfolioWS.subscribes.filter((s) => s.id !== id)
  }

  static requestAddProducts = (assets: AssetPortfolio[]) => {
    PortfolioWS.socket?.emit('request_add_products', { data: { assets: assets } })
  }

  static addProducts = (assets: AssetPortfolio[]) => {
    PortfolioWS.socket?.emit('add_products', { data: { assets: assets } })
  }
  static requestDeleteProducts = (assets: AssetPortfolio[]) => {
    PortfolioWS.socket?.emit('request_delete_products', { data: { assets: assets } })
  }

  static deleteProducts = (assets: AssetPortfolio[]) => {
    PortfolioWS.socket?.emit('delete_products', { data: { assets: assets } })
  }

  static setAssets = (assets: AssetPortfolio[]) => {
    PortfolioWS.socket?.emit('set_assets', { data: { assets: assets } })
  }

  static changeAssets = (assets: AssetPortfolio[]) => {
    PortfolioWS.socket?.emit('change_assets', { data: { assets: assets } })
  }

  static requestChangeAssetsValue = (assets: AssetPortfolio[]) => {
    PortfolioWS.socket?.emit('request_change_assets_value', { data: { assets: assets } })
  }

  static changeAssetsValue = (assets: AssetPortfolio[]) => {
    PortfolioWS.socket?.emit('change_assets_value', { data: { assets: assets } })
  }

  static requestChangeDeposit = (value: number) => {
    PortfolioWS.socket?.emit('request_change_deposit', { data: { value: value } })
  }

  static changeDeposit = (value: number) => {
    PortfolioWS.socket?.emit('change_deposit', { data: { value: value } })
  }

  static setChart = (chart: PortfolioChart) => {
    PortfolioWS.socket?.emit('set_chart', { data: { chart: chart } })
  }

  static setParams = (params: InvestingParams) => {
    PortfolioWS.socket?.emit('set_params', { data: { params: params } })
  }
  static changePortfolio = (portfolio: { label: string; value: number }) => {
    PortfolioWS.socket?.emit('change_portfolio', { data: { portfolio: portfolio } })
  }

  static closeWS = () => {
    console.log('SOCKET CLOSE')
    PortfolioWS.socket?.disconnect()
    PortfolioWS.socket = null
  }
}
