import React from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { CHART_AXIS } from 'types/chartTypes'

type Props = {
  title: string
  data: [string | number, number | null][]
  x_label: string
  y_label: string
  y_type: CHART_AXIS
  x_type: CHART_AXIS
}

const dateXAxisOptions = (props: Props): Highcharts.XAxisOptions => {
  return {
    title: { text: props.x_label },
    startOnTick: true,
    endOnTick: true,
    showLastLabel: true,
    type: 'datetime',
  }
}
const defaultXAxisOptions = (props: Props): Highcharts.XAxisOptions => {
  return {
    title: { text: props.x_label },
    startOnTick: true,
    endOnTick: true,
    showLastLabel: true,
    categories: props.data.map((d) => d[0]) as string[],
    labels: { rotation: -90 },
  }
}

const BarChart: React.FC<Props> = (props) => {
  let data = props.data
  if (props.x_type === CHART_AXIS.date) {
    //@ts-ignore
    data = data.map(([ts, y]) => [(ts as number) * 1000, y]).sort((a, b) => a[0] - b[0])
  }
  const options: Highcharts.Options = {
    chart: { type: 'column' },
    legend: { enabled: false },
    title: {
      text: '', //props.title ?? 'Chart title',
    },
    xAxis: props.x_type === CHART_AXIS.date ? dateXAxisOptions(props) : defaultXAxisOptions(props),
    yAxis: { title: { text: props.y_label } },
    tooltip: {
      valueDecimals: 1,
      xDateFormat: '%m/%d/%Y',
      // tooltip: {
      // },
    },
    series: [
      {
        name: 'Series',
        type: 'column',
        //@ts-ignore
        data: props.x_type === CHART_AXIS.date ? data : data.map((d) => d[1]),
      },
    ],
  }

  return (
    <HighchartsReact highcharts={Highcharts} options={options} constructorType={'chart'} style={{ width: '100%' }} />
  )
}

export default BarChart
