import api from 'api'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'store/store'
import InvestingChart from './InvestingChart'
type PropsType = {
  isEdit?: boolean
}
const InvestingChartContainer: React.FC<PropsType> = ({ isEdit }) => {
  const myChart = useSelector((store) => store.portfolioConstructor.myChart)
  const curPortfolio = useSelector((store) => store.portfolioConstructor.curPortfolio)

  const [data, setData] = useState()
  const [mainChart, setMainChart] = useState<PortfolioChart | undefined>()

  useEffect(() => {
    if (isEdit) {
      setMainChart(myChart)
    } else {
      setMainChart(curPortfolio)
    }
  }, [curPortfolio, myChart, isEdit])

  useEffect(() => {
    if (mainChart?.times) {
      const chartData = [] as [number, number][]
      const realChart = [] as [number, number][]
      const ranges = [] as [number, number, number][]
      const up_bound = mainChart.up_bound!
      const down_bound = mainChart.down_bound!
      const chart = mainChart.chart!
      mainChart.times.forEach((ts: any, i: any) => {
        chartData.push([ts * 1000, chart[i]])
        ranges.push([ts * 1000, down_bound[i], up_bound[i]])
      })
      mainChart.real_chart_time?.forEach((ts: any, i: any) => {
        realChart.push([ts * 1000, mainChart?.real_chart?.[i]])
      })
      const detailedGoalData = {
        chart: { chartData: chartData, ranges: ranges, realChart: realChart },
        payments: [],
      }
      //@ts-ignore
      setData(detailedGoalData)
    } else {
      const detailedGoalData = {
        chart: { chartData: [], ranges: [], realChart: [] },
        payments: [],
      }
      //@ts-ignore
      setData(detailedGoalData)
    }
  }, [mainChart])

  return (
    <InvestingChart
      //@ts-ignore
      loading={false}
      //@ts-ignore
      payments={data?.payments}
      //@ts-ignore
      charts={data?.chart}
      // charts={[
      //   [
      //     [1655193571593.314, 14004.709398185041],
      //     [1656575971593.314, 15081.285075815855],
      //     [1658390371593.314, 15188.17987592597],
      //   ],
      //   [[1655193635583.8052, 14003.484736146129, 14005.321729204497]],
      // ]}
      simulate={function (): void {
        throw new Error('Function not implemented.')
      }}
    />
  )
}

export default React.memo(InvestingChartContainer)
