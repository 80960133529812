import classNames from 'classnames'
import { CSSProperties } from 'react'
import { components, componentsNameStrings, ItemType } from './landing_components'
import styles from './Landing.module.scss'

export type Landing = {
  id: string
  blockType: componentsNameStrings
  style?: CSSProperties
  value?: any
  children?: Landing[]

  //для картинки
  url?: string
  name?: string
}

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  data: Landing[]
}
const Landing: React.FC<PropsType> = ({ data, className, ...htmlProps }) => {
  return (
    <div {...htmlProps} className={classNames([styles.landing, className])}>
      {data.map(({ blockType, ...params }) => {
        const Block: React.FC<ItemType> = components[blockType]
        return <Block key={blockType + params.id} {...params} />
      })}
    </div>
  )
}

export default Landing
