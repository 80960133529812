import React, { useEffect, useState } from 'react'
import styles from './PortfolioConstructor.module.scss'
import api from 'api'
import Loading from 'pages/Loading/Loading'
import ParamsBlock from './Components/ParamsBlock'
import MainBlock from '../Components/MainBlock'
import { useSelector } from 'store/store'
import { useDispatch } from 'react-redux'
import { getNewChart, portfolioConstructorActions } from 'store/portfolioConstructorReducer'
import { profileActions } from 'store/profileReducer'
import EditAssetsBlock from '../Components/Assets/EditAssetsBlock'
import { PortfolioWS } from 'websocket/WebSocket'
import { searchParams } from 'utils'
import RequestChangePortfolio from './RequestChangePortfolio/RequestChangePortfolio'

type Props = {
  assets?: AssetPortfolio[]
  equity: number
}

const PortfolioConstructor = ({ assets, equity }: Props) => {
  return (
    <div className={styles.page}>
      {/* <AdviserModal /> */}
      <MainBlock equity={equity} isEdit />
      <ParamsBlock />
      <EditAssetsBlock equity={equity} />

      {/* <RequestChangePortfolio /> */}
    </div>
  )
}

const PortfolioConstructorContainer = ({ myId }: { myId?: number }) => {
  const dispatch = useDispatch()
  const clientId = useSelector((store) => store.client.id)
  const categories = useSelector((state) => state.portfolioConstructor.categories!)
  const portfolio = useSelector((state) => state.portfolioConstructor.curPortfolio!)
  const assets = useSelector((state) => state.portfolioConstructor.assets!)
  const equity = useSelector((state) => state.portfolioConstructor.equity)
  const anotherAssets = useSelector((state) => state.portfolioConstructor.anotherAssets!)
  const statusConnected = useSelector((state) => state.portfolioConstructor.statusConnected)
  const deposit = useSelector((state) => state.portfolioConstructor.deposit) ?? 0
  const depositStatus = useSelector((state) => state.portfolioConstructor.depositStatus)
  const sellerFirmId = useSelector((store) => store.sellerFirm.id)

  const [wsReady, setWsReady] = useState(false)

  const portfolios = api.useGetPortfoliosQuery({ client: clientId! })
  const [reqPortfolio, resPortfolio] = api.useLazyGetPortfolioQuery()
  const {
    data: categoriesResp,
    isError: ctgError,
    isSuccess: ctgOk,
  } = api.useGetInvestingCategoriesQuery({
    seller_firm: sellerFirmId!,
  })

  useEffect(() => {
    getNewChart()
    if (assets) {
      // console.log(1234, assets)
      let x = 0
      const newEquity = assets
        .map(
          (a) =>
            (x += a.seller_product.price_name === 'Min. Investment' ? a.quantity : a.quantity * a.seller_product.price),
          (x = 0),
        )
        .reverse()[0]
      if (portfolio !== undefined) {
        dispatch(
          portfolioConstructorActions.setAvailableCash(portfolio.equity - (newEquity ?? 0) + portfolio.available_cash),
        )
      }
      dispatch(portfolioConstructorActions.setEquity(newEquity))
    }
  }, [assets, depositStatus])

  useEffect(() => {
    PortfolioWS.initSocket({
      myId: myId ?? clientId!,
      clientId: myId !== undefined ? clientId : undefined,
      cb: () => setWsReady(true),
    })
    console.log('initSocket')
    if (wsReady) {
      // GameService.
    }
  }, [])

  useEffect(() => {
    return () => {
      setWsReady(false)
      PortfolioWS.closeWS()
    }
  }, [])

  useEffect(() => {
    if (sellerFirmId && portfolios.isSuccess && portfolios.data.length > 0) {
      reqPortfolio({ portfolioId: portfolios.data[0].id, seller_firm: sellerFirmId })
    }
  }, [portfolios.isSuccess])

  useEffect(() => {
    if (resPortfolio.isSuccess && resPortfolio.data !== undefined) {
      dispatch(portfolioConstructorActions.setCurPortfolio(resPortfolio.data))
      if (!assets || assets.length === 0) dispatch(portfolioConstructorActions.setAssets(resPortfolio.data.assets))
      dispatch(portfolioConstructorActions.setAvailableCash(resPortfolio.data.available_cash))

      dispatch(
        portfolioConstructorActions.setMyChart({
          chart: resPortfolio.data.chart,
          down_bound: resPortfolio.data.down_bound,
          real_chart: resPortfolio.data.real_chart,
          times: resPortfolio.data.times,
          up_bound: resPortfolio.data.up_bound,
          real_chart_time: resPortfolio?.data?.real_chart_time,
        }),
      )
    }
  }, [resPortfolio.isSuccess])

  useEffect(() => {
    if (ctgOk && categoriesResp !== undefined) {
      dispatch(portfolioConstructorActions.setCategories(categoriesResp))
    }
  }, [ctgOk])

  if (resPortfolio.isError || ctgError) return <h2>Error</h2>
  if (portfolio === undefined || categories === undefined || resPortfolio.data === undefined) return <Loading />
  return <PortfolioConstructor assets={assets} equity={equity ?? 0} />
}

const PortfolioConstructorContainerContainer = () => {
  const params = searchParams()
  const myId = params.myId as number | undefined
  const clientIdFromUrl = params.clientId as number | undefined
  const portfolioIdFromUrl = params.portfolio as number | undefined
  const dispatch = useDispatch()
  const clientId = useSelector((store) => store.client.id)

  if (myId !== undefined && clientIdFromUrl !== undefined && portfolioIdFromUrl !== undefined) {
    if (clientId === clientIdFromUrl && myId !== clientIdFromUrl) {
      return <PortfolioConstructorContainer myId={myId} />
    } else {
      dispatch(profileActions.updateUserInfo({ client_id: clientIdFromUrl }))
      return <Loading />
    }
  } else return <PortfolioConstructorContainer />
}

export default PortfolioConstructorContainerContainer
