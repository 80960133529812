import api from 'api'
import React, { useEffect } from 'react'
import CreatePortfolio from './CreatePortfolio/CreatePortfolio'
import Portfolio from './Portfolio'
import Loading from '../Loading/Loading'
import { useDispatch, useSelector } from 'store/store'
import PortfolioStatus from './Components/PortfolioStatus'
import { portfolioConstructorActions } from 'store/portfolioConstructorReducer'
import Page404 from '../Page404/Page404'
import { searchParams } from '../../utils'
import { useSearchParams } from 'react-router-dom'

const InvestingContainer = () => {
  const [_, setParams] = useSearchParams()
  const params = searchParams()
  const portfolioId = params.portfolio as number | undefined
  const dispatch = useDispatch()
  const clientId = useSelector((store) => store.client.id)
  const sellerFirmId = useSelector((store) => store.sellerFirm.id)

  const {
    data: demands,
    refetch: refetchDemands,
    ...demandsResp
  } = api.useGetDemandQuery({ client: clientId!, create: 1, is_archived: 0 })
  const {
    data: portfolios,
    refetch: refetchPortfolios,
    ...portfoliosResp
  } = api.useGetPortfoliosQuery({ client: clientId! })
  const [getPortfolio, { data: portfolio, ...portfolioResp }] = api.useLazyGetPortfolioQuery()
  const { data: categories, ...categoriesResp } = api.useGetInvestingCategoriesQuery({
    seller_firm: sellerFirmId!,
  })

  useEffect(() => {
    if (portfoliosResp.isSuccess && portfolios !== undefined && portfolios.length > 0) {
      if (portfolioId === undefined || sellerFirmId === undefined) {
        if (portfolios[0].id) setParams({ portfolio: portfolios[0].id.toString() })
      } else {
        getPortfolio({ portfolioId, seller_firm: sellerFirmId })
      }
    }
  }, [portfolioId, portfoliosResp.isSuccess])

  useEffect(() => {
    if (portfolioResp.isSuccess && portfolio !== undefined) {
      dispatch(portfolioConstructorActions.setCurPortfolio(portfolio))
    }
  }, [portfolioResp.isSuccess])

  useEffect(() => {
    if (categoriesResp.isSuccess && categories !== undefined) {
      dispatch(portfolioConstructorActions.setCategories(categories))
    }
  }, [categoriesResp])

  useEffect(() => {
    const interval = setInterval(() => {
      refetchDemands()
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    const portfoliosInterval = setInterval(() => {
      if (portfolios?.length === 0) refetchPortfolios()
      else clearInterval(portfoliosInterval)
    }, 1000)

    return () => {
      clearInterval(portfoliosInterval)
    }
  }, [portfolios])

  if (portfoliosResp.error) {
    return <h1>Error. Please come back later</h1>
  } else if (portfolioResp.error) {
    return <Page404 />
  } else if (portfoliosResp.isLoading || portfolioResp.isLoading || demandsResp.isLoading) {
    return <Loading />
  } else if (portfolios!.length === 0 && demands!.some((d) => d.type === 'create')) {
    return <PortfolioStatus />
  } else if (portfolios!.length === 0) {
    return <CreatePortfolio />
  } else {
    return <Portfolio />
  }
}

export default InvestingContainer
