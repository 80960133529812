export enum CHART_TYPE {
  bar = 'bar',
  pie = 'pie',
  scatter = 'scatter',
}

export enum CHART_AXIS {
  date = 'date',
  float = 'float',
  string = 'string',
}

export type Chart = {
  id: number
  title: string
  x_label: string
  x_type: CHART_AXIS
  y_label: string
  y_type: CHART_AXIS
  order: number
  chart_type: CHART_TYPE
  chart: {
    x: (string | number)[]
    y: (string | number)[]
  }
}
