import React, { useEffect, useState } from 'react'
import styles from '../Portfolio.module.scss'
import classNames from 'classnames'
import { number2currency } from 'utils'
import Button from 'ui/Button/Button'
import { useNavigate } from 'react-router-dom'
import InvestingChartContainer from '../Chart/InvestingChartContainer'
import SharePortfolioModal from './SharePortfolioModal'
import api from 'api'
import Notifications from 'components/Notifications/Notifications'
import SelectPortfolio from 'components/SelectPortfolio/SelectPortfolio'
import { useSelector } from 'store/store'
import { toast } from 'react-toastify'
import SVG from 'components/SVG'

type Props = {
  isEdit?: boolean
  equity: number
  isShared?: boolean
}

const MainBlock = ({ isEdit, equity, isShared }: Props) => {
  const navigate = useNavigate()
  const [isVisibleShareModal, setIsVisibleShareModal] = useState<boolean>(false)

  const clientId = useSelector((store) => store.client.id)
  const curPortfolio = useSelector((store) => store.portfolioConstructor.curPortfolio)

  // const dif = balance - initialAmmount
  const { data: editDemand, refetch: refetchEditDemand } = api.useGetDemandQuery({
    edit: 1,
    is_archived: 0,
    client: clientId!,
  })
  const { data: updateDemand, refetch: refetchUpdateDemand } = api.useGetDemandQuery({
    update: 1,
    is_archived: 0,
    client: clientId!,
  })
  const [reqDemand, resDemand] = api.usePostDemandMutation()

  useEffect(() => {
    const editDemandInterval = setInterval(() => refetchEditDemand(), 2000)
    const updateDemandInterval = setInterval(() => refetchUpdateDemand(), 2000)

    return () => {
      clearInterval(editDemandInterval)
      clearInterval(updateDemandInterval)
    }
  }, [])

  return (
    <div className={classNames([styles.block, styles.mainChartBlock])}>
      {!isShared && (
        <>
          <div className={styles.selectPortfolioContainer}>
            {<SelectPortfolio />}
            {editDemand && editDemand.length > 0 && (
              <Notifications.Warning
                // title={`You already have ${editDemond.length} change ${editDemond.length === 1 ? 'request' : 'requests'} `}
                style={{ marginLeft: 16 }}>
                <div style={{ marginLeft: 10 }}>
                  {editDemand && (
                    <p>
                      You already have <span style={{ color: 'var(--color_primary)' }}>{editDemand.length} </span>change
                      {editDemand.length === 1 ? ' request' : ' requests'}
                    </p>
                  )}
                </div>
              </Notifications.Warning>
            )}
            {updateDemand && updateDemand.length > 0 && (
              <Notifications.Info
                // title={`You already have ${editDemond.length} change ${editDemond.length === 1 ? 'request' : 'requests'} `}
                style={{ marginLeft: 16 }}>
                <div style={{ marginLeft: 10 }}>{updateDemand && <p>You already have update request</p>}</div>
              </Notifications.Info>
            )}
          </div>
          <div style={{ display: 'flex', marginBottom: 16 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!isEdit && (
                <p style={{ fontSize: 20 }}>
                  Balance: <b>{number2currency(equity)}</b>
                </p>
              )}
            </div>
            {!isEdit ? (
              <>
                <Button
                  style={{ marginLeft: 'auto' }}
                  onClick={() => {
                    if (updateDemand?.length === 0) {
                      reqDemand({ type: 'update', portfolio: curPortfolio?.id!, client: clientId! })
                    } else {
                      toast.error('You already have update request')
                    }
                  }}
                  isLoading={resDemand.isLoading}>
                  Request update
                </Button>
                <Button
                  style={{ marginLeft: 16 }}
                  onClick={() => navigate('/portfolio_constructor?portfolio=' + curPortfolio?.id)}>
                  Edit
                </Button>
                <Button style={{ marginLeft: 16 }} onClick={() => setIsVisibleShareModal(true)}>
                  <div className={styles.shareButton}>
                    <p>Share</p>
                    <SVG.Qr />
                  </div>
                </Button>
              </>
            ) : null}
          </div>
        </>
      )}

      {/* <MainChart /> */}
      <InvestingChartContainer isEdit={isEdit || isShared} />
      <SharePortfolioModal onClose={() => setIsVisibleShareModal(false)} visible={isVisibleShareModal} />
    </div>
  )
}

export default React.memo(MainBlock)
