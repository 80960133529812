import React, { useEffect, useRef, useState } from 'react'
import styles from './Comments.module.scss'
import Button from 'ui/Button/Button'
import api from 'api'
import { useParams } from 'react-router-dom'
import { useSelector } from 'store/store'
import classNames from 'classnames'
import SVG from 'components/SVG'
import PopConfirm from 'components/PopConfirm/PopConfirm'
import { fromNow } from 'utils'

type CommentLocalType = {
  comment: CommentType
  className?: string
  replyComment?: CommentType
  setReplyComment: React.Dispatch<React.SetStateAction<CommentType | undefined>>
}

const Comment: React.FC<CommentLocalType> = ({ comment, className, setReplyComment, replyComment }) => {
  const userId = useSelector((store) => store.profile.id)

  const [isVisiblePopConfirmDelete, setIsVisiblePopConfirmDelete] = useState(false)

  const [delComment, respDelComment] = api.useDeleteCommentMutation()

  return (
    <div className={classNames(styles.commentContainer, className)}>
      <div className={styles.commentContent}>
        <div className={styles.userNameContainer}>
          {comment.parent_comment && (
            <p style={{ color: 'var(--color_placeholder)', marginRight: 10 }}>
              Replied {comment.parent_comment.user_name}
            </p>
          )}
          <p className={styles.username}>{comment.user.username}</p>
          <p
            className={styles.reply}
            onClick={() => {
              if (replyComment?.id === comment.id) {
                setReplyComment(undefined)
              } else setReplyComment({ ...comment })
            }}>
            Reply
          </p>
        </div>
        <p>{comment.text}</p>
        <p style={{ marginLeft: 'auto', color: 'var(--color_placeholder)' }}>{fromNow(comment.create_at * 1000)}</p>
      </div>
      {comment.user.id === userId && (
        <div className={styles.edit_delContainer}>
          <SVG.Delete
            style={{ fill: 'var(--color_danger)', cursor: 'pointer' }}
            onClick={() => {
              if (!isVisiblePopConfirmDelete) setIsVisiblePopConfirmDelete(true)
              else console.log('aloooo')
            }}
          />
          {isVisiblePopConfirmDelete && (
            <PopConfirm className={styles.popConfirm} onClose={() => setIsVisiblePopConfirmDelete(false)}>
              <p style={{ marginBottom: 20 }}>Are you sure you want to delete the comment?</p>
              <div className={styles.popConfirmBtnContainer}>
                <Button
                  onClick={() => setIsVisiblePopConfirmDelete(false)}
                  style={{ background: 'var(--color_danger)' }}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    delComment(comment.id)
                    setIsVisiblePopConfirmDelete(false)
                  }}>
                  Delete
                </Button>
              </div>
            </PopConfirm>
          )}
        </div>
      )}
    </div>
  )
}

const Comments: React.FC = () => {
  const { id: productId } = useParams()
  const respComments = api.useGetCommentsQuery({ product: Number(productId!) })
  const [addComment, respAddComment] = api.usePostCommentMutation()

  const [comments, setComments] = useState<CommentType[]>([])
  const [newComment, setNewComment] = useState('')
  const [replyComment, setReplyComment] = useState<CommentType | undefined>()

  const hasComments = comments && comments?.length > 0

  const user = useSelector((store) => store.profile)

  const refCommentsContainer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (respComments.data) setComments([...respComments.data])
    if (refCommentsContainer.current) {
      setTimeout(
        () =>
          refCommentsContainer.current?.scrollBy({
            top: refCommentsContainer.current.scrollHeight,
            behavior: 'smooth',
          }),
        0,
      )
    }
  }, [respComments.data])

  return (
    <>
      {hasComments ? (
        <div className={styles.commentsContainer} ref={refCommentsContainer}>
          {[...comments].reverse().map((c, i) => {
            return <Comment key={c.id ?? i} comment={c} setReplyComment={setReplyComment} replyComment={replyComment} />
          })}
        </div>
      ) : (
        <h2 style={{ margin: '64px 0', textAlign: 'center', opacity: 0.6 }}>No comments</h2>
      )}

      <div>
        {replyComment && (
          <p style={{ marginBottom: 5 }}>
            Answer <span style={{ color: 'var(--color_link)' }}>{replyComment.user.username}</span>
          </p>
        )}
        <div className={styles.textareaContainer}>
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            className={styles.textareaComment}
          />
          <Button
            className={styles.addNewCommentBtn}
            disabled={!newComment}
            isLoading={respAddComment.isLoading}
            onClick={() => {
              addComment({
                product: Number(productId!),
                text: newComment,
                user_id: user.id!,
                parent_comment: replyComment?.id,
              })
              // //@ts-ignore
              // setComments((state) => [
              //   ...state,
              //   {
              //     text: newComment,
              //     user: user,
              //     create_at: new Date().getTime() / 1000,
              //     parent_comment: replyComment
              //       ? { id: replyComment.id, user_name: replyComment.user.username }
              //       : undefined,
              //   },
              // ])
              setReplyComment(undefined)
              setNewComment('')
            }}>
            Send
          </Button>
        </div>
      </div>
    </>
  )
}

export default Comments
