export const IS_TOUCH =
  typeof window !== 'undefined' &&
  // @ts-ignore
  ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0)

export const number2currency = (number: number, fractionDigits = 0) => {
  if (number === undefined) return ''
  return new Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
    //@ts-ignore
    useGrouping: 'min2',
  }).format(number)
}

export const number2Abbr = (number: number) => {
  return new Intl.NumberFormat('en-US', { style: 'decimal', notation: 'compact', compactDisplay: 'short' }).format(
    number,
  )
}

export const roundedIntermediateValues = (min: number, max: number, maxCount = 10): number[] => {
  const getValues = (min: number, max: number, step: number) => {
    const res: number[] = []
    for (let i = min + step; i < max; i += step) res.push(i)
    return res
  }
  const dif = max - min
  if (dif < 100) return []
  const r = ('' + dif).length
  let step = Math.pow(10, r - 2)
  let res = getValues(min, max, step)
  while (res.length > maxCount) {
    step = step * 10
    res = getValues(min, max, step)
  }
  return res
}

export const thumb = (url?: string | null, width = 500) => {
  if (url === null) return undefined
  if (url === undefined) return undefined
  if (!url) return ''
  return (
    `https://sancback.splinex.com/thumb/unsafe/${width}x0/` +
    encodeURIComponent(url.replace('https://', '').replace('http://', ''))
  )
}

export const isIOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

export const isValidEmail = (email: string) => {
  return /.+@.+\..+/.test(email)
}

export const searchParams = () => {
  const search = location.search
  const params: { [key: string]: string | number } = {}
  search
    .replace('?', '')
    .split('&')
    .forEach((p) => {
      const [key, value] = p.split('=')
      if (/^\d+$/.test(value)) params[key] = Number(value)
      else params[key] = value
    })
  return params
}

export const getHref = (params: any) => {
  let href = window.location.pathname.replace('/from_email', '')

  switch (params.page) {
    case 'Campaign':
      href += '/' + 'campaign_product' + '/' + params.params.campaign
      break
    case 'Product':
      href += '/' + 'product' + '/' + params.params.id
      break

    default:
      href += '/' + 'products'
      break
  }

  return href
}

export const getSumQuantity = (assets: AssetPortfolio[]) => {
  let x = 0
  const equity = Math.round(
    assets
      .map(
        (a) =>
          (x += a.seller_product.price_name === 'Min. Investment' ? a.quantity : a.quantity * a.seller_product.price),
        (x = 0),
      )
      .reverse()[0],
  )
  return equity
}

export const getAssetColor = (id: number) => {
  const colors = [
    'rgb(47, 126, 216, ',
    'rgb(13, 35, 58, ',
    'rgb(139, 188, 33, ',
    'rgb(145, 0, 0, ',
    'rgb(26, 173, 206, ',
    'rgb(73, 41, 112, ',
    'rgb(242, 143, 67, ',
    'rgb(119, 161, 229, ',
    'rgb(196, 37, 37, ',
    'rgb(166, 201, 106, ',
    'rgb(255, 160, 122, ',
    'rgb(102, 205, 170, ',
    'rgb(216, 191, 216, ',
    'rgb(154, 205, 50, ',
    'rgb(128, 128, 0, ',
    'rgb(138, 43, 226, ',
    'rgb(173, 216, 230, ',
    'rgb(188, 143, 143, ',
    'rgb(0, 255, 127, ',
    'rgb(220, 20, 60, ',
  ]
  const randomColor = (((id * 18745) / 1984) * 9) % 19
  const randomOpacity = (((id * 4789) / 1984) * 9) % 2

  let opacity

  switch (Number(randomOpacity.toFixed(0))) {
    case 0:
      opacity = 1
      break
    case 1:
      opacity = 0.8
      break
    default:
      opacity = 0.6
  }

  // console.log('randomNumber', colors[Number(randomColor.toFixed(0))] + opacity + ')')
  return colors[Number(randomColor.toFixed(0))] + opacity + ')'
}

export const getOpacity = (id: number) => {
  const randomNumber = (((id * 4789) / 1984) * 9) % 4
}

export const deleteFieldsAsset = (asset: AssetPortfolio) => {
  const newAsset = { ...asset }
  delete newAsset.is_created
  delete newAsset.proposed_quantity
  delete newAsset.is_deleted
  return { ...newAsset }
}

export const fromNow = (date: number | string | Date) => {
  const SECOND = 1000
  const MINUTE = 60 * SECOND
  const HOUR = 60 * MINUTE
  const DAY = 24 * HOUR
  const WEEK = 7 * DAY
  const MONTH = 30 * DAY
  const YEAR = 365 * DAY
  const units = [
    {
      max: 30 * SECOND,
      divisor: 1,
      past1: 'just now',
      pastN: 'just now',
      future1: 'just now',
      futureN: 'just now',
    },
    {
      max: MINUTE,
      divisor: SECOND,
      past1: 'a second ago',
      pastN: '# seconds ago',
      future1: 'in a second',
      futureN: 'in # seconds',
    },
    {
      max: HOUR,
      divisor: MINUTE,
      past1: 'a minute ago',
      pastN: '# minutes ago',
      future1: 'in a minute',
      futureN: 'in # minutes',
    },
    {
      max: DAY,
      divisor: HOUR,
      past1: 'an hour ago',
      pastN: '# hours ago',
      future1: 'in an hour',
      futureN: 'in # hours',
    },
    {
      max: WEEK,
      divisor: DAY,
      past1: 'yesterday',
      pastN: '# days ago',
      future1: 'tomorrow',
      futureN: 'in # days',
    },
    {
      max: 4 * WEEK,
      divisor: WEEK,
      past1: 'last week',
      pastN: '# weeks ago',
      future1: 'in a week',
      futureN: 'in # weeks',
    },
    {
      max: YEAR,
      divisor: MONTH,
      past1: 'last month',
      pastN: '# months ago',
      future1: 'in a month',
      futureN: 'in # months',
    },
    {
      max: 100 * YEAR,
      divisor: YEAR,
      past1: 'last year',
      pastN: '# years ago',
      future1: 'in a year',
      futureN: 'in # years',
    },
    {
      max: 1000 * YEAR,
      divisor: 100 * YEAR,
      past1: 'last century',
      pastN: '# centuries ago',
      future1: 'in a century',
      futureN: 'in # centuries',
    },
    {
      max: Infinity,
      divisor: 1000 * YEAR,
      past1: 'last millennium',
      pastN: '# millennia ago',
      future1: 'in a millennium',
      futureN: 'in # millennia',
    },
  ]
  const diff = Date.now() - (typeof date === 'object' ? date : new Date(date)).getTime()
  const diffAbs = Math.abs(diff)
  for (const unit of units) {
    if (diffAbs < unit.max) {
      const isFuture = diff < 0
      const x = Math.round(Math.abs(diff) / unit.divisor)
      if (x <= 1) return isFuture ? unit.future1 : unit.past1
      return (isFuture ? unit.futureN : unit.pastN).replace('#', x.toString())
    }
  }
}

export const setTheme = (data: Partial<FirmTheme>) => {
  const theme = { ...data }
  delete theme.id
  delete theme.seller_firm
  const body = document.body
  for (const t in theme) {
    //@ts-ignore
    body.style.setProperty('--' + t, theme[t])
  }
}
