import React, { useEffect, useState } from 'react'
import Slider from 'rc-slider'
import styles from './SliderPortfolio.module.scss'
import PortfolioConfirmationForm from './PortfolioConfirmationForm/PortfolioConfirmationForm'
import { useDispatch, useSelector } from 'store/store'
import { portfolioConstructorActions } from 'store/portfolioConstructorReducer'
import { PortfolioWS } from 'websocket/WebSocket'

type PropsType = {
  max: number
  min: number
  step: number
  defaultValue: number
  onChange: (value: number) => void
  anotherAsset?: AnotherAssetPortfolio
  price?: number
  maxForSlider: number
  asset: AssetPortfolio
}

const SliderPortfolio: React.FC<PropsType> = ({
  max,
  min,
  step,
  defaultValue,
  onChange,
  anotherAsset,
  price,
  maxForSlider,
  asset,
}) => {
  const dispatch = useDispatch()
  const [value, setValue] = useState<number>(defaultValue)
  const assetsChangeMode = useSelector((store) => store.portfolioConstructor.assetsChangeMode)
  const anotherValue =
    asset?.proposed_quantity === undefined
      ? undefined
      : asset?.seller_product.price_name === 'Min. Investment'
      ? asset.proposed_quantity
      : asset.proposed_quantity * asset.seller_product.price

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const marks: { [key: string]: { label: string; style: any } } = {}
  if (asset?.proposed_quantity !== undefined && anotherValue !== defaultValue) {
    if (assetsChangeMode === 'price' && anotherValue !== undefined) {
      marks[anotherValue?.toFixed(2)] = { label: anotherValue?.toFixed(2), style: { top: -36 } }
    }
    if (assetsChangeMode === 'quantity' && anotherValue !== undefined) {
      marks[anotherValue?.toFixed(2)] = { label: asset?.proposed_quantity.toFixed(2), style: { top: -36 } }
    }
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <p style={{ width: 80, textAlign: 'left' }}>
        {asset?.seller_product.price_name !== 'Min. Investment' ? price && '$' + price : null}
      </p>
      <div style={{ width: '100%', marginRight: 16 }}>
        <Slider
          style={{ width: 'auto', marginLeft: 16 }}
          min={min}
          max={max}
          step={step}
          marks={marks}
          value={value}
          onAfterChange={(e) => {
            const value = Math.floor(Number(e) * 100) / 100
            onChange(value as number)
          }}
          className={styles.portfolioSlider}
          // activeDotStyle={{ borderColor: 'var(--color_confirmation)' }}
          onChange={(e) => {
            setValue(e as number)
          }}
          dotStyle={{ width: 20, height: 20, borderColor: 'var(--color_confirmation)' }}
        />
        <div className={styles.minMaxContainer}>
          <p>{min.toFixed(2)}</p>
          <p>{maxForSlider.toFixed(0)}</p>
        </div>
      </div>
      <PortfolioConfirmationForm
        onConfirm={() => {
          dispatch(
            portfolioConstructorActions.setAsset({
              ...asset,
              //@ts-ignore
              quantity: asset?.proposed_quantity,
              proposed_quantity: undefined,
            }),
          )
          PortfolioWS.changeAssetsValue([
            {
              ...asset,
              //@ts-ignore
              quantity: asset?.proposed_quantity,
              proposed_quantity: undefined,
            },
          ])
        }}
        onCancel={() => {
          dispatch(
            portfolioConstructorActions.setAsset({
              ...asset,
              proposed_quantity: undefined,
            }),
          )
          PortfolioWS.changeAssetsValue([
            {
              ...asset,
              proposed_quantity: undefined,
            },
          ])
        }}
        isVisible={anotherValue !== undefined && anotherValue !== defaultValue}
      />
    </div>
  )
}

export default SliderPortfolio
