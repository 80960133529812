import React, { useEffect, useState } from 'react'
import styles from './Login.module.scss'
import api from '../../api'
import { Link, useNavigate } from 'react-router-dom'
import GoToApp from 'components/GoToApp/GoToApp'
import translation from 'assets/locales/en/translation.json'
import Button from '../../ui/Button/Button'
import Input from 'components/Input/Input'

type Props = {
  initialUsername?: string
  autogenerated?: boolean
  href?: string
}

const Login = ({ initialUsername = '', href = '/', autogenerated = false }: Props) => {
  const navigate = useNavigate()
  const [username, setUsername] = useState(initialUsername)
  const [password, setPassword] = useState('')
  const [error, setError] = useState<string>()
  const [loginRequest, loginResponse] = api.useLoginMutation()

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setError(undefined)
    loginRequest({ username, password })
  }

  useEffect(() => {
    if (loginResponse.isSuccess) {
      if (loginResponse.isError) setError('Network error')
      else if (loginResponse.data?.error) setError('Invalid credentials')
      else navigate(href)
    }
  }, [loginResponse])

  return (
    <div className={styles.page}>
      <GoToApp />
      <form className={styles.form} onSubmit={onSubmit}>
        <h1 className={styles.title}>{translation.Login.title}</h1>
        <div className={styles.formItem}>
          <p className={styles.formItemLabel}>{translation.Login.login}</p>
          <Input name={'username'} value={username} onChange={(e) => setUsername(e)} />
        </div>
        <div className={styles.formItem}>
          <p className={styles.formItemLabel}>
            {autogenerated ? translation.Login.auto_generated_password : translation.Login.password}
          </p>
          <Input value={password} name={'password'} type={'password'} onChange={(e) => setPassword(e)} />
          {autogenerated ? (
            <p style={{ marginTop: 8 }}>
              The code will be your password, you can change it at any time in your profile
            </p>
          ) : (
            <Link to={'/forgot_password'} className={styles.forgotPassword}>
              {translation.Login.forgot_password}
            </Link>
          )}
        </div>
        <Button className={styles.loginBtn} htmlType={'submit'} disabled={loginResponse.isLoading}>
          {translation.Login.btn_login}
        </Button>
        {error && <p className={styles.error}>{error}</p>}
      </form>
    </div>
  )
}

export default Login
