import React, { useEffect, useState } from 'react'
import { number2Abbr, roundedIntermediateValues } from 'utils'
import styles from './SliderParams.module.scss'
import Slider from 'rc-slider'

type PropsType = {
  onChange: (value: any) => void
  data: SliderType
}

const SliderParams: React.FC<PropsType> = ({ data, ...props }) => {
  const [value, setValue] = useState<number | undefined>()

  const r = ('' + (data.max - data.min)).length
  const step = Math.pow(10, r - 2)
  const marks: any = {
    [data.min]: number2Abbr(data.min),
    [data.max]: number2Abbr(data.max),
  }
  roundedIntermediateValues(data.min, data.max, 8).forEach((m) => (marks[m] = number2Abbr(m)))

  useEffect(() => {
    setValue(data.min)
  }, [])

  useEffect(() => {
    props.onChange(value)
  }, [value])

  return (
    <div className={styles.sliderContainer}>
      <p className={styles.title}>
        {data.name}: {value !== undefined && value !== null ? number2Abbr(value) : '-'}
      </p>
      <Slider
        min={data.min}
        max={data.max}
        marks={marks}
        step={step}
        onChange={(v) => setValue(v as number)}
        value={value}
        style={{ maxWidth: 300, marginLeft: 10 }}
      />
    </div>
  )
}

export default SliderParams
