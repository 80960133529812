import React from 'react'
import styles from './LoadingComponent.module.scss'
import ReactLoading from 'react-loading'

type PropsType = any
const LoadingComponent: React.FC<PropsType> = (props) => {
  return <ReactLoading type="spin" color="var(--color_primary)" {...props} />
}

export default LoadingComponent
