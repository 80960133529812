import api from 'api'
import Button from 'ui/Button/Button'
import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import { useSelector } from 'store/store'

const SendChangeRequestBtn = () => {
  const navigate = useNavigate()
  const availableCash = useSelector((store) => store.portfolioConstructor.availableCash!)
  const clientId = useSelector((store) => store.client.id)
  const curPortfolio = useSelector((store) => store.portfolioConstructor.curPortfolio!)
  const deposit = useSelector((store) => store.portfolioConstructor.deposit) ?? 0
  const assets = useSelector((store) => store.portfolioConstructor.assets)

  const [sendAssets, resultAssets] = api.usePostDemandMutation()

  const onSendDemand = () => {
    if (availableCash + (deposit ?? 0) < 0)
      return toast.error('Available cash is negative. Top up deposit', { position: 'top-left' })
    const body: PostDemandBody = {
      type: 'change',
      client: clientId!,
      portfolio: curPortfolio.id,
      deposit: deposit ? { value: deposit } : { value: 0 },
      available_cash: availableCash + (deposit ?? 0),
      available_cash_old: curPortfolio.available_cash,
      //@ts-ignore
      assets: assets
        ?.map((e) => {
          if (curPortfolio?.assets?.filter((f) => f?.seller_product.id === e?.seller_product.id)?.length! > 0) {
            if (curPortfolio?.assets.filter((f) => f.quantity === e.quantity)?.length! > 0) {
              return { id: e.seller_product.id, quantity: e.quantity, is_changed: false }
            } else {
              return { id: e.seller_product.id, quantity: e.quantity, is_changed: true }
            }
          } else {
            return { id: e.seller_product.id, quantity: e.quantity, is_changed: true }
          }
        })
        .concat(
          //@ts-ignore
          curPortfolio?.assets
            //@ts-ignore
            .map((e) => {
              //@ts-ignore
              if (assets.filter((f) => f.seller_product.id === e.seller_product.id).length === 0) {
                return { id: e.seller_product.id, quantity: 0, is_changed: true }
              }
            })
            .filter((f) => f),
        ),
    }
    sendAssets(body)
  }

  useEffect(() => {
    if (resultAssets.isSuccess) navigate('/investing?portfolio=' + curPortfolio.id)
  }, [resultAssets])

  return (
    <Button
      style={{ width: 210, marginLeft: 'auto', marginTop: 20 }}
      isLoading={resultAssets.isLoading}
      onClick={onSendDemand}>
      Submit a change request
    </Button>
  )
}

export default SendChangeRequestBtn
