/* eslint-disable prettier/prettier */
import React, { Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Layout from './components/Layout/Layout'
import Products from './pages/Products/Products'
import Product from 'pages/Product/Product'
import Login from 'pages/Login/Login'
import { useSelector } from './store/store'
import Subscriptions from 'pages/Subscriptions/Subscriptions'
import CampaignProducts from 'pages/CampaignProducts/CampaignProducts'
import Loading from 'pages/Loading/Loading'
import InvestingContainer from 'pages/Investing/InvestingContainer'
import PortfolioConstructor from 'pages/Investing/PortfolioConstructor/PortfolioConstructor'
import VideoChat from './components/VideoChat/VideoChat'
import SharedPortfolio from 'pages/SharedPortfolio/SharedPortfolio'
import Vlogs from 'pages/Vlogs/Vlogs'
import SelectionSellerFirm from 'pages/SelectionSellerFirm/SelectionSellerFirm'
import Home from 'pages/Home/Home'
import About from 'pages/About/About'

const Profile = React.lazy(() => import('pages/Profile/Profile'))
const UnsubscribeEmails = React.lazy(() => import('pages/UnsubscribeEmails/UnsubscribeEmails'))
const FromEmail = React.lazy(() => import('./pages/Login/FromEmail'))
const ConfirmEmail = React.lazy(() => import('./pages/Login/ConfirmEmail'))
const Page404 = React.lazy(() => import('./pages/Page404/Page404'))
const ForgotPassword = React.lazy(() => import('./pages/Login/ForgotPassword'))

const AuthRouter = () => (
  <Routes>
    <Route path={'/login'} element={<Login />} />
    <Route path={'/forgot_password'} element={<ForgotPassword />} />
    <Route path={'/from_email'} element={<FromEmail />} />
    <Route path={'/unsubscribe_emails'} element={<UnsubscribeEmails />} />
    <Route path={'/shared_portfolio'} element={<SharedPortfolio />} />
    <Route path={'*'} element={<Navigate to="/login" />} />
  </Routes>
)

const ConfirmRouter = () => (
  <Routes>
    <Route path={'/confirm'} element={<ConfirmEmail />} />
    <Route path={'*'} element={<Navigate to="/confirm" />} />
  </Routes>
)

const SelectFirmRouter = (props: any) => {
  return (
    <Routes>
      <Route path={'/selection_seller_firm'} element={<SelectionSellerFirm />} />
      <Route path={'*'} element={<Navigate to="/selection_seller_firm" />} />
    </Routes>
  )
}

const AppRouter = () => {
  const clientId = useSelector((state) => state.client.id)
  const isCalling = useSelector((state) => state.videochat.isCalling)
  const adviser = useSelector((state) => state.videochat.adviser)

  return (
    <>
      {isCalling !== undefined && adviser !== undefined && <VideoChat adviser={adviser} />}
      <Routes>
        {/* <Route path="/" element={<Navigate to={'/products'} />} /> */}
        <Route path={'/'} element={<Home />} />
        <Route path={'/from_email'} element={<FromEmail />} />
        <Route path={'/unsubscribe_emails'} element={<UnsubscribeEmails />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/:id" element={<Products />} />
        <Route path="/portfolio_constructor" element={<PortfolioConstructor />} />{' '}
        {clientId && __STAGE2__ && (
          <>
            <Route path="/investing" element={<InvestingContainer />} />
          </>
        )}
        <Route path="/login" element={<Navigate to={'/'} />} />
        <Route path="/forgot_password" element={<Navigate to={'/'} />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/campaign_product/:id" element={<CampaignProducts />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/subscriptions" element={<Subscriptions />} />
        <Route path={'/shared_portfolio'} element={<SharedPortfolio />} />
        <Route path={'/vlogs'} element={<Vlogs />} />
        <Route path={'/selection_seller_firm'} element={<SelectionSellerFirm />} />
        <Route path={'/about'} element={<About />} />
        <Route path={'*'} element={<Page404 />} />
      </Routes>
    </>
  )
}

const Router = () => {
  const isAuth = useSelector((state) => state.profile.isAuth)
  const emailConfirmed = useSelector((state) => state.profile.email_confirmed)
  const sellerFirmId = useSelector((state) => state.sellerFirm.id)

  return (
    <Layout>
      <Suspense fallback={<Loading />}>
        {isAuth ? (
          sellerFirmId ? (
            emailConfirmed ? (
              <AppRouter />
            ) : (
              <ConfirmRouter />
            )
          ) : (
            <SelectFirmRouter />
          )
        ) : (
          <AuthRouter />
        )}
      </Suspense>
    </Layout>
  )
}

export default Router
