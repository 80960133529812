import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Videochats } from 'videochats'
import { store } from './store'

type videochatState = {
  isCalling: boolean
  adviser?: Omit<User, 'token'>
  error?: string
}

const initialState: videochatState = {
  isCalling: false,
}

const slice = createSlice({
  name: 'videochat',
  initialState,
  reducers: {
    call: (state, action: PayloadAction<{ myClientId: number; adviser: Omit<User, 'token'> }>) => {
      if (!state.isCalling) {
        state.isCalling = true
        state.adviser = action.payload.adviser
        if (Videochats.localTracks === null) {
          Videochats.initLocalTracks()
            .then(() => {
              Videochats.connect(
                action.payload.myClientId.toString(),
                action.payload.myClientId.toString(),
                'y3.deepcodec.com',
              )
            })
            .catch(() => {
              store.dispatch(videochatActions.setError('No access to camera or microphone'))
            })
        }
      }
    },
    endCall: (state) => {
      state.isCalling = false
      state.adviser = undefined
      Videochats.disconnect()
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload
    },
  },
})

export const { actions: videochatActions, reducer: videochatReducer } = slice
