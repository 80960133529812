import classNames from 'classnames'
import SVG from 'components/SVG'
import { useEffect, useState } from 'react'
import { DefaultUiTypes } from 'ui'
import styles from './GenderPicker.module.scss'
enum Genders {
  Male = 'Male',
  Female = 'Female',
  Other = 'Other',
}
type GendersString = keyof typeof Genders
type PropsType = DefaultUiTypes & {
  defaultValue?: GendersString
  value?: GendersString
  onChange?: (value: GendersString | undefined) => void
}
const GenderPicker: React.FC<PropsType> = ({ defaultValue, value, onChange, className, style }) => {
  const [v, sv] = useState<GendersString | undefined>(value ?? defaultValue)

  useEffect(() => {
    onChange?.(v)
  }, [v])
  return (
    <div style={style} className={classNames([styles.container, className])}>
      {Object.entries(Genders).map(([key, value]) => (
        <div
          key={key}
          className={classNames([styles.gender, { [styles.active]: value === v }])}
          //@ts-ignore
          onClick={() => sv(value)}>
          <span>
            <SVG.CheckMark />
          </span>
          <p>{value}</p>
        </div>
      ))}
    </div>
  )
}

export default GenderPicker
