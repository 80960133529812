import Checkbox from 'components/Checkbox/Checkbox'
import ThreeSelect from 'components/ThreeSelect/ThreeSelect'
import { max } from 'moment'
import Slider from 'rc-slider'
import { useEffect, useState } from 'react'
import { Input, MultiSelect } from 'ui'
import { number2Abbr } from 'utils'
import styles from './ProductsFilter.module.scss'

export type FilterValuesType = {
  categories?: number[]
  issuerFirms?: number[]
  min_price?: number
  max_price?: number
  subscribed?: 1 | 0
  liked?: 1 | 0
}
const DEFAULT_VALUES: FilterValuesType = {
  categories: undefined,
  issuerFirms: undefined,
  min_price: undefined,
  max_price: undefined,
  subscribed: 0,
  liked: 0,
}
type PropsType = {
  initialValues?: FilterValuesType
  categories?: Category[]
  products?: Product[]
  onChange: (value: FilterValuesType) => void
}
const getMaxMin = (value: number, max: number, min: number) => (value > max ? max : value < min ? min : value)

const ProductsFilter: React.FC<PropsType> = ({ categories, products, initialValues, onChange }) => {
  const [values, setValues] = useState<FilterValuesType>(initialValues ?? DEFAULT_VALUES)
  const [sliderValues, setSliderValues] = useState<{ min?: number; max?: number }>({
    min: undefined,
    max: undefined,
  })
  useEffect(() => {
    setValues(initialValues ?? DEFAULT_VALUES)
  }, [initialValues])

  useEffect(() => {
    // if (values.max_price !== sliderValues.max) setValues((e) => ({ ...e, max_price: sliderValues.max }))
    // if (values.max_price !== sliderValues.min) setValues((e) => ({ ...e, min_price: sliderValues.min }))
    onChange(values)
  }, [values])

  const issuerFirms = [
    //@ts-ignore
    ...new Map(products?.map(({ issuer_firm }) => [issuer_firm['id'], issuer_firm])).values(),
  ] as Firm[]
  // const prices = products?.map((e) => e.price) ?? []
  // const [maxPrice, minPrice] = [Math.max(...prices), Math.min(...prices)]
  const [minPrice, maxPrice] = [0, 1000000]
  const step = Math.pow(10, ('' + (maxPrice - minPrice)).length - 2)
  const marks: any = {
    [minPrice]: number2Abbr(minPrice),
    [maxPrice]: number2Abbr(maxPrice),
  }
  const changeValues = (type: keyof FilterValuesType, value: any) => {
    setValues((v) => ({ ...v, [type]: value }))
  }
  return (
    <div className={styles.container}>
      <h3>Products Filter</h3>
      <div className={styles.block}>
        <p className={styles.subTitle}>Categories</p>
        <MultiSelect
          placeholder="Select categories"
          options={categories?.map((c) => ({ value: c.id, label: c.name })) ?? []}
          onChange={(selected) => changeValues('categories', selected)}
          value={values.categories}
        />
      </div>
      <div className={styles.block}>
        <p className={styles.subTitle}>Issuer Firms</p>
        <MultiSelect
          placeholder="Select Issuer Firms"
          options={issuerFirms?.map((c) => ({ value: c.id, label: c.name })) ?? []}
          onChange={(selected) => changeValues('issuerFirms', selected)}
          value={values.issuerFirms}
        />
      </div>
      <div className={styles.block}>
        <div className={styles.labelInput} style={{ marginBottom: 10 }}>
          <p className={styles.subTitle}>Min Price</p>
          <Input
            type="number"
            prefix="$"
            max={maxPrice}
            min={minPrice}
            value={values.min_price ?? minPrice}
            onChange={(e) => changeValues('min_price', getMaxMin(parseInt(e), maxPrice, minPrice))}
          />
        </div>
        <Slider
          style={{ marginBottom: 10 }}
          step={step}
          marks={marks}
          max={maxPrice}
          min={minPrice}
          value={sliderValues.min ?? minPrice}
          onAfterChange={(e) => changeValues('min_price', e)}
          onChange={(e: any) => setSliderValues((s) => ({ ...s, min: e }))}
        />
      </div>
      <div className={styles.block}>
        <div className={styles.labelInput} style={{ marginBottom: 10 }}>
          <p className={styles.subTitle}>Max Price</p>
          <Input
            type="number"
            prefix="$"
            max={maxPrice}
            min={minPrice}
            value={values.max_price ?? maxPrice}
            onChange={(e) => changeValues('max_price', getMaxMin(parseInt(e), maxPrice, minPrice))}
          />
        </div>
        <Slider
          style={{ marginBottom: 10 }}
          step={step}
          marks={marks}
          max={maxPrice}
          min={minPrice}
          value={sliderValues.max ?? maxPrice}
          onAfterChange={(e) => changeValues('max_price', e)}
          onChange={(e: any) => setSliderValues((s) => ({ ...s, max: e }))}
        />
      </div>
      <div className={styles.block}>
        <div
          className={styles.labelInput}
          onClick={() => {
            changeValues('subscribed', values.subscribed === 0 ? 1 : 0)
          }}
          style={{ cursor: 'pointer' }}>
          <Checkbox
            checked={!!values.subscribed}
            onClick={() => {
              changeValues('subscribed', values.subscribed === 0 ? 1 : 0)
            }}
          />
          <p>Subscribed</p>
        </div>
      </div>
      <div className={styles.block}>
        <div
          className={styles.labelInput}
          onClick={() => {
            changeValues('liked', values.liked === 0 ? 1 : 0)
          }}
          style={{ cursor: 'pointer' }}>
          <Checkbox
            checked={!!values.liked}
            onClick={() => {
              changeValues('liked', values.liked === 0 ? 1 : 0)
            }}
          />
          <p>Liked</p>
        </div>
      </div>
    </div>
  )
}

export default ProductsFilter
