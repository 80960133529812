import React, { useEffect, useRef } from 'react'
import styles from './VideoChat.module.scss'
import SVG from '../SVG'
import JitsiTrack from 'videochats/src/types/Jitsi/modules/RTC/JitsiTrack'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'store/store'
import { Videochats, VideochatsEvent } from 'videochats'
import { videochatActions } from '../../store/videochatReducer'
import defaultAvatar from 'assets/images/avatar.png'
import LazyLoading from '../LazyLoading/LazyLoading'

type Props = {
  adviser: Omit<User, 'token'>
}

const VideoChat = ({ adviser }: Props) => {
  const dispatch = useDispatch()
  const video = useRef<HTMLVideoElement>(null)
  const localVideo = useRef<HTMLVideoElement>(null)
  const audio = useRef<HTMLAudioElement>(null)
  const user = useSelector((state) => state.profile)
  const clientId = useSelector((state) => state.client.id)
  const error = useSelector((state) => state.videochat.error)

  const onTrack = (track: JitsiTrack) => {
    const type = track.getType()
    console.log(`${adviser.id} on ${track.getType()} track`, track)
    track.attach(type === 'video' ? video.current! : audio.current!)
  }

  const onLocalTrack = (track: JitsiTrack) => {
    const type = track.getType()
    if (type === 'video') track.attach(localVideo.current!)
  }

  const onEndCall = () => {
    dispatch(videochatActions.endCall())
  }

  const onMD = () => {
    document.addEventListener('mousemove', onMM)
    document.addEventListener('mouseup', onMU)
    document.addEventListener('mouseout', onMU)
  }

  const onMM = (e: MouseEvent) => {
    const cont = document.getElementById('videochat_container')
    if (cont !== null) {
      const b = parseInt(cont.style.bottom)
      const r = parseInt(cont.style.right)
      cont.style.bottom = b - e.movementY + 'px'
      cont.style.right = r - e.movementX + 'px'
    }
  }

  const onMU = () => {
    document.removeEventListener('mousemove', onMM)
    document.removeEventListener('mouseup', onMU)
    document.removeEventListener('mouseout', onMU)
  }

  useEffect(() => {
    const unsubLocalTrack = Videochats.subscribe(clientId!.toString(), VideochatsEvent.LOCAL_TRACK, onLocalTrack)

    const tracks = Videochats.getRemoteTracks(adviser.id.toString())
    if (tracks) tracks.forEach(onTrack)
    const unsubTrack = Videochats.subscribe(adviser.id.toString(), VideochatsEvent.REMOTE_TRACK, onTrack)

    return () => {
      unsubTrack()
      unsubLocalTrack()
    }
  }, [])

  return (
    <div id={'videochat_container'} style={{ bottom: 16, right: 16 }} className={styles.container} onMouseDown={onMD}>
      {error !== undefined ? (
        <div className={styles.error}>
          <p style={{ color: 'var(--color_danger)' }}>{error}</p>
        </div>
      ) : (
        <>
          <LazyLoading src={adviser.avatar_url} noDataImg={defaultAvatar} className={styles.adviserAvatar} />
          <video
            ref={video}
            className={styles.video}
            //@ts-ignore
            pip={'false'}
            autoPlay
            playsInline
            controls={false}
            poster={''}
          />
          <audio autoPlay ref={audio} />
          <div className={styles.footer}>
            <video
              ref={localVideo}
              className={styles.localVideo}
              //@ts-ignore
              pip={'false'}
              autoPlay
              playsInline
              controls={false}
              poster={''}
            />
            <div className={styles.controls}>
              <button onClick={onEndCall} className={classNames([styles.btn, styles.endCallBtn])}>
                <SVG.Phone />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default VideoChat
