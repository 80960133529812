import api from 'api'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './Product.module.scss'
import { number2currency } from 'utils'
import Loading from '../Loading/Loading'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import Page404 from '../Page404/Page404'
import ProductChart from 'components/Charts/ProductChart'
import { useSelector } from '../../store/store'
import noImage from 'assets/images/image_not_available.png'
import LazyLoading from '../../components/LazyLoading/LazyLoading'
import translation from 'assets/locales/en/translation.json'
import VideoPlayer from 'components/VideoPlayer/VideoPlayer'
import Gallery from '../../components/Gallery/Gallery'
import Modal from '../../components/Modal/Modal'
import Button from '../../ui/Button/Button'
import useWatchIssuerProduct from 'hooks/useWatchIssuerProduct'
import Comments from 'components/Comments/Comments'
import Like from 'components/Like/Like'
import SubscribeParams from 'pages/SubscribeParams/SubscribeParams'
import Landing from 'components/Landing/Landing'
dayjs.extend(LocalizedFormat)

type Props = {
  product: Product
  sellerProducts: ProductSeller[]
  subscribe: ShortSubscribe
  liked: boolean
  refetchSubscribes: () => void
}

const Product = ({ product, sellerProducts, subscribe, liked, refetchSubscribes }: Props) => {
  const [showThanksModal, setShowThanksModal] = useState(false)

  const { data: landingData } = api.useGetLandingQuery({ issuer_product: product.id })
  const [like] = api.usePostLikeMutation()
  const [deleteLike] = api.useDeleteLikeMutation()

  const landing = landingData?.landing

  useWatchIssuerProduct(product.id, product.categories)

  const galleryItems: React.ReactNode[] = product.images
    .map((i) => <LazyLoading key={i.id} src={i.url} style={{ background: '#' + i.background_color }} />)
    .concat(
      product.videos.map((v) => (
        <VideoPlayer
          key={v.id}
          url={v.url}
          videoId={v.id}
          style={{ background: '#' + v.background_color }}
          poster={v.url_preview}
          controls
        />
      )),
    )

  const onClickLike = (e: React.MouseEvent) => {
    e.stopPropagation()
    const id = product.issuer_firm_product_id ?? product.id
    if (liked) deleteLike(id)
    else like(id)
  }

  return (
    <div className={styles.page}>
      {showThanksModal && (
        <Modal>
          <div className={styles.thanksModal}>
            <h3 style={{ marginBottom: 16 }}>Thanks for subscribe</h3>
            <p style={{ marginBottom: 24 }}>Our adviser will call you soon</p>
            <Button style={{ width: 100 }} onClick={() => setShowThanksModal(false)}>
              Ok
            </Button>
          </div>
        </Modal>
      )}
      <div className={styles.galleryWithInfo}>
        <div className={styles.gallery}>
          {galleryItems.length > 0 ? (
            <Gallery bullets={true} controls className={styles.gallery} showFullscreenBtn>
              {galleryItems}
            </Gallery>
          ) : (
            <img
              src={noImage}
              alt={translation.Product.img_alt}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          )}
        </div>
        <div className={styles.subscribeInfoBlock}>
          <div className={styles.nameContainer}>
            <h1 className={styles.name}>{product.name}</h1>
          </div>
          <div className={styles.issuerFirmContainer}>
            {product.issuer_firm?.avatar_url && (
              <div className={styles.issuerAvatarContainer}>
                <LazyLoading src={product.issuer_firm?.avatar_url} />
              </div>
            )}
            <p>{product.issuer_firm.name}</p>
          </div>
          {product.price > 0 && (
            <div className={styles.priceContainer}>
              <p className={styles.priceName}>{product.price_name}</p>
              <p className={styles.price}>{number2currency(product.price)}</p>
            </div>
          )}

          {product.expire_date && (
            <div className={styles.expireDateContainer}>
              <p className={styles.expireDateName}>Expire date</p>
              <p className={styles.expireDate}>{dayjs(product.expire_date * 1000).format('LL')}</p>
            </div>
          )}

          <div className={styles.descriptionContainer}>
            <p className={styles.descriptionName}>Description</p>
            <p className={styles.description}>{product.description}</p>
          </div>

          <div className={styles.sellerProductBlock}>
            {/* <div className={styles.sellerInfo}>
                  <img src={pdct.seller_firm.avatar_url ?? undefined} alt={''} className={styles.sellerAvatar} />
                  <p>{pdct.seller_firm.name}</p>
                </div> */}
            <div style={{ display: 'flex' }}>
              <Like liked={liked} onClick={onClickLike} />
              {/* <Share text={'product/' + product.id} /> */}
            </div>
            <SubscribeParams product={product} subscribe={subscribe} refetchSubscribes={refetchSubscribes} />
          </div>
        </div>
      </div>
      {product.attachments.length > 0 ||
        (product.charts.length > 0 && (
          <div className={styles.bigInfo} style={{ marginBottom: 20 }}>
            {product.attachments.length > 0 && (
              <div className={styles.bigInfoBlock}>
                <h3 style={{ marginBottom: 12 }}>{translation.Product.attachments}</h3>
                {product.attachments.map((a, i) => (
                  <a
                    key={i}
                    target={'_blank'}
                    href={a.url}
                    rel="noreferrer"
                    style={{ display: 'block', marginBottom: 10 }}>
                    {a.name}
                  </a>
                ))}
              </div>
            )}
            {product.charts.length > 0 && (
              <div className={styles.bigInfoBlock}>
                <h3 style={{ marginBottom: 12 }}>{translation.Product.charts}</h3>
                {product.charts.map((chart, i) => (
                  <ProductChart data={chart} key={i} />
                ))}
              </div>
            )}
          </div>
        ))}
      {landing && (
        <div style={{ marginBottom: 16 }} className={styles.bigInfo}>
          <div className={styles.bigInfoBlock}>
            <Landing data={landing} />
          </div>
        </div>
      )}

      {__STAGE2__ && (
        <div className={styles.bigInfo}>
          <div className={styles.bigInfoBlock}>
            <h3 style={{ marginBottom: 16 }}>{translation.Product.comments}:</h3>
            <Comments />
          </div>
        </div>
      )}
    </div>
  )
}

const ProductContainer = () => {
  const { id: productId } = useParams()
  const id = Number(productId)

  if (isNaN(id)) return <Page404 />
  const mySellerFirmId = useSelector((store) => store.sellerFirm.id)
  const { data: sellerProduct } = api.useGetSellerProductByIssuerProductQuery(
    { product: id!, seller_firm: mySellerFirmId! },
    { skip: !id || !mySellerFirmId },
  )
  const userId = useSelector((state) => state.profile.id!)
  const {
    data: subscribe,
    isLoading: subscribesLoading,
    refetch,
  } = api.useGetSubscribeQuery(
    {
      user: userId,
      product: sellerProduct?.id!,
    },
    { skip: !sellerProduct },
  )

  const { data: sellerProducts, isLoading: sellerProductsLoading } = api.useGetProductSellersQuery(id)
  const { data: product, isLoading: productLoading, isError } = api.useGetProductQuery(id)
  const { data: likes, isLoading: likesLoading } = api.useGetLikesQuery({ products: [id] })

  if (isError) return <Page404 />
  else if (productLoading || subscribesLoading || sellerProductsLoading || likesLoading) return <Loading />
  else {
    return (
      <Product
        refetchSubscribes={refetch}
        product={product!}
        sellerProducts={sellerProducts!}
        subscribe={subscribe!}
        liked={!!likes?.find((l) => l === id)}
      />
    )
  }
}

export default ProductContainer
