import classNames from 'classnames'
import Button from 'ui/Button/Button'
import ThreeSelect, { Option } from 'components/ThreeSelect/ThreeSelect'
import SVG from 'components/SVG'
import React, { useState } from 'react'
import { addNewAssets, portfolioConstructorActions } from 'store/portfolioConstructorReducer'
import { useDispatch, useSelector } from 'store/store'
import { getAssetColor } from 'utils'
import styles from './AddProduct.module.scss'

const AddProduct = () => {
  const categories = useSelector((store) => store.portfolioConstructor.categories)
  const assets = useSelector((store) => store.portfolioConstructor.assets)
  const assetsProductsId = assets?.map((e) => e.seller_product.id)
  const statusConnected = useSelector((store) => store.portfolioConstructor.statusConnected)

  const dispatch = useDispatch()

  const [selectedProducts, setSelectedProducts] = useState<number[]>([])
  const addProduct = () => {
    // const newAssets = [...assets!]
    const currentProducts = categories
      ?.map((e) => e.products)
      .flat(1)
      .filter((e) => selectedProducts.includes(e.id))
      .filter((e) => !assetsProductsId?.includes(e.id))

    const newAssets = currentProducts?.map((p) => ({
      quantity: 0,
      is_created: statusConnected === 'connected' ? true : undefined,
      seller_product: {
        id: p.id,
        max_investment: p.max_investment,
        min_investment: p.min_investment,
        name: p.name,
        step: p.step,
        price_name: p.price_name,
        price: p.price,
      },
    }))
    if (newAssets) {
      addNewAssets([...newAssets])
      setSelectedProducts([])
    }
  }

  const data = categories
    ?.map((c) => ({
      id: c.id,
      label: c.name,
      children: c.products.filter((e) => !assetsProductsId?.includes(e.id)).map((e) => ({ id: e.id, label: e.name })),
    }))
    .filter((e) => e.children.length) as Option[]

  return (
    <div className={styles.container}>
      <ThreeSelect
        btnClass={styles.threeSelect}
        placeholder="Select products to add"
        options={data}
        selected={selectedProducts}
        onChange={setSelectedProducts}
      />
      <Button className={styles.addBtn} onClick={addProduct}>
        Save
      </Button>
    </div>
    // <Modal
    //   title={'Add new product'}
    //   okTitle={'Add'}
    //   onOk={addProduct}
    //   onClose={onClose}
    //   okBtnDisabled={selectedProducts.length === 0}
    //   classNameContainer={classNames({
    //     [styles.closeModal]: !isVisibleModal,
    //     [styles.openModal]: isVisibleModal,
    //   })}>
    //   {/* <div className={styles.categoriesContainer}>
    //       {categories?.map((c, i) => (
    //         <CategoryOption
    //           assets={assets}
    //           key={c.id}
    //           category={c}
    //           setSelectedProducts={setSelectedProducts}
    //           selectedProducts={selectedProducts}
    //         />
    //       ))}
    //     </div> */}
    // </Modal>
  )
}

type CategoryOptionType = {
  setSelectedProducts: React.Dispatch<React.SetStateAction<InvestingProduct[]>>
  selectedProducts: InvestingProduct[]
  category: InvestingCategory
  assets: AssetPortfolio[] | undefined
}

const CategoryOption: React.FC<CategoryOptionType> = ({ category, setSelectedProducts, selectedProducts, assets }) => {
  const [isOpenProducts, setIsOpenProducts] = useState(false)
  // console.log('assets', assets)
  // console.log('selectedProducts', selectedProducts)
  const filteredProducts = category.products
    .map((p) => {
      if (assets?.find((f) => f.seller_product.id === p.id)) {
        return undefined
      } else {
        return { ...p }
      }
    })
    .filter((f) => f)
  // console.log('filteredProducts', filteredProducts)
  return (
    <div className={styles.categoryContainer}>
      <div
        className={styles.categoryContent}
        onClick={() => {
          setIsOpenProducts((state) => !state)
        }}>
        <h2>{category.name}</h2>
        <div className={classNames(styles.arrowContainer, { [styles.openProducts]: isOpenProducts })}>
          <SVG.Arrow style={{ fill: 'var(--color_shade_background)' }} />
        </div>
      </div>

      {isOpenProducts && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {filteredProducts.length > 0 ? (
            filteredProducts.map((p) => {
              return (
                <ProductOption
                  key={p?.id}
                  product={p!}
                  setSelectedProducts={setSelectedProducts}
                  selectedProducts={selectedProducts}
                />
              )
            })
          ) : (
            <h2 className={styles.noProducts}>There are no products in this category</h2>
          )}
        </div>
      )}
    </div>
  )
}

type ProductOptionType = {
  setSelectedProducts: React.Dispatch<React.SetStateAction<InvestingProduct[]>>
  selectedProducts: InvestingProduct[]
  product: InvestingProduct
}

const ProductOption: React.FC<ProductOptionType> = ({ product, setSelectedProducts, selectedProducts }) => {
  const isSelected = selectedProducts.find((f) => f.id === product.id)
  return (
    <div
      className={styles.productContainer}
      onClick={() => {
        if (isSelected) {
          setSelectedProducts((state) => [...state.filter((f) => f.id !== product.id)])
        } else {
          setSelectedProducts((state) => [...state, product])
        }
      }}>
      <div style={{ width: 10, height: '100%', background: getAssetColor(product.id) }} />

      <div className={classNames(styles.productNameContainer, { [styles.selectedProduct]: isSelected })}>
        <h4 style={{ marginLeft: 20 }}>{product.name}</h4>
      </div>

      {/* <div className={styles.categoryContent}>
        
      </div> */}
    </div>
  )
}

export default AddProduct
