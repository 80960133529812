import React, { useEffect } from 'react'
import { IS_TOUCH } from '../utils'

export const useDragScroll = (ref: React.RefObject<HTMLDivElement>) => {
  useEffect(() => {
    const container = ref.current
    if (IS_TOUCH || container === null) return
    let isDown = false
    let isMoved = false
    let startX: number
    let scrollLeft: number

    container.classList.add('draggable')

    container.addEventListener('mousedown', (e) => {
      isDown = true
      isMoved = false
      container.style.scrollBehavior = 'unset'
      container.classList.add('drag')
      startX = e.pageX - container.offsetLeft
      scrollLeft = container.scrollLeft
    })
    container.addEventListener('mousemove', (e) => {
      if (!isDown) return
      isMoved = true
      e.preventDefault()
      const x = e.pageX - container.offsetLeft
      const walk = x - startX //scroll-fast
      container.scrollLeft = scrollLeft - walk
    })

    const onLeaveOrUp = () => {
      isDown = false
      container.style.scrollBehavior = 'smooth'
      container.classList.remove('drag')
    }
    container.addEventListener('mouseleave', onLeaveOrUp)
    container.addEventListener('mouseup', onLeaveOrUp)
    container.addEventListener(
      'click',
      (e) => {
        if (isMoved) {
          e.preventDefault()
          e.stopPropagation()
        }
      },
      { capture: true },
    )
  }, [])
}
