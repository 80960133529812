import React from 'react'
import styles from './Portfolio.module.scss'
import AssetsBlock from './Components/Assets/AssetsBlock'
import GoalsBlock from './Components/GoalsBlock'
import MainBlock from './Components/MainBlock'
import { useSelector } from 'store/store'

const Portfolio: React.FC = () => {
  const portfolio = useSelector((state) => state.portfolioConstructor.curPortfolio!)

  return (
    <div className={styles.page}>
      <MainBlock equity={portfolio?.equity} />
      <GoalsBlock balance={portfolio?.equity} />
      <AssetsBlock assets={portfolio?.assets} equity={portfolio?.equity} />
    </div>
  )
}

export default Portfolio
