import { clientActions } from './store/clientReducer'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { profileActions } from 'store/profileReducer'
import { RootState, store } from './store/store'
import md5 from 'md5'
import { Landing } from 'components/Landing/Landing'

// const API_URL = __DEV__ ? 'https://sancback.splinex.com/api' : 'https://sancback.splinex.com/api'
export const BASE_URL = 'https://sancback.splinex.com'
const API_URL = `${BASE_URL}/api`

const PASSWORD_SALT = '4048b685204f7638ec07e56d0f7115e8'

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).profile.token
    // if (token) headers.set('Authorization', 'b32a51ac9c1a1706cc21f0f01e881f73')
    if (token) headers.set('authorization', token)
    return headers
  },
})

const api = createApi({
  reducerPath: 'api',
  baseQuery,
  tagTypes: [
    'Products',
    'Product',
    'Likes',
    'SellerFirms',
    'Demand',
    'Portfolio',
    'Goals',
    'Comment',
    'Advisers',
    'Client',
    'Subscribes',
  ],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body: { username: string; password: string }) => {
        return {
          url: 'login',
          method: 'POST',
          body: {
            ...body,
            password: md5(body.password + PASSWORD_SALT),
            email: body.username,
          },
        }
      },
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((r) => {
            if (r.data.error === undefined) {
              dispatch(profileActions.setUser(r.data))
            }
          })
          .catch((e) => {
            console.log('LOGIN ERROR', e)
          })
      },
    }),
    getUserInfo: builder.query<Omit<User, 'token'>, void>({
      query: () => '/user',
      async onQueryStarted(arg: void, { queryFulfilled, dispatch }) {
        queryFulfilled.then((r) => {
          dispatch(profileActions.updateUserInfo(r.data))
          dispatch(api.endpoints.getShortSubscribes.initiate({ user: r.data.id }))
        })
      },
    }),
    patchUserInfo: builder.mutation<Omit<User, 'token'>, UserPatchBody>({
      query: (body) => {
        return {
          url: '/user',
          method: 'PATCH',
          body,
        }
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        queryFulfilled.then((r) => {
          dispatch(profileActions.updateUserInfo(r.data))
        })
      },
    }),
    setUserAvatar: builder.mutation<{ url: string }, FormData>({
      query: (body) => {
        return {
          url: '/user/avatar',
          method: 'POST',
          body,
        }
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        queryFulfilled.then((r) => {
          dispatch(profileActions.updateUserInfo({ avatar_url: r.data.url }))
        })
      },
    }),
    getUserById: builder.query<Omit<User, 'token'>, number>({
      query: (id) => 'user_by_id/' + id,
    }),
    changePassword: builder.mutation<User, { password: string }>({
      query: ({ password }) => ({
        url: '/change_password',
        method: 'PATCH',
        body: {
          password: md5(password + PASSWORD_SALT),
        },
      }),
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((r) => {
            dispatch(profileActions.setUser(r.data))
          })
          .catch((e) => {
            console.log('LOGIN ERROR', e)
          })
      },
    }),
    resetPassword: builder.mutation<unknown, { email: string }>({
      query: (body) => ({ url: '/reset_password', method: 'POST', body }),
    }),
    getTokenForCode: builder.mutation<User | { error: string }, { email: string; code: string }>({
      query: (body) => ({ url: '/reset_password_code_confirmation', method: 'POST', body }),
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then((r) => {
            if (!('error' in r.data)) {
              dispatch(profileActions.updateUserInfo({ token: r.data.token }))
            }
          })
          .catch((e) => {
            console.log('LOGIN ERROR', e)
          })
      },
    }),
    getUserAdvisers: builder.query<Omit<User, 'token'>[], { user_id: number; seller_firm: number }>({
      query: ({ user_id, seller_firm }) => {
        return {
          url: '/advisors_by_user/' + user_id,
          params: {
            seller_firm: seller_firm,
          },
        }
      },
      providesTags: [{ id: 'all', type: 'Advisers' }],
    }),
    getImportedClient: builder.query<ImportedClient, { seller_firm: number; user: number }>({
      query: (params) => {
        return {
          url: '/imported_client',
          params,
        }
      },
      providesTags: [{ id: 'all', type: 'Client' }],
      async onQueryStarted(arg: { seller_firm: number; user: number }, { queryFulfilled, dispatch }) {
        queryFulfilled
          .then((r) => {
            // dispatch(clientActions.setClientId(r.data.id))
            dispatch(clientActions.setClient(r.data))
          })
          .catch((err) => {
            dispatch(clientActions.setClient())
          })
      },
    }),

    patchImportedClient: builder.mutation<ImportedClient, ImportedClient>({
      query: (body) => {
        return {
          url: `/imported_client/${body.id}`,
          method: 'PATCH',
          body,
        }
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        queryFulfilled.then((r) => {
          dispatch(clientActions.setClient(r.data))
        })
      },
    }),

    getCategories: builder.query<Category[], void>({
      query: () => '/categories',
    }),

    //Products
    getProducts: builder.query<Product[], ProductRequestParams | void>({
      query: (params) => {
        return {
          url: '/issuer_products',
          method: 'GET',
          params: { with_sellers: 1, ...params },
        }
      },
      providesTags: (result, error, arg) => [
        { id: arg?.toString(), type: 'Products' },
        { id: 'all', type: 'Products' },
      ],
    }),
    getSellerProducts: builder.query<Product[], ProductRequestParams | void>({
      query: (params) => {
        return {
          url: '/seller_products',
          method: 'GET',
          params: { ...params },
        }
      },
    }),
    getProduct: builder.query<Product, number>({
      query: (id) => '/issuer_product/' + id,
      providesTags: (result, error, id) => [
        { id: id.toString(), type: 'Product' },
        { id: 'all', type: 'Product' },
      ],
    }),
    getSellerProductByIssuerProduct: builder.query<Product, { product: number; seller_firm: number }>({
      query: (params) => ({
        url: 'seller_product_by_issuer_product',
        method: 'GET',
        params,
      }),
    }),
    getProductSellers: builder.query<ProductSeller[], number>({
      query: (id) => '/issuer_product/' + id + '/sellers',
    }),

    //Likes
    getLikes: builder.query<number[], LikesRequestParams | void>({
      query: (args) => {
        const params: any = { ...args }
        if (params !== undefined && 'products' in params) params.products = params.products?.join(',')
        return {
          url: '/likes',
          method: 'GET',
          params: { ...params, nocache: true },
        }
      },
      providesTags: [{ id: 'all', type: 'Likes' }],
    }),
    postLike: builder.mutation<{ Success: boolean }, number>({
      query: (product) => {
        return {
          url: '/likes',
          method: 'POST',
          body: {
            product,
          },
        }
      },
      onQueryStarted(productId, { dispatch, queryFulfilled, getState }) {
        queryFulfilled.then((resp) => {
          if (resp.data.Success) {
            // upd likes
            for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(getState(), [
              { type: 'Likes', id: 'all' },
            ])) {
              if (endpointName !== 'getLikes') continue
              dispatch(
                api.util.updateQueryData(endpointName, originalArgs, (likes) => {
                  likes.push(productId)
                }),
              )
            }
            // upd products like count
            for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(getState(), [
              { type: 'Products', id: 'all' },
            ])) {
              if (endpointName !== 'getProducts') continue
              dispatch(
                api.util.updateQueryData(endpointName, originalArgs, (products) => {
                  const product = products.find((p) => (p.issuer_firm_product_id ?? p.id) === productId)
                  if (product !== undefined) Object.assign(product, { like_count: product.like_count + 1 })
                }),
              )
            }
            // upd product like count
            for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(getState(), [
              { type: 'Product', id: 'all' },
            ])) {
              if (endpointName !== 'getProduct') continue
              dispatch(
                api.util.updateQueryData(endpointName, originalArgs, (product) => {
                  if ((product.issuer_firm_product_id ?? product.id) === productId) {
                    Object.assign(product, { like_count: product.like_count + 1 })
                  }
                }),
              )
            }
          }
        })
      },
    }),
    deleteLike: builder.mutation<{ Success: boolean }, number>({
      query: (product) => {
        return {
          url: '/likes',
          method: 'delete',
          params: {
            product,
          },
        }
      },
      onQueryStarted(productId, { dispatch, queryFulfilled, getState }) {
        queryFulfilled.then((resp) => {
          if (resp.data.Success) {
            // upd likes
            for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(getState(), [
              { type: 'Likes', id: 'all' },
            ])) {
              if (endpointName !== 'getLikes') continue
              dispatch(
                api.util.updateQueryData(endpointName, originalArgs, (likes) => {
                  const index = likes.findIndex((l) => l === productId)
                  if (index !== -1) likes.splice(index, 1)
                }),
              )
            }
            // upd products like count
            for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(getState(), [
              { type: 'Products', id: 'all' },
            ])) {
              if (endpointName !== 'getProducts') continue
              dispatch(
                api.util.updateQueryData(endpointName, originalArgs, (products) => {
                  const product = products.find((p) => (p.issuer_firm_product_id ?? p.id) === productId)
                  if (product !== undefined) Object.assign(product, { like_count: product.like_count - 1 })
                }),
              )
            }
            // upd product like count
            for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(getState(), [
              { type: 'Product', id: 'all' },
            ])) {
              if (endpointName !== 'getProduct') continue
              dispatch(
                api.util.updateQueryData(endpointName, originalArgs, (product) => {
                  if ((product.issuer_firm_product_id ?? product.id) === productId) {
                    Object.assign(product, { like_count: product.like_count - 1 })
                  }
                }),
              )
            }
          }
        })
      },
    }),

    //Subscribes
    getShortSubscribes: builder.query<ShortSubscribe[], SubscribeRequestParams>({
      query: (params) => {
        return {
          url: '/subscribes',
          method: 'GET',
          params: { ...params, preview: 1, is_subscribed: 1 },
        }
      },
    }),
    getSubscribes: builder.query<Subscribe[], SubscribeRequestParams>({
      query: (params) => {
        return {
          url: '/subscribes',
          method: 'GET',
          params: {
            ...params,
            is_subscribed: 1,
          },
        }
      },
    }),
    getSubscribe: builder.query<ShortSubscribe, { user: number; product: number }>({
      query: (params) => {
        return {
          url: '/subscribe_by_product',
          method: 'GET',
          params: {
            ...params,
          },
        }
      },
    }),
    setSubscribe: builder.mutation<Subscribe, SubscribePostRequestParams>({
      query: (body) => {
        return {
          url: '/subscribe',
          method: 'POST',
          body,
        }
      },
      onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        queryFulfilled.then((resp) => {
          const subscribe = resp.data
          const user = (getState() as RootState).profile.id!
          const seller_firm = (getState() as RootState).sellerFirm.id!
          dispatch(
            api.util.updateQueryData('getSubscribes', { user }, (subscribes) => {
              subscribes.push(subscribe)
            }),
          )

          dispatch(
            api.util.updateQueryData('getShortSubscribes', { user }, (subscribes) => {
              subscribes.push({ id: subscribe.id, product: subscribe.product.id })
            }),
          )
        })
      },
    }),
    deleteSubscribe: builder.mutation<void, number>({
      query(id) {
        return {
          url: `/subscribe/${id}`,
          method: 'PATCH',
          body: {
            is_subscribed: false,
          },
        }
      },
      onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        queryFulfilled.then(() => {
          const user = (getState() as RootState).profile.id!
          dispatch(
            api.util.updateQueryData('getSubscribes', { user }, (subscribes) => {
              return subscribes.filter((s) => s.id !== id)
            }),
          )
          dispatch(
            api.util.updateQueryData('getShortSubscribes', { user }, (subscribes) => {
              return subscribes.filter((s) => s.id !== id)
            }),
          )
        })
      },
    }),

    unsubscribeEmail: builder.query<any, { client_id: string; ch: string }>({
      query: (params) => {
        return {
          url: `/imported_client/unsubscribe/${params.client_id}`,
          method: 'GET',
          params: {
            h: params.ch,
          },
        }
      },
    }),
    unsubscribeTillDateEmail: builder.query<any, { client_id: string; ch: string; t: number }>({
      query: (params) => {
        return {
          url: `/imported_client/unsubscribe_till_date/${params.client_id}`,
          method: 'GET',
          params: {
            h: params.ch,
            t: params.t,
          },
        }
      },
    }),
    getCategoriesUser: builder.query<(Category & { active: boolean })[], { user_id: string; uh: string }>({
      query: (params) => {
        return {
          url: `/user_categories/${params.user_id}`,
          method: 'GET',
          params: {
            h: params.uh,
          },
        }
      },
    }),
    setCategoriesUser: builder.mutation<any, { user_id: string; uh: string; data: { [key: string]: boolean } }>({
      query: (body) => {
        return {
          url: `/user_categories/${body.user_id}`,
          method: 'POST',
          params: {
            h: body.uh,
          },
          body: body.data,
        }
      },
    }),

    // Seller firms
    getSellerFirms: builder.query<Firm[], number[] | void>({
      query: (args) => {
        const seller_firms = args?.join(',')
        return {
          url: '/seller_firms',
          method: 'GET',
          params: {
            seller_firms,
          },
        }
      },
      providesTags: [{ id: 'all', type: 'SellerFirms' }],
    }),
    getSellerFirm: builder.query<
      Firm & { seller_firm_theme: FirmTheme | null },
      { seller_firm: number; params?: { with_theme?: 0 | 1 } }
    >({
      query: ({ seller_firm, params }) => {
        return {
          url: '/seller_firm/' + seller_firm,
          method: 'GET',
          params,
        }
      },
      onQueryStarted(productId, { dispatch, queryFulfilled, getState }) {
        const userId = (getState() as RootState).profile.id
        queryFulfilled.then((resp) => {
          // if (resp.data.id === (getState() as RootState).sellerFirm.id || resp.data.id === undefined) {
          //   console.log('Ну и хуита!!!')
          // } else {
          dispatch(api.endpoints.getUserAdvisers.initiate({ seller_firm: resp.data.id!, user_id: userId! }))
          dispatch(api.endpoints.getImportedClient.initiate({ seller_firm: resp.data.id!, user: userId! })).then(
            (value) => {
              dispatch(clientActions.setClientId(value.data?.id))
              dispatch(api.endpoints.getPortfolios.initiate({ client: value.data?.id! }, { subscribe: true }))
              // location.reload()
            },
          )
          // }
        })
      },
    }),

    getSellerFirmsByUser: builder.query<Firm[], void>({
      query: () => {
        return {
          url: '/seller_firm_by_user',
          method: 'GET',
        }
      },
    }),

    // Investing category
    getInvestingCategories: builder.query<InvestingCategory[], InvestingCategoryRequestParams>({
      query: (params) => {
        return {
          url: '/category_investing',
          method: 'GET',
          params,
        }
      },
    }),

    // Portfolio
    getPortfolios: builder.query<ShortPortfolio[] | [], { client: number }>({
      query: (params) => {
        return {
          url: '/portfolios',
          method: 'GET',
          params,
        }
      },
      providesTags: [{ id: 'all', type: 'Portfolio' }],
    }),
    getPortfolio: builder.query<Portfolio, { portfolioId: number; seller_firm: number }>({
      query: ({ portfolioId, seller_firm }) => {
        return {
          url: '/portfolio/' + portfolioId,
          method: 'GET',
          params: { seller_firm },
        }
      },
      providesTags: [{ id: 'all', type: 'Portfolio' }],
    }),
    portfolioConfiguration: builder.mutation<PortfolioConfiguration, PortfolioConfigurationRequestType>({
      query: (body) => {
        return {
          url: `/portfolio/configuration`,
          method: 'POST',
          params: { seller_firm: body.seller_firm },
          body,
        }
      },
    }),

    // Investing portfilio constructor
    // getInvestingChart: builder.query<any, void>({
    //   query: (args) => {
    //     return {
    //       url: 'https://wealth.splinex.com/api/goal/51',
    //       method: 'GET',
    //     }
    //   },
    // }),

    // Investing demand
    getDemand: builder.query<DemandResponse[], { client: number; is_archived?: 0 | 1 } & DemandStatusName>({
      query: (params) => {
        return {
          url: '/demand',
          method: 'GET',
          params,
        }
      },
      providesTags: [{ id: 'all', type: 'Demand' }],
    }),
    postDemand: builder.mutation<any, PostDemandBody>({
      query: (body) => {
        return {
          url: '/demand',
          method: 'POST',
          body: body,
        }
      },
      invalidatesTags: [{ id: 'all', type: 'Demand' }],
    }),

    // Goal
    getGoals: builder.query<Goal[], void>({
      query: () => {
        return {
          url: '/goals',
          method: 'GET',
        }
      },
      providesTags: [{ id: 'all', type: 'Goals' }],
    }),
    addGoal: builder.mutation<Goal, Omit<Goal, 'id'>>({
      query: (body) => {
        return {
          url: '/goal',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ id: 'all', type: 'Goals' }],
    }),
    editGoal: builder.mutation<Goal, Goal>({
      query: (body) => {
        return {
          url: '/goal/' + body.id,
          method: 'PATCH',
          body: {
            name: body.name,
            price: body.price,
          },
        }
      },
      invalidatesTags: [{ id: 'all', type: 'Goals' }],
    }),
    deleteGoal: builder.mutation<any, number>({
      query: (id) => {
        return {
          url: '/goal/' + id,
          method: 'DELETE',
        }
      },
      invalidatesTags: [{ id: 'all', type: 'Goals' }],
    }),

    getNewChart: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: '/portfolio/custom_configuration',
          method: 'POST',
          body,
        }
      },
    }),

    // Comment
    getComments: builder.query<CommentType[], void | { product?: number }>({
      query: (params) => {
        if (params)
          return {
            url: '/comment',
            method: 'GET',
            params,
          }
        else
          return {
            url: '/comment',
            method: 'GET',
          }
      },
      providesTags: [{ id: 'all', type: 'Comment' }],
    }),
    postComment: builder.mutation<any, CommentTypeBody>({
      query: (body) => {
        return {
          url: '/comment',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ id: 'all', type: 'Comment' }],
    }),

    deleteComment: builder.mutation<void, number>({
      query: (id) => {
        return {
          url: '/comment/' + id,
          method: 'delete',
        }
      },
      invalidatesTags: [{ id: 'all', type: 'Comment' }],
    }),

    //Shared Portfolio
    getSharedPortfolio: builder.query<Portfolio & { is_snapshot: boolean; expire_date: string }, { token: string }>({
      query: (params) => {
        return {
          url: '/shared_portfolio',
          method: 'GET',
          params,
        }
      },
    }),

    postSharedPortfolio: builder.mutation<SharedPortfolioResponse, SharedPortfolioBody>({
      query: (body) => {
        return {
          url: '/shared_portfolio',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ id: 'all', type: 'Comment' }],
    }),

    //Vlogs
    getVlogs: builder.query<Vlog[], { user?: number; seller_firm_id?: number }>({
      query: (params) => {
        return {
          url: '/blog',
          method: 'GET',
          params,
        }
      },
    }),
    //Landing

    getLanding: builder.query<
      {
        id: number
        issuer_product: number
        landing: Landing[]
      },
      { seller_firm?: number; issuer_product?: number }
    >({
      query: (params) => ({ url: '/landing', params }),
    }),

    getMainProductLanding: builder.query<
      {
        issuer_product_id: number
        seller_product_id: number
        landing: {
          id: number
          landing: Landing[]
        }
      },
      { seller_firm: number }
    >({
      query: (params) => ({ url: `/seller_firm/main_product/${params.seller_firm}` }),
    }),
  }),
})

export default api
