import React, { useEffect } from 'react'
import Router from './Router'
import api from './api'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import useAFK from 'hooks/useAFK'
import { useDispatch, useSelector } from 'store/store'
import { sellerFirmActions } from 'store/sellerFirmReducer'
import useFirmTheme from './hooks/useFirmTheme'

const App = () => {
  const dispatch = useDispatch()

  const user = useSelector((store) => store.profile)
  const clientId = useSelector((store) => store.client.id)
  const sellerFirm = useSelector((store) => store.sellerFirm)

  const [getClient, resultClient] = api.useLazyGetImportedClientQuery()

  useAFK()
  useFirmTheme()

  useEffect(() => {
    // if (user.id && !clientId && sellerFirm.id) getClient({ user: user.id, seller_firm: sellerFirm.id })
    getClient({ user: user.id!, seller_firm: sellerFirm.id! }).then((value) => {
      //@ts-ignore
      if (value.error?.status === 404) {
        dispatch(sellerFirmActions.clearSellerFirm())
        // navigate('/selection_seller_firm')
        // console.log('value', value)
      }
    })
  }, [user])

  return (
    <>
      <Router />
      <ToastContainer />
    </>
  )
}

export default App
