import React from 'react'
import styles from './Loading.module.scss'
import translation from 'assets/locales/en/translation.json'
import ReactLoading from 'react-loading'

const Loading = () => {
  return (
    <div className={styles.page}>
      {/* <img src={LOGO} className={styles.logo} alt={translation.Loading.img_alt} /> */}
      <ReactLoading type="spin" color="var(--color_primary)" className={styles.spin} />
      <h2 className={styles.message}>{translation.Loading.loading}</h2>
    </div>
  )
}

export default Loading
